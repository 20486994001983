import React from 'react'

const Email = () => {

  const data =   {
    "id": 43,
    "created_at": "2023-09-13T15:13:01.196Z",
    "fecha_falla": "2023-08-31T04:00:00.000Z",
    "idclasificacion": "c81e728d9d4c2f636f067f89cc14862c",
    "clasificacion": "Operativo",
    "severidad": "",
    "id_activo": "6512bd43d9caa6e02c990b0a82652dca",
    "nombre_activo": "TRANSBORDADOR BERTINA",
    "activo": true,
    "accionestomadas": "[]",
    "iniciotrabajos": null,
    "terminotrabajos": null,
    "repuestos": "[]",
    "nrotecnicos": null,
    "fechacotizacion": null,
    "fechasolped": null,
    "fechaoc": null,
    "nrocot": null,
    "nrosolped": null,
    "nrooc": null,
    "solicitante": "6f4922f45568161a8cdf4ad2299f6d23",
    "nombresolicitante": "Solicitante",
    "aprobador": null,
    "nombreaprobador": null,
    "supervisor": null,
    "nombresupervisor": null,
    "rutproveedor": null,
    "id_item_activos": "6f4922f45568161a8cdf4ad2299f6d23",
    "nombre_item": "Motor Principal POPA",
    "costoreparacion": null,
    "estado_general": "[{\"estado\":\"Abierta\",\"fecha\":\"2023-09-13T12:12:59.572Z\"},{\"estado\":\"Aprobada\",\"fecha\":\"2023-09-13T12:18:52.413Z\"}]",
    "imagenes": "[\"\",\"\",\"\"]",
    "observaciones": "[]",
    "fecha_programada": null,
    "nivel": null,
    "responsable": null,
    "rut": null,
    "proveedor": null
}


const formatDate = (date) => {
  console.log(date)
  if (!date) {
    return ''

  } else {
    return new Date(date).toLocaleString()

  }
}


const emailContainer = {
  color: "Black",
  backgroundColor: "white",
  padding: "20px",
  fontFamily: "Arial",
  width:'100%'
}; 

const titleSection = {
  background: 'var(--secundary-color)',
  padding: '5px',
  width: '100%',
  fontSize: '14px',
  textTransform: 'uppercase',
  letterSpacing: '2px',
  color: 'var(--secundary-color-ligth)'
}

const row = {
  margin: '0 !important',
  display:'inline-block'
}

  return (
    <div style={emailContainer}>
        <div style={{}}>
           <h3>Nueva solicitud N° {data.id}</h3>
        </div>
        
        <div style={row}>
        <div style={{width:'100%'}}>
          <p style={{background:'#478673',color:'white',padding:'5px'}}>Nueva solicitud levantada </p>
        </div>
        <div style={{}}>
          <label style={{}} >Fecha de la falla</label>
          <label style={{}}>{formatDate(data.fecha_falla)}</label>
        </div>
        <div style={{}}>
          <label style={{}} >Clasificación de Trabajo</label>
          <br />
          <label style={{}}>{data.clasificacion}</label>

        </div>
        <div style={{}}>
          <label style={{}} >Nave </label><br />
          <label style={{}}>{data.nombre_activo}</label>
        </div>
        <div style={{}}>
          <label style={{}} >Equipo</label>
          <br />
          <label style={{}}>{data.nombre_item}</label>
        </div>

        </div>
     
    </div>

  )
}

export default Email