import axios from 'axios'



export const getCategoriaItems = async(token) =>{
    return await axios.get(`${process.env.REACT_APP_API}/get-categorias-items`,{
        headers:{
            "x-auth-token":token
        }
    })
}

export const getCategoriasItemsById = async(token,id) =>{
    return await axios.post(`${process.env.REACT_APP_API}/get-categorias-items-by-id`,id,{
        headers:{
            "x-auth-token":token
        }
    })
}

export const createCategoriaItems = async(token,body) =>{
    return await axios.post(`${process.env.REACT_APP_API}/create-categorias-items`,body,{
        headers:{
            "x-auth-token":token
        }
    })
}

export const updateCategoriaItem = async(token,body) =>{
    return await axios.put(`${process.env.REACT_APP_API}/update-categorias-items`,body,{
        headers:{
            "x-auth-token":token
        }
    })
}

