import React from 'react'
import {useSelector} from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Loading from '../otros/Loading'

const BuqueRoute = ({Component}) =>{
    const {user} = useSelector((state) => ({...state}))
    let navigate = useNavigate ()

    return user && user.token && user.rol === 'Buque' ? 
    (
    <Component/>
    ) : (
        navigate('/')
    )
}


export default BuqueRoute