import React from "react";

const ModalCreateEquipos = ({
  onOpenChange,
  handleSubmit,
  handleChange,
  values,
}) => {
  const { tipo_equipo, tipo_estanque, stock, m3, horometro } = values;

  return (
    <>
      <div className="container-modal" onClick={onOpenChange}></div>
      <div className="body-modal">
        <h6>Crear Equipo Bitacora</h6>
        <div className="row">
          <div className="col-md-12 ">
            <label className="label-title">Tipo equipo</label>
            <select
              className="w-100 inputFormGest"
              name="tipo_equipo"
              value={tipo_equipo}
              onChange={handleChange}
            >
              <option>Seleccionar tipo equipo</option>
              <option value="estanque">Estanque</option>
              <option value="generador">Generador</option>
              <option value="motor">Motor</option>
            </select>
          </div>
          {tipo_equipo === "estanque" && (
            <>
              <div className="col-md-12 mt-2 ">
                <label className="label-title">Tipo estanque</label>
                <select
                  className="w-100 inputFormGest"
                  name="tipo_estanque"
                  value={tipo_estanque}
                  onChange={handleChange}
                >
                  <option>Seleccionar tipo estanque</option>
                  <option value="venta">Venta</option>
                  <option value="almacenamiento">Almacenamiento</option>
                  <option value="diario">Diario</option>
                </select>
              </div>
              <div className="col-6 mt-2">
                <label className="mr-2 label-title">M3</label>
                <br />
                <input
                  type="number"
                  className="w-100 inputFormGest"
                  name="m3"
                  value={m3}
                  onChange={handleChange}
                  step="0.01"
                />
              </div>
              <div className="col-6 mt-2">
                <label className="mr-2 label-title">Stock</label>
                <br />
                <input
                  type="number"
                  className="w-100 inputFormGest"
                  step="0.01"
                  name="stock"
                  value={stock}
                  onChange={handleChange}
                />
              </div>
            </>
          )}

          {tipo_equipo !== "" && tipo_equipo !== "estanque" && (
            <div className="col-12 mt-2">
              <label className="mr-2 label-title">Horometro</label>
              <br />
              <input
                type="number"
                className="w-100 inputFormGest"
                step="0.01"
                name="horometro"
                value={horometro}
                onChange={handleChange}
              />
            </div>
          )}

          <button
            className="buttonContentPrimary w-100 mt-4"
            onClick={handleSubmit}
          >
            Crear
          </button>
        </div>
      </div>
    </>
  );
};

export default ModalCreateEquipos;
