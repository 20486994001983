import axios from 'axios'



export const getClasificaciones = async(token) =>{
    return await axios.get(`${process.env.REACT_APP_API}/get-clasificaciones`,{
        headers:{
            "x-auth-token":token
        }
    })
}

export const getClasificacionById = async(token,id) =>{
    return await axios.post(`${process.env.REACT_APP_API}/get-clasificaciones-by-id`,id,{
        headers:{
            "x-auth-token":token
        }
    })
}

export const createClasificacion = async(token,body) =>{
    return await axios.post(`${process.env.REACT_APP_API}/create-clasificacion`,body,{
        headers:{
            "x-auth-token":token
        }
    })
}

export const updateClasificacion = async(token,body) =>{
    return await axios.put(`${process.env.REACT_APP_API}/update-clasificaciones`,body,{
        headers:{
            "x-auth-token":token
        }
    })
}

