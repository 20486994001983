import React,{ useState } from 'react'
import { Link } from 'react-router-dom'
import Sidebar from '../../components/nav/Sidebar'

import Loading from '../../components/otros/Loading'

const HomeBuque = () => {

  const [loading,setLoading] = useState(true)


  return (
    <div> 
        <Sidebar />
        {loading ? (
          
        <div className="home">
          <Loading />
        </div>
        ):(
          <div className="home">
          <div className='navCentro'>
                  <ol class="breadcrumb">
                      <li className="breadcrumb-item"><i class="fa-solid fa-house"></i> HOME</li>
                      <li className="breadcrumb-item">Dashboard</li>
                  </ol>
                  <div className="row navTab">
                     
                      <div className="col-md-6 navTabItem">
                          <Link>Nav 1 <i class="fa-solid fa-circle-right ml-2"></i></Link>
  
                      </div>
                      <div className="col-md-6 navTabItem">
                          <Link>Nav 2 <i class="fa-solid fa-circle-right ml-2"></i></Link>
                      </div>
                  </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="title">
                    <h6>TRABAJOS PENDIENTES</h6>
                </div>
              </div>
            </div>
          </div>
        )}
       
    </div>
  )
}

export default HomeBuque