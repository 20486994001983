import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import AdminSidebar from "../../../components/nav/AdminSidebar";
import { toast } from "react-toastify";
import Loading from "../../../components/otros/Loading";

import { updateActivo, getActivoById } from "../../../functions/api/activos";
import { getTipoActivos } from "../../../functions/api/tipoActivos";

const initialState = {
  nombre_activo: "",
  id_tipo_activo: "",
  img_activo: "",
  activo: true,
  id: "",
};

const UpdateActivo = () => {
  const [loading, setLoading] = useState(false);
  const [activo, setActivo] = useState(initialState);
  const [tipoActivos, setTipoActivos] = useState([]);
  const [imageBase64, setImageBase64] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState("");

  let { id } = useParams();
  let { user } = useSelector((state) => ({ ...state }));
  let navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    loadTActivos();
    loadActivo();
    setLoading(false);
  }, []);

  const loadTActivos = () =>
    getTipoActivos(user.token).then((res) => {
      setTipoActivos(res.data);
    });

  const loadActivo = () =>
    getActivoById(user.token, { id: id }).then((res) => {
      setActivo(res.data[0]);
      setImagePreview(res.data[0].img_activo);
    });

  const handleChange = (e) => {
    setActivo({ ...activo, [e.target.name]: e.target.value });
  };

  const { nombre_activo, id_tipo_activo } = activo;

  const submitActivo = (e) => {
    e.preventDefault();
    activo.img_activo = imagePreview;
    updateActivo(user.token, activo)
      .then((r) => {
        toast.success("Activo actualizado exitosamente");
        setActivo([]);
        navigate("/admin/datos-maestros/activos");
      })
      .catch((err) => toast.error(err.response.data));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDelete = () => {
    setImagePreview("");
  };

  return (
    <div>
      <AdminSidebar />
      {loading ? (
        <div className="home">
          <Loading />
        </div>
      ) : (
        <div className="home">
          <div className="navCentro">
            <ol class="breadcrumb">
              <li className="breadcrumb-item">
                <i class="fa-solid fa-ship"></i> ACTIVOS
              </li>
              <li className="breadcrumb-item">Actualizar activo</li>
            </ol>
            <div className="row navTab">
              <div className="col-md-12 navTabItem">
                <Link to="/admin/datos-maestros/activos">
                  <i class="fa-solid fa-chevron-left mr-2"></i>Atras
                </Link>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <form className="formFormat1">
                <label>Nombre del Activo</label>
                <input
                  name="nombre_activo"
                  value={nombre_activo}
                  onChange={handleChange}
                />
                <label>Tipo de Activo</label>

                <select
                  id="selectInput"
                  value={id_tipo_activo}
                  name="id_tipo_activo"
                  onChange={handleChange}
                >
                  <option value="">Seleccionar tipo de Activo</option>
                  {tipoActivos &&
                    tipoActivos.map((c) => (
                      <option value={c.id}>{c.tipo_activo}</option>
                    ))}
                </select>
                <div className="row">
                  <div className="col-md-12">
                    <label for="fileInput" class="file-upload">
                      <span>Subir Imagen</span>
                      <input
                        type="file"
                        onChange={handleImageChange}
                        accept="image/*"
                        id="fileInput"
                      />
                    </label>
                    {/* <input type="file" className='file-upload' accept="image/*" onChange={handleImageChange} /> */}
                  </div>
                  <div className="col-md-12 mb-3">
                    {imagePreview && (
                      <div className="image-container">
                        <div className="deleteImg" onClick={handleDelete}>
                          <i class="fa-solid fa-trash"></i>
                        </div>
                        <img src={imagePreview} alt="Uploaded Preview" />
                      </div>
                    )}
                  </div>
                </div>

                <button className="formButton1" onClick={submitActivo}>
                  Actualizar
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateActivo;
