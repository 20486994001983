import React,{ useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';




import DataTable from 'react-data-table-component';
import AdminSidebar from '../../../components/nav/AdminSidebar'
import Loading from '../../../components/otros/Loading'

import { getUsers } from '../../../functions/api/auth'


const initialStateFiltros = {
    nombre:'',
    email:''
}

const Usuarios = () => {
    
    const [loading,setLoading] = useState(false)
    const [usuarios,setUsuarios] = useState([])
    const [filtros,setFiltros] = useState(initialStateFiltros)

    let {user} = useSelector((state) =>({...state}))
  
    useEffect(() => {
        setLoading(true)

        const delay = 500; // 3 seconds in milliseconds
    
        const timer = setTimeout(() => {
          // Code to execute after the delay
          setLoading(false)
          loadUsers()
          console.log('3 seconds have passed');
        }, delay);
    
        return () => {
          clearTimeout(timer);
        };
    }, [])


    const {nombre,email} = filtros
    
    const loadUsers = () => getUsers(user.token).then( res => {
        setUsuarios(res.data)
    }
    ).catch( err => 
        console.log(err)
    )


    const columns = [
        {
            name: 'Nombre Usuario',
            selector: row => row.nombre,
        },
        {
            name: 'Email',
            selector: row => row.email,
        },
        {
            name: 'Rol',
            selector: row => row.rol,
        },
        {
            name: 'Tipo Solicitante',
            selector: row => row.tipo_solicitante,
        },
        {
            name: 'Activo',
            selector: row => row.activo,
        },
        {
            name: 'Editar',
            selector: row => row.editar,
        }
    ]
    
    const data = usuarios && 
    usuarios
    .filter( d => d.nombre.toLowerCase().includes(filtros.nombre.toLowerCase()))
    .filter( d => d.email.toLowerCase().includes(filtros.email.toLowerCase()))
    .map( c => {
        return  {
            nombre:c.nombre,
            email: c.email, 
            rol:c.rol, 
            tipo_solicitante: c.tipo_solicitante, 
            activo:c.activo === true ? <button className='btn btn-primary btn-sm'>Activo</button>:<button className='btn btn-secondary btn-sm'>Inactivo</button>, 
            editar:<Link to={`/admin/usuarios/actualizar-usuario/${c.id}`} className='btn btn-success btn-sm'>Editar</Link >}
    }
    )
    


    const handleFiltroChange = (e) =>{
        setFiltros({...filtros, [e.target.name]:e.target.value})
    }
   
    
  return (
    <div> 
        <AdminSidebar />
        {loading ? (
          
        <div className="home">
          <Loading />
        </div>
        ):(
            <div className="home">
                 <div className='navCentro'>
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><i class="fa-solid fa-users "></i> USUARIOS</li>
                    </ol>
                    <div className="row navTab">
                        
                        <div className="col-6 navTabItem">
                            <Link to='/admin/usuarios/crear-usuario'>Crear usuario<i class="fa-solid fa-user-plus ml-2"></i></Link>
                        </div>
                        <div className="col-6 navTabItem">
                            <Link to='/admin/usuarios/flujo-solicitud'>Asignar Activos<i class="fa-solid fa-user-plus ml-2"></i></Link>
                        </div>
                    </div>
                   
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className='filtros'>
                                    <span >Filtros:</span>
                                    <input  placeholder='Nombre' value={nombre} name='nombre' onChange={handleFiltroChange} />
                                    <input  placeholder='Email' value={email} name='email' onChange={handleFiltroChange} />
                        </div>
                        <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        />
                        
                        
                    </div>
                    </div>
            </div>
        )}
    </div>
  )
}

export default Usuarios