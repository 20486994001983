import axios from "axios";

export const uploadFile = async (token, params, file) => {
  // Filtrar los parámetros para excluir valores nulos o indefinidos
  const filteredParams = Object.entries(params).reduce((acc, [key, value]) => {
    if (value != null) {
      // Cambiado a != null para incluir valores falsos pero no nulos/undefined
      acc[key] = value;
    }
    return acc;
  }, {});

  // Crear un objeto FormData
  const formData = new FormData();

  // Agregar el archivo a formData
  formData.append("file", file); // Asegúrate de que 'file' es un objeto File o similar

  // Agregar los otros parámetros a formData
  Object.keys(filteredParams).forEach((key) => {
    formData.append(key, filteredParams[key]);
  });

  // Configuración de la solicitud, incluyendo headers
  const config = {
    headers: {
      "x-auth-token": token,
      "Content-Type": "multipart/form-data", // Importante para la carga de archivos
    },
  };

  // Realizar la solicitud POST
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/upload?${new URLSearchParams(
        filteredParams
      ).toString()}`,
      formData,
      config
    );
    return response;
  } catch (error) {
    console.error("Error al subir el archivo:", error);
    throw error;
  }
};

export const downloadFile = async (token, id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API}/download/${id}`,
      {
        headers: {
          "x-auth-token": token,
        },
        responseType: "blob", // Important for handling binary data
      }
    );

    // Create a URL for the file
    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
    const fileLink = document.createElement("a");

    fileLink.href = fileURL;
    fileLink.setAttribute("download", id); // Set the file name and extension
    document.body.appendChild(fileLink);

    fileLink.click();

    // Clean up and revoke the URL
    fileLink.parentNode.removeChild(fileLink);
    window.URL.revokeObjectURL(fileURL);
  } catch (error) {
    console.error("Error downloading file:", error);
    throw error;
  }
};

export const getArchivosSolicitud = async (token, params) => {
  const filteredParams = Object.entries(params).reduce((acc, [key, value]) => {
    if (value) {
      acc[key] = value;
    }
    return acc;
  }, {});

  // Construir la cadena de consulta (query string)
  const queryString = new URLSearchParams(filteredParams).toString();

  return await axios.get(
    `${process.env.REACT_APP_API}/get_archivos?${queryString}`,
    {
      headers: {
        "x-auth-token": token,
      },
    }
  );
};

// router.post("/upload", uploader.single("file"), uploadFile);
// router.get("/download/:idArchivo", getFile);
// router.get("/get_archivos", getArchivosPorTrabajo);
