import axios from 'axios'



export const getAsignaciones = async(token) =>{
    return await axios.get(`${process.env.REACT_APP_API}/get-asignaciones`,{
        headers:{
            "x-auth-token":token
        }
    })
}

export const getAsignacionesByUserId = async(token,id) =>{
    return await axios.post(`${process.env.REACT_APP_API}/get-asignaciones-by-id`,id,{
        headers:{
            "x-auth-token":token
        }
    })
}

export const createAsignacion = async(token,body) =>{
    return await axios.post(`${process.env.REACT_APP_API}/create-asignacion`,body,{
        headers:{
            "x-auth-token":token
        }
    })
}

export const createAsignaciones = async(token,body) =>{
    return await axios.post(`${process.env.REACT_APP_API}/create-asignaciones`,body,{
        headers:{
            "x-auth-token":token
        }
    })
}

