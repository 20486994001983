import axios from 'axios'



export const getTipoActivos = async(token) =>{
    return await axios.get(`${process.env.REACT_APP_API}/get-tipo-activos`,{
        headers:{
            "x-auth-token":token
        }
    })
}

export const getTipoActivosById = async(token,id) =>{
    return await axios.post(`${process.env.REACT_APP_API}/get-tipo-activo-by-id`,id,{
        headers:{
            "x-auth-token":token
        }
    })
}

export const createTipoActivo = async(token,body) =>{
    return await axios.post(`${process.env.REACT_APP_API}/create-tipo-activo`,body,{
        headers:{
            "x-auth-token":token
        }
    })
}

export const updateTipoActivo = async(token,body) =>{
    return await axios.put(`${process.env.REACT_APP_API}/update-tipo-activo`,body,{
        headers:{
            "x-auth-token":token
        }
    })
}

