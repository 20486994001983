import React from "react";
import { Link } from "react-router-dom";
import Stepper from "react-stepper-horizontal";
import { NumericFormat } from "react-number-format";

const SolicitudFlotaForm = ({
  proveedores,
  deleteRepuesto,
  values,
  handleChange,
  observacionesData,
  comentario,
  handleAddDataObs,
  deleteItem,
  handleComentarioChange,
  handleSubmitComentario,
  handleProgramar,
  user,
  handleCerrar,
  rep,
  handleAddRepuestosData,
  handleRepuestoChange,
  repuestosData,
  adjuntosData,
  handleDownloadFile,
  selectedFiles,
  handleFileChange,
  handleFileUpload,
  handleModalSupervisor,
}) => {
  const {
    fecha_falla,
    proveedor,
    nivel,
    responsable,
    nrotecnicos,
    costoreparacion,
    rutproveedor,
    idclasificacion,
    nombre_activo,
    nombre_item,
    severidad,
    fecha_programada,
    clasificacion,
    nrocot,
    fechacotizacion,
    nrosolped,
    fechasolped,
    nrooc,
    fechaoc,
    repuestos,
    nombresolicitante,
    nombreaprobador,
    origen_falla,
    periferico,
    imagenes,
    observaciones,
    estado_general,
    estado_buque,
    handleProgramarModal,
    estado_supervisor,
  } = values;

  const {
    titulo,
    fecha,
    usuario,
    fechaComentario,
    descripcion,
    tipoComentario,
  } = comentario;

  const obs = values && JSON.parse(observaciones);

  const img = values && imagenes && JSON.parse(imagenes);

  const repData = values && repuestos && JSON.parse(repuestos);

  const estadoSolicitud =
    values &&
    JSON.parse(estado_general)[JSON.parse(estado_general).length - 1].estado;

  const { cantRep, descRep } = repuestosData;

  const formatDate = (date) => {
    console.log(date);
    if (!date) {
      return "";
    } else {
      return new Date(date).toLocaleString();
    }
  };

  const formatCurrency = (data) => {
    if (data === null || data === undefined) return "$ ";
    const value = data.replace(/[^0-9]/g, "");

    if (value.length < 2) return value;

    let cuerpoFormatoMiles = value
      .toString()
      .split("")
      .reverse()
      .join("")
      .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
    cuerpoFormatoMiles = cuerpoFormatoMiles
      .split("")
      .reverse()
      .join("")
      .replace(/^[\.]/, "");

    return `$ ${cuerpoFormatoMiles}`;
  };

  const etapa = () => {
    if (nivel !== 1) {
      let flujo = [
        { title: "Abierta" },
        { title: "Aprobada" },
        { title: "Gestionada" },
        { title: "En Compra" },
        { title: "Despachada" },
        { title: "Recepcionada" },
      ];

      const index = flujo.findIndex((item) => item.title === estadoSolicitud);

      console.log(estadoSolicitud);
      console.log(index);
      return index;
    } else {
      let flujo = [
        { title: "Abierta" },
        { title: "Aprobada" },
        { title: "Gestionada" },
        { title: "En Compra" },
        { title: "Despachada" },
        { title: "Recepcionada" },
      ];

      const index = flujo.findIndex((item) => item.title === estadoSolicitud);

      console.log(estadoSolicitud);
      console.log(index);
      return index;
    }
  };

  const index = etapa();

  return (
    <form>
      <div className="row formSolicitud">
        <div className="col-md-12 ">
          <p className="title-section">Información Falla </p>
        </div>
        <div className="col-md-2">
          <label className="mr-2 label-title">Fecha de la falla</label>
          <label className="label-white">{formatDate(fecha_falla)}</label>
        </div>
        <div className="col-md-2">
          <label className=" label-title">Clasificación</label>
          <br />
          <label className="label-white">{clasificacion}</label>
        </div>
        <div className="col-md-3">
          <label className="mr-2 label-title">Nave </label>
          <br />
          <label className="label-white">{nombre_activo}</label>
        </div>
        <div className="col-md-2 ">
          <label className="mr-2 label-title">Equipo</label>
          <br />
          <label className="label-white">{nombre_item}</label>
        </div>
        <div className="col-md-2 ">
          <label className="mr-2 label-title">Periferico (Nro. de parte)</label>
          <br />
          <label className="label-white">{periferico}</label>
        </div>
        {estadoSolicitud &&
          (estadoSolicitud === "Gestionada" ||
            estadoSolicitud === "Programada" ||
            estadoSolicitud === "En Trabajos" ||
            estadoSolicitud === "Conforme") && (
            <>
              <div className="col-md-12 mt-3">
                <p className="title-section">Gestión de la falla </p>
              </div>
              <div className="col-md-4">
                <label className="mr-2 label-title">Severidad de Falla</label>
                <br />
                <label className="label-white">{severidad}</label>
              </div>
              <div className="col-md-4">
                <label className="mr-2 label-title">Nivel</label>
                <br />
                <label className="label-white">{nivel}</label>
              </div>
              <div className="col-md-4">
                <label className="mr-2 label-title">Responsable</label>
                <br />
                <label className="label-white">{responsable}</label>
              </div>
              <div className="col-2 mt-2">
                <label className="mr-2 label-title">Id Cotización</label>
                <br />
                <label className="label-white">{nrocot}</label>
              </div>{" "}
              <div className="col-2 mt-2">
                <label className="mr-2 label-title w-100">
                  Fecha Cotización
                </label>
                <br />
                <label className="label-white">{fechacotizacion}</label>
              </div>
              <div className="col-2 mt-2">
                <label className="mr-2 label-title w-100">Id Solped</label>
                <br />
                <label className="label-white">{nrosolped}</label>
              </div>
              <div className="col-2 mt-2">
                <label className="mr-2 label-title w-100">Fecha Solped</label>
                <br />
                <label className="label-white">{fechasolped}</label>
              </div>{" "}
              <div className="col-2 mt-2">
                <label className="mr-2 label-title w-100">Id Oc</label>
                <br />
                <label className="label-white">{nrooc}</label>
              </div>
              <div className="col-2 mt-2">
                <label className="mr-2 label-title w-100">Fecha Oc</label>
                <br />
                <label className="label-white">{fechaoc}</label>
              </div>
              {/* <div className="col-md-1 mt-2">
                <label className="mr-2 label-title">Id Cot</label>
                <br />
                <input
                  type="number"
                  className="w-100 inputFormGest"
                  name="nrocot"
                  value={nrocot}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-2 mt-2">
                <label className="mr-2 label-title">Fecha Cot</label>
                <br />

                <input
                  type="date"
                  name="fechacotizacion"
                  value={fechacotizacion}
                  onChange={handleChange}
                  className="w-100 inputFormGest"
                />
              </div>
              <div className="col-md-2 mt-2">
                <label className="mr-2 label-title">Id Solped</label>
                <br />

                <input
                  type="number"
                  className="w-100 inputFormGest"
                  name="nrosolped"
                  value={nrosolped}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-3 mt-2">
                <label className="mr-2 label-title">Fecha Solped</label>
                <br />

                <input
                  type="date"
                  name="fechasolped"
                  value={fechasolped}
                  onChange={handleChange}
                  className="w-100 inputFormGest"
                />
              </div>
              <div className="col-1 mt-2">
                <label className="mr-2 label-title">Id OC</label>
                <br />
                <input
                  type="number"
                  className="w-100 inputFormGest"
                  name="nrooc"
                  value={nrooc}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-3 mt-2">
                <label className="mr-2 label-title">Fecha OC</label>
                <br />

                <input
                  type="date"
                  name="fechaoc"
                  value={fechaoc}
                  onChange={handleChange}
                  className="w-100 inputFormGest"
                />
              </div> */}
              <div className="col-md-3 mt-2">
                <label className="mr-2 label-title">Nª Técnicos</label>
                <br />
                <input
                  type="number"
                  className="w-100 inputFormGest"
                  name="nrotecnicos"
                  value={nrotecnicos}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-3 mt-2">
                <label className="mr-2 label-title">Costo Reparación</label>
                <br />
                <input
                  className="w-100 inputFormGest"
                  name="costoreparacion"
                  value={formatCurrency(costoreparacion)}
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-3 mt-2">
                <label className="mr-2 label-title">Servicio</label>
                <br />
                <select
                  className="w-100 inputFormGest"
                  name="rutproveedor"
                  value={rutproveedor}
                  onChange={handleChange}
                >
                  <option>Seleccionar servicio</option>
                  {proveedores &&
                    proveedores.map((c) => (
                      <option value={c.id}>{c.proveedor}</option>
                    ))}
                </select>
              </div>
              <div className="col-md-3 mt-2">
                <label className="mr-2 label-title">Origen Falla</label>
                <br />
                <label className="label-white">{origen_falla}</label>
              </div>
              {estadoSolicitud &&
                (estadoSolicitud === "Programada" ||
                  estadoSolicitud === "Conforme" ||
                  estadoSolicitud === "Gestionada" ||
                  estadoSolicitud === "En Trabajos") && (
                  <div className="col-md-12 mt-3 p-3">
                    <p className="label-title">Repuestos Utilizados</p>
                    <table className="table-rep">
                      <tr>
                        <th>Descripción</th>
                        <th>Cantidad</th>
                        <th>Borrar</th>
                      </tr>

                      {repData ? (
                        repData.map((c, index) => (
                          <tr>
                            <td>{c.descRep}</td>
                            <td>{c.cantRep}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>No hay regitros guardados</td>
                          <td>No hay regitros guardados</td>
                        </tr>
                      )}
                      {rep.map((c, index) => (
                        <tr>
                          <td>{c.descRep}</td>
                          <td>{c.cantRep}</td>
                          <td>
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() => deleteRepuesto(index)}
                            >
                              <i class="fa-solid fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td>
                          <input
                            name="descRep"
                            className="inputTable"
                            placeholder="Ingresar nombre de repuesto"
                            value={descRep}
                            onChange={handleRepuestoChange}
                          />
                        </td>

                        <td>
                          <input
                            type="number"
                            name="cantRep"
                            placeholder="Ingresar Cantidad"
                            className="inputTable"
                            value={cantRep}
                            onChange={handleRepuestoChange}
                          />
                        </td>
                        <td>
                          <button
                            className="btn btn-primary"
                            onClick={handleAddRepuestosData}
                          >
                            <i class="fa-solid fa-floppy-disk"></i> Guardar
                          </button>
                        </td>
                      </tr>
                    </table>
                  </div>
                )}
            </>
          )}

        {estadoSolicitud && estadoSolicitud === "Cerrado" && (
          <>
            <div className="col-md-12 mt-3">
              <p className="title-section">Gestión de la falla </p>
            </div>
            <div className="col-12">
              <label className="mr-2 label-title">Severidad de Falla</label>
              <br />
              <label className="label-white">{severidad}</label>
            </div>

            <div className="col-1 mt-2">
              <label className="mr-2 label-title">Id Cot</label>
              <br />
              <label className="label-white">{nrocot}</label>
            </div>
            <div className="col-2 mt-2">
              <label className="mr-2 label-title">Fecha Cot</label>
              <br />

              <label className="label-white">
                {formatDate(fechacotizacion)}
              </label>
            </div>
            <div className="col-2 mt-2">
              <label className="mr-2 label-title">Id Solped</label>
              <br />

              <label className="label-white">{nrosolped}</label>
            </div>
            <div className="col-3 mt-2">
              <label className="mr-2 label-title">Fecha Solped</label>
              <br />

              <label className="label-white">{formatDate(fechasolped)}</label>
            </div>
            <div className="col-1 mt-2">
              <label className="mr-2 label-title">Id OC</label>
              <br />
              <label className="label-white">{nrooc}</label>
            </div>
            <div className="col-3 mt-2">
              <label className="mr-2 label-title">Fecha OC</label>
              <br />

              <label className="label-white">{formatDate(fechaoc)}</label>
            </div>
            <div className="col-md-3 mt-2">
              <label className="mr-2 label-title">Nª Técnicos</label>
              <br />
              <label className="label-white">{nrotecnicos}</label>
            </div>

            <div className="col-md-3 mt-2">
              <label className="mr-2 label-title">Costo reparación</label>
              <br />
              <label className="label-white">
                {formatCurrency(costoreparacion)}
              </label>
            </div>

            <div className="col-md-3 mt-2">
              <label className="mr-2 label-title">Servicio</label>
              <br />
              <label className="label-white">{proveedor}</label>
            </div>

            <div className="col-md-3 mt-2">
              <label className="mr-2 label-title">Origen Falla</label>
              <br />
              <label className="label-white">{origen_falla}</label>
            </div>
            <div className="col-md-12  p-3">
              <p className="label-title">Repuestos Utilizados</p>
              <table className="table mb-0">
                <tr>
                  <th className="label-title">Descripción</th>
                  <th className="label-title">Cantidad</th>
                </tr>
                {repData &&
                  repData.map((c, index) => (
                    <tr>
                      <td>{c.descRep}</td>
                      <td>{c.cantRep}</td>
                    </tr>
                  ))}
              </table>
            </div>
          </>
        )}

        <div className="col-md-12 mt-3">
          <p className="title-section">Estado solicitud</p>
        </div>
        <div className="col-md-12 mt-2 mb-3">
          {nivel !== 1 && (
            <Stepper
              steps={[
                { title: "Abierta" },
                { title: "Aprobada" },
                { title: "Gestionada" },
                { title: "En Compra" },
                { title: "Despachada" },
                { title: "Recepcionada" },
              ]}
              activeStep={index}
            />
          )}
          {nivel && nivel === 1 && (
            <Stepper
              steps={[
                { title: "Abierta" },
                { title: "Aprobada" },
                { title: "Gestionada" },
                { title: "En Compra" },
                { title: "Despachada" },
                { title: "Recepcionada" },
              ]}
              activeStep={index}
            />
          )}
        </div>
        <div className="col-md-12 mt-2"></div>

        <div className="col-md-8 ">
          <label className="mr-2 title-section">Comentarios</label>
          <br />

          <div className="comentarios">
            {obs &&
              obs.map((c, index) => (
                <div className="comentario-body" key={index}>
                  <div className="header-comentario">
                    <ul className="userList">
                      <li className="userLi">{c.titulo}</li>
                      <div className="userLiContainer">
                        <li className="userLi">{c.usuario}</li>
                        <li
                          className={
                            c.tipoComentario === "Observación"
                              ? "tipoComentarioLi"
                              : "tipoComentarioLi2"
                          }
                        >
                          {c.tipoComentario}
                        </li>
                        <li>{formatDate(c.fecha)}</li>
                      </div>
                    </ul>
                  </div>
                  <div className="body-comentario">
                    <p className="body-desc">{c.descripcion}</p>
                  </div>
                </div>
              ))}
          </div>

          <div className="comentarios">
            {observacionesData &&
              observacionesData.map((c, index) => (
                <div className="comentario-body" key={index}>
                  <div className="header-comentario">
                    <ul className="userList">
                      <li className="userLi">{c.titulo}</li>
                      <div className="userLiContainer">
                        <li className="userLi">{c.usuario}</li>
                        <li
                          className={
                            c.tipoComentario === "Observación"
                              ? "tipoComentarioLi"
                              : "tipoComentarioLi2"
                          }
                        >
                          {c.tipoComentario}
                        </li>
                        <li>{c.fecha}</li>
                        <li>
                          <button
                            className="borarComent"
                            onClick={() => deleteItem(index)}
                          >
                            <i class="fa-solid fa-trash"></i>
                          </button>
                        </li>
                      </div>
                    </ul>
                  </div>
                  <div className="body-comentario">
                    <p className="body-desc">{c.descripcion}</p>
                  </div>
                </div>
              ))}
            {estadoSolicitud !== "Rechazada" &&
              estadoSolicitud !== "Cerrado" && (
                <>
                  <form className="cardFormComentarios">
                    <input
                      required
                      value={titulo}
                      name="titulo"
                      onChange={handleComentarioChange}
                      className="titleInput"
                      placeholder="Título"
                    />
                    <input
                      required
                      value={fecha}
                      name="fecha"
                      onChange={handleComentarioChange}
                      className="selectInput"
                      type="datetime-local"
                      placeholder="Título"
                    />
                    <select
                      required
                      value={tipoComentario}
                      name="tipoComentario"
                      onChange={handleComentarioChange}
                      className="selectInput"
                    >
                      <option value="">Tipo de comentario</option>
                      <option value="Accion Tomada">Acción tomada</option>
                      <option value="Observación">Observación</option>
                      {user && user.tipo_solicitante === "Ito" && (
                        <option value="Tarea">Tarea</option>
                      )}
                    </select>
                    <textArea
                      required
                      value={descripcion}
                      name="descripcion"
                      onChange={handleComentarioChange}
                      className="textAreaInput"
                      placeholder="Descripción"
                    ></textArea>
                  </form>
                  <button
                    className="buttonSolicitud"
                    onClick={handleAddDataObs}
                  >
                    Agregar comentario
                  </button>
                </>
              )}
          </div>
        </div>

        <div className="col-md-4 ">
          <div className="row">
            {/* {estadoSolicitud && estadoSolicitud === "Gestionada" && (
              <>
                <div className="col-md-12 ">
                  <p className="title-section">Programación de trabajos</p>
                </div>
                <label type="date" className="mr-2">
                  Fecha de trabajos:
                </label>
                <br />

                <input
                  type="datetime-local"
                  name="fecha_programada"
                  value={fecha_programada}
                  onChange={handleChange}
                  className="w-100 inputFormGest"
                />
              </>
            )} */}
            {estadoSolicitud &&
              (estadoSolicitud === "Programada" ||
                estadoSolicitud === "En Trabajos" ||
                estadoSolicitud === "Cerrado" ||
                estadoSolicitud === "Conforme") && (
                <>
                  <div className="col-md-12">
                    <p className="title-section">Programación de trabajos</p>
                  </div>
                  <label type="date" className="mr-2 label-title">
                    Fecha Programada
                  </label>
                  <br />

                  <label className="label-white mb-3">
                    {" "}
                    <i class="fa-solid fa-clock"></i>{" "}
                    {formatDate(fecha_programada)}
                  </label>
                </>
              )}
            <label className="title-section">Adjuntos</label>
            <div className="col-md-12 mt-2">
              <input type="file" multiple onChange={handleFileChange} />
              <ul>
                {selectedFiles.map((file, index) => (
                  <li key={index}>{file.name}</li>
                ))}
              </ul>
              <button onClick={handleFileUpload} className="fileUploadButton">
                Subir Archivos
              </button>
            </div>
            <br />
            <div className="col-12 p-0">
              <ui className="list-archivos">
                {adjuntosData &&
                  adjuntosData.map((c) => (
                    <li
                      onClick={(e) => handleDownloadFile(e, c.ruta)}
                      key={c.id}
                    >
                      {c.nombre_archivo}
                    </li>
                  ))}
              </ui>
            </div>
          </div>
        </div>

        {estadoSolicitud && estadoSolicitud === "Aprobada" && (
          <>
            <div className="col-6 mt-3">
              <button
                className="buttonSolicitud"
                onClick={handleSubmitComentario}
              >
                Guardar comentarios
              </button>
            </div>
            {/* <button onClick={test}>sds</button> */}
            <div className="col-6 mt-3">
              <button
                className="buttonSolicitud"
                onClick={handleModalSupervisor}
              >
                Tramitar solicitud
              </button>
            </div>
          </>
        )}
        {estadoSolicitud && estadoSolicitud === "En Trabajos" && (
          <>
            <div className="col-12 mt-3">
              <button
                className="buttonSolicitud"
                onClick={handleSubmitComentario}
              >
                Actualizar solicitud
              </button>
            </div>

            {/* <div className="col-6 mt-3">
        <button className='buttonSolicitud' onClick={handleCerrar}>Cerrar Solicitud</button>
      </div>
       */}
          </>
        )}

        {estadoSolicitud && estadoSolicitud === "Programada" && (
          <>
            <div className="col-12 mt-3">
              <button
                className="buttonSolicitud"
                onClick={handleSubmitComentario}
              >
                Actualizar solicitud
              </button>
            </div>
          </>
        )}
      </div>
    </form>
  );
};

export default SolicitudFlotaForm;
