import React,{ useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, Link, useParams } from 'react-router-dom'
import AdminSidebar from '../../../components/nav/AdminSidebar'
import { toast } from 'react-toastify'

import Loading from '../../../components/otros/Loading'

import { updateProveedor, getProveedorById } from '../../../functions/api/proveedores';


function formatRut(rut) {
    const rutLimpio = rut.replace(/[^0-9kK]/g, '');
      const cuerpo = rutLimpio.slice(0, -1);
      const dv = rutLimpio.slice(-1).toUpperCase();
      if (rutLimpio.length < 2) return rutLimpio;
      let cuerpoFormatoMiles = cuerpo
          .toString()
          .split('')
          .reverse()
          .join('')
          .replace(/(?=\d*\.?)(\d{3})/g, '$1.');
      cuerpoFormatoMiles = cuerpoFormatoMiles
          .split('')
          .reverse()
          .join('')
          .replace(/^[\.]/, '');
      return `${cuerpoFormatoMiles}-${dv}`;
  
  }
  
  function validateRut(rut) {
   
  
    const rutLimpio = rut.replace(/[^0-9kK]/g, '');
    if (rutLimpio.length < 2) return false;
    const cuerpo = rutLimpio.slice(0, -1);
    const dv = rutLimpio.slice(-1).toUpperCase();
    if (!cuerpo.replace(/[^0-9]/g, '')) return false;
  
  
  
    // Calculate verification digit
    let suma = 0;
    let multiplo = 2;
    for (let i = cuerpo.length - 1; i >= 0; i--) {
      suma += cuerpo.charAt(i) * multiplo;
      multiplo = (multiplo + 1) % 8 || 2;
    }
    const resultado = 11 - (suma % 11);
    const verificador = resultado === 11 ? '0' : resultado === 10 ? 'K' : resultado.toString();
    
  
  
    
  
  
    let errRut = true;
    const res = verificador === dv;
    if (rut && res === true) {
        errRut = false;
    }
    return errRut;
  
  }
  
  

const UpdateProveedor = () => {

    const [loading,setLoading] = useState(false)
    const [proveedorData,setProveedor] = useState([])

    let { id } = useParams();
    let {user} = useSelector((state) =>({...state}))
    let navigate = useNavigate ()

    useEffect(() => {
        setLoading(true)

        const delay = 500; // 3 seconds in milliseconds
    
        const timer = setTimeout(() => {
          // Code to execute after the delay

          loadProveedor()
          setLoading(false)
          console.log('3 seconds have passed');
        }, delay);
    
        return () => {
          clearTimeout(timer);
        };
    }, [])


    const loadProveedor = () => getProveedorById(user.token,{id:id}).then( c=> setProveedor(c.data[0])).catch(err => console.log(err))



    const {rut,proveedor,activo} = proveedorData


    const handleChange = (e) =>{

        setProveedor({...proveedorData, [e.target.name]:e.target.value})

    }


    const submitData = (e) =>{

        e.preventDefault()
        
        updateProveedor(user.token,proveedorData)
        .then( r => {
            toast.success('Proveedor actualizado exitosamente')
            setProveedor([])
            navigate('/admin/datos-maestros/proveedores')
        }
        )
        .catch( err =>
                toast.error(err.response.data)
        )

    }
    
    const handleRutChange = (e) => {
        let rut = formatRut(e.target.value) 
        setProveedor({...proveedorData,['rut']:rut })
    }

  return (
    <div> 
        <AdminSidebar />
        {loading ? (
          
        <div className="home">
          <Loading />
        </div>
        ):(
            <div className="home">
                <div className='navCentro'>
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><i class="fa-solid fa-truck-field icon"></i> PROVEEDORES</li>
                        <li className="breadcrumb-item">Actualizar Proveedor</li>
                    </ol>
                    <div className="row navTab">
                        <div className="col-md-12 navTabItem">
                            <Link to='/admin/datos-maestros/proveedores'><i class="fa-solid fa-chevron-left mr-2"></i>Atras</Link>
                        </div>
                    </div>
                   
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <form className='formFormat1'>
                            <label>Rut Proveedor</label>
                            <input type="text" name='rut' required onChange={e => handleRutChange(e)}    value={rut}/> 
                            <label>Proveedor</label>
                            <input  type='text' value={proveedor} name='proveedor' onChange={handleChange}></input>
                            <label>Activo / Inactivo</label>
                            <select id="selectInput" value={activo} name='activo' onChange={handleChange}>
                                <option value="">Escoga el estado del proveedor</option>
                                <option value={true}>Activo</option>
                                <option value={false}>Inactivo</option>
                            </select>
                            <button className='formButton1' onClick={submitData}>Actualizar proveedor</button>
                        </form>
                        
                    </div>
                    </div>
            </div>
        )}
    </div>
  )
}

export default UpdateProveedor