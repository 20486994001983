import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loading from "../../../../components/otros/Loading";
import AdminSidebar from "../../../../components/nav/AdminSidebar";
import ModalCreateEquipos from "./ModalCreateEquipos";
import { getActivoById } from "../../../../functions/api/activos";
import { useSelector } from "react-redux";
import {
  createEquipoBitacora,
  getEquiposBitacoraByIdNave,
} from "../../../../functions/api/equiposBitacora";

const initialState = {
  nombre_activo: "",
  id_tipo_activo: "",
  img_activo: "",
  activo: true,
  id: "",
};

const initialStateEquipo = {
  tipo_equipo: "",
  tipo_estanque: "",
  m3: "",
  stock: "",
  horometro: "",
  idNave: "",
};

const EquiposBitacoraHome = () => {
  const [loading, setLoading] = useState(false);
  const [createModal, setModalCreate] = useState(false);
  const [nave, setNave] = useState(initialState);
  const [equipo, setEquipo] = useState(initialStateEquipo);
  const [equipos, setEquipos] = useState([]);

  let { id } = useParams();
  let { user } = useSelector((state) => ({ ...state }));
  let navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    loadNave();
    loadEquipos();
    setLoading(false);
  }, []);

  const loadNave = () =>
    getActivoById(user.token, { id: id }).then((res) => {
      setNave(res.data[0]);
    });

  const loadEquipos = () => {
    getEquiposBitacoraByIdNave(user.token, id).then((res) => {
      setEquipos(res.data);
    });
  };

  const handleModalOpen = () => {
    setModalCreate(!createModal);
  };

  const handleEquipoChange = (e) => {
    setEquipo({ ...equipo, [e.target.name]: e.target.value });
  };

  const handleEquipoSubmit = async (e) => {
    e.preventDefault();
    try {
      equipo.idNave = id;
      await createEquipoBitacora(user.token, equipo);
      setModalCreate(false);
      loadEquipos();
    } catch (err) {
      console.error("Error creating equipo bitacora", err);
    }
  };

  return (
    <div>
      <AdminSidebar />

      {createModal && (
        <ModalCreateEquipos
          onOpenChange={handleModalOpen}
          handleSubmit={handleEquipoSubmit}
          handleChange={handleEquipoChange}
          values={equipo}
        />
      )}

      {loading ? (
        <div className="home">
          <Loading />
        </div>
      ) : (
        <div className="home">
          <div className="navCentro">
            <ol class="breadcrumb">
              <li className="breadcrumb-item">
                <i class="fa-solid fa-users "></i> USUARIO
              </li>
              <li className="breadcrumb-item">Gestionar Equipos Bitacora</li>
            </ol>
            <div className="row navTab">
              <div className="col-md-12 navTabItem">
                <Link to="/admin/datos-maestros/activos">
                  <i class="fa-solid fa-chevron-left mr-2"></i>Atras
                </Link>
              </div>
            </div>
            <div className="row content ">
              <div className="col-md-12 ">
                <div className="headerContent p-2">
                  <h2 className="titleContent">{nave.nombre_activo}</h2>
                  <button
                    onClick={handleModalOpen}
                    className="buttonContentPrimary"
                  >
                    Crear equipo bitacora
                  </button>
                </div>
              </div>
              <div className="col-md-12 mt-2">
                <h4 className="subtitleContent">Estanques</h4>
                <hr />
              </div>

              <div className="col-md-12">
                <div className="row">
                  {equipos && equipos.length > 0 ? (
                    equipos
                      .filter((d) => d.tipo_equipo === "estanque")
                      .map((c) => (
                        <div
                          key={c.id}
                          className="col-md-3 cardContent text-capitalize"
                        >
                          <h6 className="m-auto" style={{}}>
                            {c.tipo_equipo} - ID: {c.id}
                          </h6>
                          {c.tipo_equipo === "estanque" && (
                            <>
                              <span className="m-auto">{c.tipo_estanque}</span>
                              <span className="m-auto">Stock</span>
                              <span className="m-auto">
                                {c.stock} M3 / {c.stock * 1000} Lts
                              </span>
                              <span className="m-auto">Capacidad</span>
                              <span className="m-auto">
                                {Math.floor(c.m3)} m3
                              </span>
                              <div
                                className="progress-bar"
                                role="progressbar"
                                aria-valuenow={(c.stock / c.m3) * 100}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                              <label className="fuelContent">
                                <i className="fa-solid fa-gas-pump" />{" "}
                                {((c.stock / c.m3) * 100).toFixed(2)}%
                              </label>
                              <div class="progress">
                                <div
                                  class="progress-bar progress-bar-striped progress-bar-animated"
                                  role="progressbar"
                                  style={{
                                    width: `${(c.stock / c.m3) * 100}%`,
                                  }}
                                  aria-valuenow={c.stock}
                                  aria-valuemin="0"
                                  aria-valuemax={c.m3}
                                ></div>
                              </div>
                            </>
                          )}
                          <button className="buttonContentSecondary">
                            Detalle
                          </button>
                        </div>
                      ))
                  ) : (
                    <span>No hay equipos asociados a esta nave.</span>
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <h4 className="subtitleContent">Motores y Generadores</h4>
                <hr />
              </div>

              <div className="col-md-12">
                <div className="row">
                  {equipos && equipos.length > 0 ? (
                    equipos
                      .filter((d) => d.tipo_equipo !== "estanque")
                      .map((c) => (
                        <div
                          key={c.id}
                          className="col-md-2 cardContent text-capitalize"
                        >
                          <h6 className="m-auto" style={{}}>
                            {c.tipo_equipo} - {c.id}
                          </h6>

                          <span className="m-auto horometro">
                            <i className="fa-regular fa-clock" />{" "}
                            {Math.floor(c.horometro)}
                          </span>
                          <button className="buttonContentSecondary">
                            Detalle
                          </button>
                        </div>
                      ))
                  ) : (
                    <span>No hay equipos asociados a esta nave.</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EquiposBitacoraHome;
