import React,{ useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux';

import Loading from '../../../components/otros/Loading'
import DataTable from 'react-data-table-component';
import AdminSidebar from '../../../components/nav/AdminSidebar'


import { getItemsActivos } from '../../../functions/api/sistemas'
import { getCategoriaItems } from '../../../functions/api/sistemasCategorias'


const filtrosState = {
    codigo: '',
    nombre_item: '',
    categoria:''
}

const Sistemas = () => {
    const [loading,setLoading] = useState(false)
    const [items,setItems] = useState([])
    const [itemsCategoria,setItemsCategoria] = useState([])
    const [filtros,setFiltros] = useState(filtrosState)

    let {user} = useSelector((state) =>({...state}))

    useEffect(() => {
        setLoading(true)

        const delay = 500; // 3 seconds in milliseconds
    
        const timer = setTimeout(() => {
          // Code to execute after the delay
          setLoading(false)
          loadItems()
          loadItemsCategoria()
          console.log('3 seconds have passed');
        }, delay);
    
        return () => {
          clearTimeout(timer);
        };
    }, [])

    const loadItems = () => getItemsActivos(user.token).then( res => {
        setItems(res.data)
    })

    const loadItemsCategoria = () => getCategoriaItems(user.token).then( res => {
        setItemsCategoria(res.data)
    })

    const handleFiltroChange = (e) =>{
        setFiltros({...filtros, [e.target.name]:e.target.value})
    }

    const columns = [
        {
            name: 'Código',
            selector: row => row.codigo,
        },
        {
            name: 'Nombre',
            selector: row => row.nombre,
        },
        {
            name: 'Categoria',
            selector: row => row.categoria,
        },
        {
            name: 'Activo',
            selector: row => row.activo,
        },
        {
            name: 'Editar',
            selector: row => row.editar,
        }
    ]

    const itemsData = items && itemsCategoria && items.map( c => {
        let categoria = itemsCategoria && itemsCategoria.filter( d => d.id === c.id_categoria).map( f =>  { return f.tipo_activo})
        if(categoria === undefined) categoria = ''
        return {codigo: c.codigo, nombre_item: c.nombre_item, id:c.id,id_categoria:c.id_categoria, categoria:categoria[0] , activo:c.activo}
    })

    console.log(itemsData)

    const data = itemsData  && itemsData
    .filter( d => d.codigo.toLowerCase().includes(filtros.codigo.toLowerCase()))
    .filter( d => d.nombre_item.toLowerCase().includes(filtros.nombre_item.toLowerCase()))
    .map( c => {
        return {
            codigo: c.codigo,
            nombre: c.nombre_item,
            categoria: c.categoria,
            activo: c.activo === true ? <button className='btn btn-primary'>Activo</button>:<button className='btn btn-secondary'>Inactivo</button>,
            editar: <Link to={`/admin/sistemas/update-sistema/${c.id}`} className='btn btn-success'>Editar</Link >
        }
    })
        
    const { codigo, nombre_item, categoria } = filtros

  return (
    <div> 
        <AdminSidebar />
        {loading ? (
          
        <div className="home">
          <Loading />
        </div>
        ):(
            <div className="home">
                <div className='navCentro'>
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item">
                            <i class="fa-solid fa-database icon"></i> EQUIPOS</li>
                        <li className="breadcrumb-item">Seleccionar tipo de datos</li>
                    </ol>
                    <div className="row navTab">
                        <div className="col-md-6 navTabItem">
                            <Link to='/admin/sistemas/create-sistema'>Crear Equipo<i class="fa-solid fa-circle-right ml-2"></i></Link>
                        </div>
                        <div className="col-md-6 navTabItem">
                            <Link to='/admin/sistemas/tipo-sistema'>Categoria Equipo<i class="fa-solid fa-circle-right ml-2"></i></Link>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-md-12">
                    <div className='filtros'>
                                    <span >Filtros:</span>
                            
                                    <input  placeholder='Código' value={codigo} name='codigo' onChange={handleFiltroChange} />
                                    <input  placeholder='Nombre' value={nombre_item} name='nombre_item' onChange={handleFiltroChange} />
                        </div>
                        {items && itemsCategoria && (
                            <DataTable
                            columns={columns}
                            data={data}
                            pagination
                            />
                        )}
                   
                       
                        
                    </div>
                    </div>
                </div>
            </div>
        )}
    </div>
  )
}

export default Sistemas