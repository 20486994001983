import axios from 'axios'



export const getTrabajos = async(token) =>{
    return await axios.get(`${process.env.REACT_APP_API}/get-trabajos`,{
        headers:{
            "x-auth-token":token
        }
    })
}

export const getTrabajoById = async(token,id) =>{
    return await axios.post(`${process.env.REACT_APP_API}/get-trabajo-by-id`,id,{
        headers:{
            "x-auth-token":token
        }
    })
}

export const getTrabajoByIdNave = async(token,id) =>{
    return await axios.post(`${process.env.REACT_APP_API}/get-trabajos-by-id-nave`,id,{
        headers:{
            "x-auth-token":token
        }
    })
}

export const createTrabajo = async(token,body) =>{
    return await axios.post(`${process.env.REACT_APP_API}/create-trabajo`,body,{
        headers:{
            "x-auth-token":token
        }
    })
}

export const updateTrabajo = async(token,body) =>{
    return await axios.put(`${process.env.REACT_APP_API}/update-trabajo`,body,{
        headers:{
            "x-auth-token":token
        }
    })
}

