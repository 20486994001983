import axios from "axios";

const API_URL = process.env.REACT_APP_API;

export const getRegistrosDiarios = async (token) => {
  return await axios.get(`${API_URL}/registros_diarios`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const getRegistrosDiariosByEstanque = async (token, estanque) => {
  return await axios.get(`${API_URL}/registros_diarios/estanque/${estanque}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const createRegistroDiario = async (token, body) => {
  return await axios.post(`${API_URL}/registros_diarios`, body, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const updateRegistroDiario = async (token, id, body) => {
  return await axios.put(`${API_URL}/registros_diarios/${id}`, body, {
    headers: {
      "x-auth-token": token,
    },
  });
};
