import React from "react";
import { Link } from "react-router-dom";

import Stepper from "react-stepper-horizontal";

const SolicitudAnalistaForm = ({
  values,
  repuestosData,
  comentario,
  adjuntosData,
  handleDownloadFile,
}) => {
  const {
    proveedor,
    costoreparacion,
    nrotecnicos,
    fecha_falla,
    nivel,
    responsable,
    nombre_activo,
    nombre_item,
    severidad,
    fecha_programada,
    clasificacion,
    nrocot,
    fechacotizacion,
    nrosolped,
    fechasolped,
    nrooc,
    fechaoc,
    repuestos,
    nombresolicitante,
    nombreaprobador,
    imagenes,
    origen_falla,
    periferico,
    observaciones,
    estado_general,
    estado_buque,
    estado_supervisor,
  } = values;

  const {
    titulo,
    fecha,
    usuario,
    fechaComentario,
    descripcion,
    tipoComentario,
  } = comentario;

  const img = values && imagenes && JSON.parse(imagenes);

  const estadoSolicitud =
    values &&
    JSON.parse(estado_general)[JSON.parse(estado_general).length - 1].estado;

  const obs = values && JSON.parse(observaciones);

  const repData = values && repuestos && JSON.parse(repuestos);

  const { cantRep, descRep } = repuestosData;

  const formatDate = (date) => {
    console.log(date);
    if (!date) {
      return "";
    } else {
      return new Date(date).toLocaleString();
    }
  };

  const estadoData = values && JSON.parse(estado_general);

  const etapa = () => {
    if (nivel !== 1) {
      let flujo = [
        { title: "Abierta" },
        { title: "Aprobada" },
        { title: "Gestionada" },
        { title: "Programada" },
        { title: "En Trabajos" },
        { title: "Conforme" },
        { title: "Cerrado" },
      ];

      const index = flujo.findIndex((item) => item.title === estadoSolicitud);

      console.log(estadoSolicitud);
      console.log(index);
      return index;
    } else {
      let flujo = [
        { title: "Abierta" },
        { title: "Aprobada" },
        { title: "Gestionada" },
        { title: "Programada" },
        { title: "En Trabajos" },
        { title: "Cerrado" },
      ];

      const index = flujo.findIndex((item) => item.title === estadoSolicitud);

      console.log(estadoSolicitud);
      console.log(index);
      return index;
    }
  };

  const index = etapa();

  const formatCurrency = (data) => {
    console.log(data);
    if (data === null || data === undefined) return "$ ";
    const value = data.replace(/[^0-9]/g, "");

    if (value.length < 2) return value;

    let cuerpoFormatoMiles = value
      .toString()
      .split("")
      .reverse()
      .join("")
      .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
    cuerpoFormatoMiles = cuerpoFormatoMiles
      .split("")
      .reverse()
      .join("")
      .replace(/^[\.]/, "");
    return `$ ${cuerpoFormatoMiles}`;
  };
  return (
    <form>
      <div className="row formSolicitud">
        <div className="col-md-12 ">
          <p className="title-section">Información Falla </p>
        </div>
        <div className="col-md-2">
          <label className="mr-2 label-title">Fecha de la falla</label>
          <label className="label-white">{formatDate(fecha_falla)}</label>
        </div>
        <div className="col-md-2">
          <label className=" label-title">Clasificación</label>
          <br />
          <label className="label-white">{clasificacion}</label>
        </div>
        <div className="col-md-3">
          <label className="mr-2 label-title">Nave </label>
          <br />
          <label className="label-white">{nombre_activo}</label>
        </div>
        <div className="col-md-2 ">
          <label className="mr-2 label-title">Equipo</label>
          <br />
          <label className="label-white">{nombre_item}</label>
        </div>
        <div className="col-md-3 ">
          <label className="mr-2 label-title">Periferico (Nro. de parte)</label>
          <br />
          <label className="label-white">{periferico}</label>
        </div>
        <div className="col-md-12 mt-3">
          <p className="title-section">Gestión de la falla </p>
        </div>
        <div className="col-md-4">
          <label className="mr-2 label-title">Severidad de Falla</label>
          <br />
          <label className="label-white">{severidad}</label>
        </div>
        <div className="col-md-4">
          <label className="mr-2 label-title">Nivel</label>
          <br />
          <label className="label-white">{nivel}</label>
        </div>

        <div className="col-md-4">
          <label className="mr-2 label-title">Responsable</label>
          <br />
          <label className="label-white">{responsable}</label>
        </div>
        <div className="col-md-1 mt-2">
          <label className="mr-2 label-title">Id Cot</label>
          <br />
          <label className="label-white">{nrocot}</label>
        </div>
        <div className="col-md-2 mt-2">
          <label className="mr-2 label-title">Fecha Cot</label>
          <br />

          <label className="label-white">{formatDate(fechacotizacion)}</label>
        </div>
        <div className="col-md-2 mt-2">
          <label className="mr-2 label-title">Id Solped</label>
          <br />

          <label className="label-white">{nrosolped}</label>
        </div>
        <div className="col-md-3 mt-2">
          <label className="mr-2 label-title">Fecha Solped</label>
          <br />

          <label className="label-white">{formatDate(fechasolped)}</label>
        </div>
        <div className="col-md-1 mt-2">
          <label className="mr-2 label-title">Id OC</label>
          <br />
          <label className="label-white">{nrooc}</label>
        </div>
        <div className="col-md-3 mt-2">
          <label className="mr-2 label-title">Fecha OC</label>
          <br />

          <label className="label-white">{formatDate(fechaoc)}</label>
        </div>

        <div className="col-md-3 mt-2">
          <label className="mr-2 label-title">Nª Técnicos</label>
          <br />
          <label className="label-white">{nrotecnicos}</label>
        </div>

        <div className="col-md-3 mt-2">
          <label className="mr-2 label-title">Costo reparación</label>
          <br />
          <label className="label-white">
            {formatCurrency(costoreparacion)}
          </label>
        </div>

        <div className="col-md-3 mt-2">
          <label className="mr-2 label-title">Servicio</label>
          <br />
          <label className="label-white">{proveedor}</label>
        </div>

        <div className="col-md-3 mt-2">
          <label className="mr-2 label-title">Origen Falla</label>
          <br />
          <label className="label-white">{proveedor}</label>
        </div>

        <div className="col-md-12 mt-3 p-3">
          <p className="label-title">Repuestos Utilizados</p>
          <table className="table-rep mb-0">
            <tr>
              <th>Descripción</th>
              <th>Cantidad</th>
            </tr>
            {repData ? (
              repData.map((c, index) => (
                <tr>
                  <td>{c.descRep}</td>
                  <td>{c.cantRep}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td>No hay regitros guardados</td>
                <td>No hay regitros guardados</td>
              </tr>
            )}
          </table>
        </div>

        <div className="col-md-12 mt-3">
          <p className="title-section">Estado solicitud</p>
        </div>
        <div className="col-md-12 mt-2 mb-3">
          {nivel !== 1 && (
            <Stepper
              steps={[
                { title: "Abierta" },
                { title: "Aprobada" },
                { title: "Gestionada" },
                { title: "Programada" },
                { title: "En trabajos" },
                { title: "Conforme" },
                { title: "Cerrado" },
              ]}
              activeStep={index}
            />
          )}
          {nivel && nivel === 1 && (
            <Stepper
              steps={[
                { title: "Abierta" },
                { title: "Aprobada" },
                { title: "Gestionada" },
                { title: "Programada" },
                { title: "En trabajos" },
                { title: "Cerrado" },
              ]}
              activeStep={index}
            />
          )}
        </div>
        <div className="col-md-12 mt-2"></div>
        <div className="col-md-8 ">
          <label className=" title-section">Comentarios</label>
          <br />

          <div className="comentarios">
            {obs &&
              obs.map((c, index) => (
                <div className="comentario-body" key={index}>
                  <div className="header-comentario">
                    <ul className="userList">
                      <li className="userLi">{c.titulo}</li>
                      <div className="userLiContainer">
                        <li className="userLi">{c.usuario}</li>
                        <li
                          className={
                            c.tipoComentario === "Observación"
                              ? "tipoComentarioLi"
                              : "tipoComentarioLi2"
                          }
                        >
                          {c.tipoComentario}
                        </li>
                        <li>{formatDate(c.fecha)}</li>
                      </div>
                    </ul>
                  </div>
                  <div className="body-comentario">
                    <p className="body-desc">{c.descripcion}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="col-md-4 ">
          <div className="row">
            {estadoSolicitud &&
              (estadoSolicitud === "Programada" ||
                estadoSolicitud === "En Trabajos" ||
                estadoSolicitud === "Cerrado") && (
                <>
                  <div className="col-md-12">
                    <p className="title-section">Programación de trabajos</p>
                  </div>
                  <label type="date" className="mr-2 label-title">
                    Fecha Programada
                  </label>
                  <br />

                  <label className="label-white">
                    {" "}
                    <i class="fa-solid fa-clock"></i>{" "}
                    {formatDate(fecha_programada)}
                  </label>
                </>
              )}
            <label className="title-section">Adjuntos</label>

            <br />
            <div className="col-12 p-0">
              <ui className="list-archivos">
                {adjuntosData &&
                  adjuntosData.map((c) => (
                    <li
                      onClick={(e) => handleDownloadFile(e, c.ruta)}
                      key={c.id}
                    >
                      {c.nombre_archivo}
                    </li>
                  ))}
              </ui>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SolicitudAnalistaForm;
