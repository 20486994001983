import React from "react";

import Load from "../../img/loader-step.gif";

const LoadingComponent = () => {
  return (
    <div className="loading">
      <img src={Load} />
      <h4>Cargando</h4>
    </div>
  );
};

export default LoadingComponent;
