import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Sidebar from "../../components/nav/Sidebar";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { getClasificaciones } from "../../functions/api/clasificaciones";
import { getActivosByUser } from "../../functions/api/activos";
import { getAsignacionesByUserId } from "../../functions/api/asignacionNaves";
import { createTrabajo } from "../../functions/api/trabajos";
import { getItemsActivosById2 } from "../../functions/api/sistemas";
import { uploadFile } from "../../functions/api/archivos";
import SolicitudModalComentario from "./SolicitudModalComentario";

const initialState = {
  fecha_falla: "",
  idclasificacion: "",
  id_activo: "",
  idubicacion: "",
  severidad: "",
  activo: true,
  accionestomadas: "",
  repuestos: "",
  solicitante: "",
  imagenes: "",
  observaciones: "",
  estado_general: "",
  estado_buque: "",
  estado_supervisor: "",
  repuestos: "",
  periferico: "",
};

const comentarioState = {
  usuario: "",
  fecha: "",
  fechaComentario: "",
  titulo: "",
  tipoComentario: "",
  descripcion: "",
  solicitante: "",
};

const comentarioCustomState = {
  descripcion: "",
  impacto: "",
  obsSintomas: "",
  accionesPreliminares: "",
  capacidadesRemanentes: "",
};

const SolicitudCreate = () => {
  const [loading, setLoading] = useState(false);
  const [clasificaciones, setClasificaciones] = useState([]);
  const [activos, setActivos] = useState([]);
  const [asignaciones, setAsignaciones] = useState([]);
  const [values, setValues] = useState(initialState);
  const [acciones, setAcciones] = useState([]);
  const [sistemas, setSistemas] = useState([]);
  const [observacionesData, setObsevaciones] = useState([]);
  const [comentario, setComentario] = useState(comentarioState);
  const [comentarioCustom, setComentarioCustom] = useState(
    comentarioCustomState
  );

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [open, setOpen] = useState(false);

  const [fileName, setFileName] = useState("");

  let navigate = useNavigate();
  let { user } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    setLoading(true);

    const delay = 500; // 3 seconds in milliseconds

    const timer = setTimeout(() => {
      // Code to execute after the delay
      setLoading(false);

      loadClasificaciones();
      loadActivos();
      loadAsignaciones();
      loadSistemasActivo();

      console.log("3 seconds have passed");
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const {
    fecha_falla,
    idclasificacion,
    id_activo,
    id_item_activos,
    severidad,
    respuestos,
    periferico,
    solicitante,
    imagenes,
    observaciones,
    estado_general,
    estado_buque,
    accionestomadas,
  } = values;

  const {
    titulo,
    fecha,
    usuario,
    fechaComentario,
    descripcion,
    tipoComentario,
  } = comentario;

  const loadClasificaciones = () =>
    getClasificaciones(user.token).then((res) => {
      setClasificaciones(res.data);
    });
  const loadActivos = () =>
    getActivosByUser(user.token, { id: user.id }).then((res) => {
      setActivos(res.data);
    });
  const loadAsignaciones = () =>
    getAsignacionesByUserId(user.token, { id: user.id }).then((res) => {
      setAsignaciones(res.data);
    });
  const loadSistemasActivo = () =>
    getItemsActivosById2(user.token, { id: id_activo }).then((res) => {
      setSistemas(res.data);
    });

  useEffect(() => {
    loadSistemasActivo();
  }, [id_activo]);

  const handleComentarioChange = (e) => {
    setComentario({ ...comentario, [e.target.name]: e.target.value });
  };

  const handleComentariocustomChange = (e) => {
    setComentarioCustom({
      ...comentarioCustom,
      [e.target.name]: e.target.value,
    });
  };

  const deleteItem = (index) => {
    const updatedArray = [...observacionesData]; // Create a copy of the array
    updatedArray.splice(index, 1); // Remove the item at the specified position
    setObsevaciones(updatedArray);
  };

  // observaciones array dictionary
  const handleAddDataObs = (e) => {
    e.preventDefault();

    if (titulo === "") {
      toast.error("Falta agregar título ");
    } else if (fecha === "") {
      toast.error("Falta agregar fecha");
    } else if (tipoComentario === "") {
      toast.error("Falta seleccionar tipo de comentario");
    } else if (descripcion === "") {
      toast.error("Falta agregar descripción");
    } else {
      const currentDate = new Date();
      comentario.usuario = user.nombre;
      comentario.fechaComentario = currentDate;
      setObsevaciones((prevData) => [...prevData, comentario]);
      setComentario(comentarioState);
    }
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(comentarioCustom);

    let comentario = {};

    let descripcion = "";
    if (values.accionestomadas !== "trabajo") {
      descripcion = `Solicitante: ${comentarioCustom.solicitante}`;
    } else {
      descripcion = `
      1.- Descripción de la falla: ${comentarioCustom.descripcion}
      2.- Impacto de la falla:${comentarioCustom.impacto}
      3.- Observaciones
      y síntomas:${comentarioCustom.obsSintomas}
      4.- Acciones preliminares tomadas:${comentarioCustom.accionesPreliminares}
      5.- Capacidades remanentes:${comentarioCustom.capacidadesRemanentes}
      6.= Solicitante: ${comentarioCustom.solicitante}
      `;
    }

    const currentDate = new Date();

    comentario.titulo = "Información de falla";
    comentario.fecha = currentDate;
    comentario.usuario = user.nombre;
    comentario.fechaComentario = currentDate;
    comentario.descripcion = descripcion;
    comentario.tipoComentario = "Observación";

    values.solicitante = user.id;
    values.observaciones = JSON.stringify([comentario]);
    values.repuestos = JSON.stringify(acciones);

    values.estado_buque = JSON.stringify([
      {
        estado: "Abierta",
        fecha: new Date(),
      },
    ]);

    values.estado_general = JSON.stringify([
      {
        estado: "Abierta",
        fecha: new Date(),
      },
    ]);

    values.estado_supervisor = JSON.stringify([
      {
        estado: "Abierta",
        fecha: new Date(),
      },
    ]);

    createTrabajo(user.token, values)
      .then((res) => {
        toast.success("Solicitud ingresada con exito");
        setValues([]);
        setAcciones([]);
        setObsevaciones([]);

        console.log(res.data[0].id);

        selectedFiles.forEach((file) => {
          let params = {};

          params.idTrabajo = res.data[0].id;
          params.nombre_documento = file.name;

          uploadFile(user.token, params, file)
            .then((res) => {
              toast.success(res);
            })
            .catch((err) => console.log(err));
          // Append the file with its name
          // formData.append("files", file, file.name);
        });

        navigate("/buque/mis-solicitudes");
      })
      .catch((err) => console.log(err));
  };

  const formatDate = (date) => {
    const inputDate = new Date(date);
    const hours = String(inputDate.getHours()).padStart(2, "0");
    const minutes = String(inputDate.getMinutes()).padStart(2, "0");
    const day = String(inputDate.getDate()).padStart(2, "0");
    const month = String(inputDate.getMonth() + 1).padStart(2, "0");
    const year = inputDate.getFullYear();

    return `${hours}:${minutes} ${day}-${month}-${year}`;
  };

  const handleFileChange = (event) => {
    setSelectedFiles([...event.target.files]);
  };

  const handleModal = (e) => {
    e.preventDefault();

    setOpen(!open);
  };

  const idPedimentosFlota = ["e2c420d928d4bf8ce0ff2ec19b371514"];
  const idPedimentosMantencion = ["32bb90e8976aab5298d5da10fe66f21d"];
  const idPedimentosSSO = ["d2ddea18f00665ce8623e36bd4e3c7c5"];
  const idPedimentosArmatorial = ["ad61ab143223efbc24c7d2583be69251"];

  return (
    <div>
      <Sidebar />
      {open && (
        <SolicitudModalComentario
          handleModal={handleModal}
          handleSubmit={handleSubmit}
          handleComentariocustomChange={handleComentariocustomChange}
          comentario={comentarioCustom}
          values={values}
        />
      )}
      <div className="home">
        <div className="navCentro">
          <ol class="breadcrumb">
            <li className="breadcrumb-item">
              <i class="fa-solid fa-screwdriver-wrench"></i> CREACIÓN SOLICITUD
            </li>
            <li className="breadcrumb-item">Solicitar</li>
          </ol>
        </div>
        <div className="row">
          <div className="col-md-12">
            <form>
              <div className="row formSolicitud">
                <div className="col-md-12 mb-3">
                  <p className="title-section">Información Solicitud</p>
                </div>
                <div className="col-md-4">
                  <label className="mr-2 label-title">
                    Fecha de solicitud:
                  </label>
                  <input
                    className="inputFormGest w-100"
                    type="datetime-local"
                    id="datetime"
                    name="fecha_falla"
                    value={fecha_falla}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-4">
                  <label className="mr-2 label-title">Nave: </label>
                  <br />
                  <select
                    style={{ width: "100%" }}
                    name="id_activo"
                    value={id_activo}
                    onChange={handleChange}
                    className="inputFormGest w-100"
                  >
                    <option selected>Seleccionar Nave</option>
                    {activos &&
                      activos.map((c) => (
                        <option key={c.id} value={c.id}>
                          {c.nombre_activo}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-4">
                  <label className="mr-2 label-title">Clasificacion: </label>
                  <br />
                  <select
                    style={{ width: "100%" }}
                    className="inputFormGest w-100"
                    name="idclasificacion"
                    value={idclasificacion}
                    onChange={handleChange}
                  >
                    <option selected>Seleccionar estado</option>
                    {clasificaciones &&
                      clasificaciones.map((c) => (
                        <option key={c.id} value={c.id}>
                          {c.clasificacion}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-4">
                  <label className="mr-2 label-title">Tipo Solicitud: </label>
                  <br />
                  <select
                    style={{ width: "100%" }}
                    name="accionestomadas"
                    className="inputFormGest w-100"
                    value={accionestomadas}
                    onChange={handleChange}
                  >
                    <option value="">Seleccionar tipo de solicitud</option>

                    <option value="trabajo">Trabajo</option>
                    <option value="pedimentoMant">
                      Pedimento - Mantención
                    </option>
                    <option value="pedimentoFlota">Pedimento - Flota</option>
                    <option value="pedimentoSSO">Pedimento - SSO</option>
                    <option value="pedimentoArmatorial">
                      Pedimento - Armatorial
                    </option>
                  </select>
                </div>
                {accionestomadas !== "" && (
                  <div className="col-md-4  ">
                    <label className="mr-2 label-title">Artículo:</label>
                    <br />

                    <select
                      style={{ width: "100%" }}
                      name="id_item_activos"
                      className="inputFormGest w-100"
                      value={id_item_activos}
                      onChange={handleChange}
                    >
                      <option selected>Seleccionar Equipo</option>
                      {accionestomadas === "pedimentoFlota"
                        ? sistemas.length > 0 &&
                          sistemas
                            .filter(
                              (d) =>
                                Array.isArray(idPedimentosFlota) &&
                                idPedimentosFlota.includes(d.id_categoria)
                            )
                            .map((c) => (
                              <option key={c.id} value={c.id}>
                                {c.nombre_item}
                              </option>
                            ))
                        : accionestomadas === "pedimentoMant"
                        ? sistemas
                            .filter(
                              (d) =>
                                Array.isArray(idPedimentosMantencion) &&
                                idPedimentosMantencion.includes(d.id_categoria)
                            )
                            .map((c) => (
                              <option key={c.id} value={c.id}>
                                {c.nombre_item}
                              </option>
                            ))
                        : accionestomadas === "pedimentoSSO"
                        ? sistemas
                            .filter(
                              (d) =>
                                Array.isArray(idPedimentosSSO) &&
                                idPedimentosSSO.includes(d.id_categoria)
                            )
                            .map((c) => (
                              <option key={c.id} value={c.id}>
                                {c.nombre_item}
                              </option>
                            ))
                        : accionestomadas === "pedimentoArmatorial"
                        ? sistemas
                            .filter(
                              (d) =>
                                Array.isArray(idPedimentosArmatorial) &&
                                idPedimentosArmatorial.includes(d.id_categoria)
                            )
                            .map((c) => (
                              <option key={c.id} value={c.id}>
                                {c.nombre_item}
                              </option>
                            ))
                        : sistemas
                            .filter(
                              (d) =>
                                !(
                                  Array.isArray(idPedimentosFlota) &&
                                  idPedimentosFlota.includes(d.id_categoria)
                                ) &&
                                !(
                                  Array.isArray(idPedimentosMantencion) &&
                                  idPedimentosMantencion.includes(
                                    d.id_categoria
                                  )
                                ) &&
                                !(
                                  Array.isArray(idPedimentosSSO) &&
                                  idPedimentosSSO.includes(d.id_categoria)
                                ) &&
                                !(
                                  Array.isArray(idPedimentosArmatorial) &&
                                  idPedimentosArmatorial.includes(
                                    d.id_categoria
                                  )
                                )
                            )
                            .map((c) => (
                              <option key={c.id} value={c.id}>
                                {c.nombre_item}
                              </option>
                            ))}
                    </select>
                  </div>
                )}

                {accionestomadas === "trabajo" && (
                  <div className="col-md-4  ">
                    <label className="mr-2 label-title">Periferico:</label>
                    <br />
                    <input
                      className="inputFormGest w-100"
                      name="periferico"
                      value={periferico}
                      style={{ height: "25px", width: "100%" }}
                      onChange={handleChange}
                    />
                  </div>
                )}
                <div className="col-md-12">
                  <hr />
                </div>

                <div className="col-md-12 mt-2">
                  <label className="mr-2 title-section">Adjuntos</label>
                  <input type="file" multiple onChange={handleFileChange} />
                  <ul>
                    {selectedFiles.map((file, index) => (
                      <li key={index}>{file.name}</li>
                    ))}
                  </ul>
                </div>
                <div className="col-12 mt-3">
                  <button className="buttonSolicitud" onClick={handleModal}>
                    Enviar solicitud
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolicitudCreate;
