import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { getProveedores } from "../../../functions/api/proveedores";
import { getTrabajoById } from "../../../functions/api/trabajos";
import SolicitudAnalistaForm from "../../../components/forms/solicitudesDetail/SolicitudAnalistaForm";
import {
  downloadFile,
  getArchivosSolicitud,
} from "../../../functions/api/archivos";
import AdminSidebar from "../../../components/nav/AdminSidebar";

const comentarioState = {
  usuario: "",
  fecha: "",
  fechaComentario: "",
  titulo: "",
  tipoComentario: "",
  descripcion: "",
};

const repuestoState = {
  cantRep: "",
  descRep: "",
};

const SolicitudesDetailAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [naves, setNaves] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [values, setValues] = useState("");
  const [rep, setRep] = useState([]);
  const [repuestosData, setRepuestosData] = useState(repuestoState);
  const [observacionesData, setObsevaciones] = useState([]);
  const [comentario, setComentario] = useState(comentarioState);
  const [adjuntosData, setAdjuntosData] = useState([]);

  let navigate = useNavigate();
  let { user } = useSelector((state) => ({ ...state }));

  let { id } = useParams();

  useEffect(() => {
    setLoading(true);

    const delay = 500; // 3 seconds in milliseconds

    const timer = setTimeout(() => {
      // Code to execute after the delay
      setLoading(false);

      loadTrabajo();
      loadProveedores();
      loadAdjuntos();
      console.log("3 seconds have passed");
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const loadTrabajo = () =>
    getTrabajoById(user.token, { id: id }).then((res) =>
      setValues(res.data[0])
    );
  const loadProveedores = () =>
    getProveedores(user.token).then((res) => setProveedores(res.data));
  const loadAdjuntos = () =>
    getArchivosSolicitud(user.token, { id_trabajo: id }).then((res) =>
      setAdjuntosData(res.data)
    );

  const {
    fecha_falla,
    idclasificacion,
    nombre_activo,
    nombre_item,
    severidad,
    fecha_programada,
    clasificacion,
    nrocot,
    fechacotizacion,
    nrosolped,
    fechasolped,
    nrooc,
    fechaoc,
    respuestos,
    nombresolicitante,
    nombreaprobador,
    imagenes,
    observaciones,
    estado_general,
    estado_buque,
    estado_supervisor,
  } = values;

  const {
    titulo,
    fecha,
    usuario,
    fechaComentario,
    descripcion,
    tipoComentario,
  } = comentario;

  const { cantRep, descRep } = repuestosData;

  const obs = values && JSON.parse(observaciones);

  const handleDownloadFile = (e, ruta) => {
    e.preventDefault();
    // toast.success(ruta);
    downloadFile(user.token, ruta)
      .then((res) => {
        toast.success("Documento descargado exitosamente");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <AdminSidebar />
      <div className="home">
        <div className="navCentro">
          <ol class="breadcrumb">
            <li className="breadcrumb-item">
              <i class="fa-solid fa-screwdriver-wrench"></i> Solicitud de
              Trabajo
            </li>
            <li className="breadcrumb-item">Solicitud N° {id}</li>
          </ol>
        </div>
        <div className="row">
          <div className="col-md-12">
            <SolicitudAnalistaForm
              values={values}
              repuestosData={repuestosData}
              comentario={comentario}
              adjuntosData={adjuntosData}
              handleDownloadFile={handleDownloadFile}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolicitudesDetailAdmin;
