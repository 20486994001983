import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import AdminSidebar from "../../../components/nav/AdminSidebar";
import { toast } from "react-toastify";
import Loading from "../../../components/otros/Loading";
import { createUser } from "../../../functions/api/auth";

const initialState = {
  nombre: "",
  email: "",
  password: "",
  rol: "",
  tipo_solicitante: "",
  activo: true,
};

const CreateUser = () => {
  const [loading, setLoading] = useState(false);
  const [usuario, setUsuario] = useState(initialState);
  const [checkboxAnalista, setCheckboxAnalista] = useState([]);
  const { user } = useSelector((state) => ({ ...state }));
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const delay = 500; // 0.5 seconds in milliseconds

    const timer = setTimeout(() => {
      setLoading(false);
      console.log("0.5 seconds have passed");
    }, delay);

    return () => clearTimeout(timer);
  }, []);

  const { nombre, email, password, rol, tipo_solicitante } = usuario;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUsuario({ ...usuario, [name]: value });
  };

  const handleCheckboxChange = (event) => {
    const checkedValue = event.target.value;

    if (event.target.checked) {
      setCheckboxAnalista([...checkboxAnalista, checkedValue]);
    } else {
      setCheckboxAnalista(
        checkboxAnalista.filter((item) => item !== checkedValue)
      );
    }
  };

  const isCheckboxCheckedAnalista = (c) => {
    return (
      checkboxAnalista && checkboxAnalista.some((dictItem) => dictItem === c)
    );
  };

  const submitUser = async (e) => {
    e.preventDefault();

    const newUser = {
      ...usuario,
      tipo_solicitante:
        usuario.rol === "Admin"
          ? "Admin"
          : usuario.rol === "Analista"
          ? JSON.stringify(checkboxAnalista)
          : usuario.tipo_solicitante,
    };

    try {
      await createUser(newUser, user.token);
      toast.success("Usuario creado exitosamente");
      setUsuario(initialState);
      navigate("/admin/usuarios");
    } catch (err) {
      toast.error(err.response?.data || "Error al crear usuario");
    }
  };

  const renderSolicitanteOptions = () => (
    <>
      <label>Tipo de solicitante</label>
      <select
        id="selectInput"
        value={tipo_solicitante}
        name="tipo_solicitante"
        onChange={handleChange}
      >
        <option value="">Escoga el rol del usuario</option>
        <option value="Solicitante">Solicitante</option>
        <option value="Aprobador">Aprobador</option>
      </select>
    </>
  );

  const renderSupervisorOptions = () => (
    <>
      <label>Tipo de supervisor</label>
      <select
        id="selectInput"
        value={tipo_solicitante}
        name="tipo_solicitante"
        onChange={handleChange}
      >
        <option value="">Escoga el rol del usuario</option>
        <option value="Superintendente">Superintendente</option>
        <option value="Ito">Ito</option>
        <option value="Flota">Flota</option>
        <option value="SSO">SSO</option>
        <option value="Armatorial">Armatorial</option>
      </select>
    </>
  );

  const renderAnalistaCheckboxes = () => {
    const checkBoxAnalista = ["Combustible", "Pedimentos", "Trabajos"];
    return (
      <div className="mb-4">
        <label>Roles de analista</label>
        <div className="row">
          {checkBoxAnalista.map((c) => (
            <div className="col-md-3" key={c}>
              <label className="switchN">
                <input
                  type="checkbox"
                  value={c}
                  onChange={handleCheckboxChange}
                  checked={isCheckboxCheckedAnalista(c)}
                />
                <span className="slider"></span>
                <span className="text">{c}</span>
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      <AdminSidebar />
      {loading ? (
        <div className="home">
          <Loading />
        </div>
      ) : (
        <div className="home">
          <div className="navCentro">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <i className="fa-solid fa-users "></i> USUARIOS
              </li>
              <li className="breadcrumb-item">Crear usuario</li>
            </ol>
            <div className="row navTab">
              <div className="col-md-12 navTabItem">
                <Link to="/admin/usuarios">
                  <i className="fa-solid fa-chevron-left mr-2"></i>Atras
                </Link>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <form className="formFormat1" onSubmit={submitUser}>
                <label>Nombre de usuario</label>
                <input
                  type="text"
                  value={nombre}
                  name="nombre"
                  onChange={handleChange}
                />
                <label>Email</label>
                <input
                  type="email"
                  value={email}
                  name="email"
                  onChange={handleChange}
                />
                <label>Password</label>
                <input
                  type="password"
                  value={password}
                  name="password"
                  onChange={handleChange}
                />
                <label>Rol del usuario</label>
                <select
                  id="selectInput"
                  value={rol}
                  name="rol"
                  onChange={handleChange}
                >
                  <option value="">Escoga el rol del usuario</option>
                  <option value="Admin">Administrador</option>
                  <option value="Supervisor">Mantenimiento</option>
                  <option value="Buque">Solicitante</option>
                  <option value="Analista">Analista</option>
                </select>
                {rol === "Buque" && renderSolicitanteOptions()}
                {rol === "Supervisor" && renderSupervisorOptions()}
                {rol === "Analista" && renderAnalistaCheckboxes()}
                <button className="formButton1" type="submit">
                  Crear Usuario
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateUser;
