import React, { useState } from "react";
import Sidebar from "../../../../components/nav/Sidebar";
import { useSelector } from "react-redux";
import useEquiposNaves from "../../../../hooks/useEquiposNave";
import LoadingComponent from "../../../../components/otros/LoadingComponent";
import { useNavigate, useParams } from "react-router-dom";
import EquipoBitacoraCard from "./EquipoBitacoraCard";
import ModalEquiposBitacora from "./ModalEquiposBitacora";
import { createRegistroDiario } from "../../../../functions/api/registrosDiarios";
import { eq } from "lodash";

const initialStateRegistroDiario = {
  nivel: "",
  comprobante: null,
  estanque: "",
};

const RegistrosHome = () => {
  let navigate = useNavigate();

  const [reload, setReload] = useState(false);
  const [modalRegistrosDiarios, setModalRegistrosDiarios] = useState(false);
  const [equipo, setEquipo] = useState({});
  const [preview, setPreview] = useState(null);
  const [registroDiario, setRegistroDiario] = useState(
    initialStateRegistroDiario
  );
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const user = useSelector((state) => state.user);
  const { idNave } = useParams();

  const handleReload = () => {
    setReload(!reload);
  };

  const { data, loading } = useEquiposNaves(user.token, idNave, reload);

  if (loading)
    return (
      <div>
        <Sidebar />
        <div className="home">
          <LoadingComponent />
        </div>
      </div>
    );

  const handleModalOpen = (data) => {
    if (!modalRegistrosDiarios) {
      setEquipo(data);
    }

    setModalRegistrosDiarios(!modalRegistrosDiarios);
  };

  const handleRegistroDiarioChange = (e) => {
    setRegistroDiario({ ...registroDiario, [e.target.name]: e.target.value });
  };

  const createRegistro = () => {
    console.log("creando registro diario");

    setLoadingSubmit(true);
    registroDiario.estanque = equipo.id;

    createRegistroDiario(user.token, registroDiario)
      .then((res) => {
        console.log(res);
        setRegistroDiario(initialStateRegistroDiario);
        handleReload();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingSubmit(false);
        setModalRegistrosDiarios(false);
      });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setRegistroDiario((prevState) => ({
          ...prevState,
          comprobante: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      {modalRegistrosDiarios && (
        <ModalEquiposBitacora
          onOpenChange={handleModalOpen}
          values={registroDiario}
          handleChange={handleRegistroDiarioChange}
          handleSubmit={createRegistro}
          handleFileChange={handleFileChange}
        />
      )}
      <Sidebar />
      <div className="home">
        <div className="navCentro mb-4">
          <ol class="breadcrumb">
            <li className="breadcrumb-item">
              <i className="fa-solid fa-marker "></i> Registros Bitácora
            </li>
            <li className="breadcrumb-item">Equipos</li>
          </ol>
        </div>
        <button
          className="backButton"
          onClick={() => navigate("/buque/registros-bitacora")}
        >
          <i class="fa-solid fa-chevron-left"></i> Volver
        </button>
        <div className="row content">
          <div className="col-md-12 mt-2">
            <h4 className="subtitleContent">Estanques</h4>
            <hr />
          </div>
          <div className="col-md-12">
            <div className="row">
              {data.length > 0 &&
                data
                  .filter(
                    (d) =>
                      (d.tipo_estanque === "diario" ||
                        d.tipo_estanque === "" ||
                        d.tipo_estanque === "almacenamiento") &&
                      d.tipo_equipo === "estanque"
                  )
                  .map((c) => (
                    <EquipoBitacoraCard
                      handleModalOpen={handleModalOpen}
                      data={c}
                    />
                  ))}
            </div>
          </div>
          <div className="col-md-12 mt-2">
            <h4 className="subtitleContent">Generadores</h4>
            <hr />
          </div>
          <div className="col-md-12">
            <div className="row">
              {data.length > 0 &&
                data
                  .filter(
                    (d) =>
                      (d.tipo_estanque === "diario" ||
                        d.tipo_estanque === "") &&
                      d.tipo_equipo === "generador"
                  )
                  .map((c) => (
                    <EquipoBitacoraCard
                      handleModalOpen={handleModalOpen}
                      data={c}
                    />
                  ))}
            </div>
          </div>
          <div className="col-md-12 mt-2">
            <h4 className="subtitleContent">Motores</h4>
            <hr />
          </div>
          <div className="col-md-12">
            <div className="row">
              {data.length > 0 &&
                data
                  .filter(
                    (d) =>
                      (d.tipo_estanque === "diario" ||
                        d.tipo_estanque === "") &&
                      d.tipo_equipo === "motor"
                  )
                  .map((c) => (
                    <EquipoBitacoraCard
                      handleModalOpen={handleModalOpen}
                      data={c}
                    />
                  ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrosHome;
