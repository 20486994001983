import React,{ useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';

import Loading from '../../../components/otros/Loading'
import DataTable from 'react-data-table-component';
import AdminSidebar from '../../../components/nav/AdminSidebar'


import { getUsers } from '../../../functions/api/auth'
import { getActivosCol } from '../../../functions/api/activos'
import { getTipoActivos } from '../../../functions/api/tipoActivos'
import { getAsignaciones,createAsignaciones } from '../../../functions/api/asignacionNaves'
import { toast } from 'react-toastify';

const AsignarNave = () => {

    const [loading,setLoading] = useState(false)
    const [usuarios,setUsuarios] = useState([])
    const [naves,setNaves] = useState([])
    const [tipoActivo,setTipoActivo] = useState([]) 
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [asignaciones,setAsignaciones] = useState([])
    const [userSelected,setUserSelected] = useState([])
    const [checkboxValues, setCheckboxValues] = useState([]);

    let {user} = useSelector((state) =>({...state}))

    useEffect(() => {
      
      setLoading(true)
      loadUsers()
      loadNaves()
      loadTActivos()
      setLoading(false)
       
  }, [])
  
    const loadUsers = () => getUsers(user.token).then( res => {
      setUsuarios(res.data)
    }
    ).catch( err => 
        console.log(err)
    )

    const loadNaves = () => getActivosCol(user.token).then( res => {
      setNaves(res.data)
    })

    const loadTActivos = () => getTipoActivos(user.token).then( res => {
      setTipoActivo(res.data)
    })




    useEffect(() => {
      
      console.log(userSelected)

      getAsignaciones(user.token).then( res => {
        const data = res.data
        const asigData = data.filter(d => d.userid === userSelected)

        
        setAsignaciones(asigData)
      })


  }, [userSelected])

    const selectedUser = (e) =>{
      setUserSelected(e.target.value)
      
    }



    const isCheckboxChecked = (c) => {
      return asignaciones && asignaciones.some(
        (dictItem) =>
        dictItem.userid === userSelected && dictItem.activo_id === c.id
      );
    };


    const handleCheckboxChange = (event) => {
      const checkedValue = event.target.value;


      
      
      if (event.target.checked) {
        setAsignaciones([...asignaciones,
          {userid:userSelected,
            activo_id:checkedValue}])
      } else {
        setAsignaciones(asignaciones.filter((item) => item.activo_id !== checkedValue));
      }
    };
  

    const saveValues = () => {

      createAsignaciones(user.token, asignaciones).then( r=>{
        toast.success('Naves asignadas con exito')
        setUserSelected([])
        setAsignaciones([])
        setIsModalOpen(false)
      }
        
      ).catch( err => {
        console.log(err)
      })
    }

    const handleModalOpen = (e) => {
      setUserSelected(e.target.value)
      setIsModalOpen(true);
    };
  
    const handleModalClose = () => {
      setIsModalOpen(false);
    };

    const columns = [
      {
          name: 'Nombre Usuario',
          selector: row => row.nombre
      },
      {
        name: 'Rol',
        selector: row => row.rol
      },
      {
        name: 'Asignar',
        selector: row => row.asignar,
      }
     
    ]


    const data = usuarios && usuarios
    .filter( d => d.activo === true)
    .map( c => {
      return { 
        nombre: c.nombre, 
        rol: c.rol, 
        asignar: <button onClick={handleModalOpen} value={c.id}  className='btn btn-success btn-sm'> <i class="fa-solid fa-ship icon"></i> Asignar</button >
      }
    })



  return (
    <div> 
        <AdminSidebar />
        {isModalOpen && (
                      <div className=" modal-naves">
                          <div>
                          <div className="title">
                            <h6>Asignar Naves a {userSelected && usuarios && usuarios.filter( d => d.id === userSelected).map( c => (
                                    c.nombre
                                ))
                                }</h6>
                        </div>
                        <div className="row ">
                            
                            {naves && naves.map(c => (
                                <div className="col-md-6 nave">
                                        <div className="text">
                                            <label class="switchN">
                                            <input type="checkbox" value={c.id} onChange={handleCheckboxChange}  checked={isCheckboxChecked(c)}/>
                                            <span class="slider"></span>
                                            <span className="text">{c.nombre_activo} - {c.tipo_activo}</span>
                                            </label> 
                                
                                        </div>
                                        
                                </div>
                              ))}
                            </div>
                            <div className="row">
                              <div className="col-6">
                                <button onClick={handleModalClose} className='btn-red'>CERRAR</button>
                              </div>
                              <div className="col-6">
                                <button onClick={saveValues} className='guardar-asignacion-naves'>GUARDAR</button>
                              </div>
                            </div>
                          </div>
                      </div>
                    )}
        {loading ? (
          
        <div className="home">
          <Loading />
        </div>
        ):(
            <div className="home">
                <div className='navCentro'>
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item">
                        <i class="fa-solid fa-users "></i>  USUARIO</li>
                        <li className="breadcrumb-item">Asignar Nave</li>
                    </ol>
                    
                    <div className="row navTab">
                        <div className="col-md-12 navTabItem">
                            <Link to='/admin/usuarios'><i class="fa-solid fa-chevron-left mr-2"></i>Atras</Link>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-12"> 
                    <div className='filtros'>
                                    <span >Filtros:</span>
                            <ul>
                             
                            </ul>
                        </div>
                        
                        <DataTable 
                        columns={columns}
                        data={data}
                        pagination
                        />
                        
                    </div>
                    
                    
                    </div>
            </div>
        )}
    </div>
  )
}

export default AsignarNave