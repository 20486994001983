import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Sidebar from "../../components/nav/Sidebar";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { getClasificaciones } from "../../functions/api/clasificaciones";
import { getAsignacionesByUserId } from "../../functions/api/asignacionNaves";
import { getProveedores } from "../../functions/api/proveedores";
import { getTrabajoById, updateTrabajo } from "../../functions/api/trabajos";
import SolicitudSolicitanteForm from "../../components/forms/solicitudesDetail/SolicitudSolicitanteForm";
import SolicitudAprobadorForm from "../../components/forms/solicitudesDetail/SolicitudAprobadorForm";
import { sendMail } from "../../functions/api/mail";
import {
  downloadFile,
  getArchivosSolicitud,
  uploadFile,
} from "../../functions/api/archivos";

const comentarioState = {
  usuario: "",
  fecha: "",
  fechaComentario: "",
  titulo: "",
  tipoComentario: "",
  descripcion: "",
};

const repuestoState = {
  cantRep: "",
  descRep: "",
};

const SolicitudDetail = () => {
  const [loading, setLoading] = useState(false);
  const [naves, setNaves] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [values, setValues] = useState("");
  const [rep, setRep] = useState([]);
  const [repuestosData, setRepuestosData] = useState(repuestoState);
  const [observacionesData, setObsevaciones] = useState([]);
  const [comentario, setComentario] = useState(comentarioState);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [adjuntosData, setAdjuntosData] = useState([]);

  let navigate = useNavigate();
  let { user } = useSelector((state) => ({ ...state }));

  let { id } = useParams();

  useEffect(() => {
    setLoading(true);

    const delay = 500; // 3 seconds in milliseconds

    const timer = setTimeout(() => {
      // Code to execute after the delay
      setLoading(false);
      loadAdjuntos();
      loadTrabajo();
      loadProveedores();

      console.log("3 seconds have passed");
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const loadTrabajo = () =>
    getTrabajoById(user.token, { id: id }).then((res) =>
      setValues(res.data[0])
    );
  const loadProveedores = () =>
    getProveedores(user.token).then((res) => setProveedores(res.data));
  const loadAdjuntos = () =>
    getArchivosSolicitud(user.token, { id_trabajo: id }).then((res) =>
      setAdjuntosData(res.data)
    );

  const {
    fecha_falla,
    idclasificacion,
    nombre_activo,
    nombre_item,
    severidad,
    fecha_programada,
    clasificacion,
    nrocot,
    fechacotizacion,
    nrosolped,
    fechasolped,
    nrooc,
    fechaoc,
    respuestos,
    nombresolicitante,
    nombreaprobador,
    imagenes,
    observaciones,
    estado_general,
    estado_buque,
    estado_supervisor,
  } = values;

  const {
    titulo,
    fecha,
    usuario,
    fechaComentario,
    descripcion,
    tipoComentario,
  } = comentario;

  const { cantRep, descRep } = repuestosData;

  const obs = values && JSON.parse(observaciones);

  const deleteRepuesto = (index) => {
    const updatedArray = [...rep]; // Create a copy of the array
    updatedArray.splice(index, 1); // Remove the item at the specified position
    setRep(updatedArray);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    values.observaciones = JSON.stringify(obs.concat(observacionesData));

    updateTrabajo(user.token, values)
      .then((r) => {
        toast.success("Solicitud actualizada con exito");
        setObsevaciones([]);
        navigate(`/buque/mis-solicitudes/${id}`);
      })
      .catch((err) => console.log(err));
  };

  const handleAprobar = (e) => {
    e.preventDefault();

    values.observaciones = JSON.stringify(obs.concat(observacionesData));

    const estadoG = JSON.parse(estado_general);

    const aprobValue = {
      estado: "Aprobada",
      fecha: new Date(),
    };

    const newEstado = [...estadoG, aprobValue];

    values.estado_general = JSON.stringify(newEstado);

    updateTrabajo(user.token, values)
      .then((r) => {
        toast.success("Solicitud actualizada con exito");

        const body = {
          clasificacion: r.data[0].clasificacion,
          nombre_activo: r.data[0].nombre_activo,
          fecha_falla: r.data[0].fecha_falla,
          nombresolicitante: r.data[0].nombresolicitante,
          observaciones: r.data[0].observaciones,
          nombre_item: r.data[0].nombre_item,
          id: r.data[0].id,
          email: user.email,
          nave_id: r.data[0].id_activo,
        };

        sendMail(user.token, body).then((res) =>
          toast.success("Email enviado!").catch((err) => console.log(err))
        );
        setObsevaciones([]);
        navigate(`/buque/mis-solicitudes/${id}`);
      })
      .catch((err) => console.log(err));
  };

  const handleRechazar = (e) => {
    e.preventDefault();

    values.observaciones = JSON.stringify(obs.concat(observacionesData));

    const estadoG = JSON.parse(estado_general);

    const estadoValue = {
      estado: "Rechazada",
      fecha: new Date(),
    };

    const newEstado = [...estadoG, estadoValue];

    values.estado_general = JSON.stringify(newEstado);

    updateTrabajo(user.token, values)
      .then((r) => {
        toast.success("Solicitud actualizada con exito");
        setObsevaciones([]);
        navigate(`/buque/mis-solicitudes/${id}`);
      })
      .catch((err) => console.log(err));
  };

  const handleRepuestoChange = (e) => {
    e.preventDefault();
    setRepuestosData({ ...repuestosData, [e.target.name]: e.target.value });
  };

  const handleAddRepuestosData = (e) => {
    e.preventDefault();
    if (cantRep === "") {
      toast.error("Falta agregar cantidad");
    } else if (descRep === "") {
      toast.error("Falta agregar descripción");
    } else {
      setRep((prevData) => [...prevData, repuestosData]);
      setRepuestosData(repuestoState);
    }
  };

  const handleCerrar = (e) => {
    e.preventDefault();

    values.observaciones = JSON.stringify(obs.concat(observacionesData));

    const estadoG = JSON.parse(estado_general);

    const estadoValue = {
      estado: "Cerrado",
      fecha: new Date(),
    };

    const newEstado = [...estadoG, estadoValue];

    values.estado_general = JSON.stringify(newEstado);

    updateTrabajo(user.token, values)
      .then((r) => {
        toast.success("Solicitud cerrada con exito");
        setObsevaciones([]);
        setRep([]);

        navigate(`/buque/mis-solicitudes/${id}`);
      })
      .catch((err) => console.log(err));
  };

  // const capturedImages = JSON.parse(imagenes)

  const handleComentarioChange = (e) => {
    setComentario({ ...comentario, [e.target.name]: e.target.value });
  };

  const deleteItem = (index) => {
    const updatedArray = [...observacionesData]; // Create a copy of the array
    updatedArray.splice(index, 1); // Remove the item at the specified position
    setObsevaciones(updatedArray);
  };

  // observaciones array dictionary
  const handleAddDataObs = (e) => {
    e.preventDefault();

    if (titulo === "") {
      toast.error("Falta agregar título ");
    } else if (fecha === "") {
      toast.error("Falta agregar fecha");
    } else if (tipoComentario === "") {
      toast.error("Falta seleccionar tipo de comentario");
    } else if (descripcion === "") {
      toast.error("Falta agregar descripción");
    } else {
      const currentDate = new Date();
      comentario.usuario = user.nombre;
      comentario.fechaComentario = currentDate;
      setObsevaciones((prevData) => [...prevData, comentario]);
      setComentario(comentarioState);
    }
  };

  const handleDownloadFile = (e, ruta) => {
    e.preventDefault();
    // toast.success(ruta);
    downloadFile(user.token, ruta)
      .then((res) => {
        toast.success("Documento descargado exitosamente");
      })
      .catch((err) => console.log(err));
  };

  const handleFileChange = (event) => {
    setSelectedFiles([...event.target.files]);
  };

  const handleFileUpload = (e) => {
    e.preventDefault();

    selectedFiles.forEach((file) => {
      let params = {};

      params.idTrabajo = values.id;
      params.nombre_documento = file.name;

      uploadFile(user.token, params, file)
        .then((res) => {
          toast.success("Archivo subido exitosamente.");
          loadTrabajo();
          loadAdjuntos();
          selectedFiles([]);
        })
        .catch((err) => console.log(err));
      // Append the file with its name
      // formData.append("files", file, file.name);
    });
  };

  return (
    <div>
      <Sidebar />
      <div className="home">
        <div className="navCentro">
          <ol class="breadcrumb">
            <li className="breadcrumb-item">
              <i class="fa-solid fa-screwdriver-wrench"></i> Solicitud de
              Trabajo
            </li>
            <li className="breadcrumb-item">Solicitud N° {id}</li>
          </ol>
        </div>
        <div className="row">
          <div className="col-md-12">
            {/* {JSON.stringify(values.accionestomadas)} */}

            {user.tipo_solicitante &&
              user.tipo_solicitante === "Solicitante" && (
                <SolicitudSolicitanteForm
                  values={values}
                  comentario={comentario}
                  observacionesData={observacionesData}
                  deleteItem={deleteItem}
                  handleDownloadFile={handleDownloadFile}
                  handleComentarioChange={handleComentarioChange}
                  handleAddDataObs={handleAddDataObs}
                  handleSubmit={handleSubmit}
                  handleCerrar={handleCerrar}
                  adjuntosData={adjuntosData}
                  handleFileChange={handleFileChange}
                  selectedFiles={selectedFiles}
                  handleFileUpload={handleFileUpload}
                  proveedores={proveedores}
                />
              )}

            {user.tipo_solicitante && user.tipo_solicitante === "Aprobador" && (
              <SolicitudAprobadorForm
                values={values}
                comentario={comentario}
                observacionesData={observacionesData}
                deleteItem={deleteItem}
                handleFileChange={handleFileChange}
                selectedFiles={selectedFiles}
                handleFileUpload={handleFileUpload}
                adjuntosData={adjuntosData}
                handleDownloadFile={handleDownloadFile}
                handleComentarioChange={handleComentarioChange}
                handleAddDataObs={handleAddDataObs}
                handleSubmit={handleSubmit}
                handleCerrar={handleCerrar}
                handleRechazar={handleRechazar}
                handleAprobar={handleAprobar}
                handleAddRepuestosData={handleAddRepuestosData}
                handleRepuestoChange={handleRepuestoChange}
                rep={rep}
                proveedores={proveedores}
                repuestosData={repuestosData}
                deleteRepuesto={deleteRepuesto}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolicitudDetail;
