import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Sidebar from "../../../components/nav/Sidebar";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { getClasificaciones } from "../../../functions/api/clasificaciones";
import { getActivosByUser } from "../../../functions/api/activos";
import { getAsignacionesByUserId } from "../../../functions/api/asignacionNaves";
import { createTrabajo } from "../../../functions/api/trabajos";
import { getItemsActivosById2 } from "../../../functions/api/sistemas";
import { uploadFile } from "../../../functions/api/archivos";
import SolicitudModalComentario from "../../buque/SolicitudModalComentario";
import AdminSidebar from "../../../components/nav/AdminSidebar";

const initialState = {
  fecha_falla: "",
  idclasificacion: "",
  id_activo: "",
  idubicacion: "",
  severidad: "",
  activo: true,
  accionestomadas: "",
  repuestos: "",
  solicitante: "",
  imagenes: "",
  observaciones: "",
  estado_general: "",
  estado_buque: "",
  estado_supervisor: "",
  repuestos: "",
  periferico: "",
};

const comentarioState = {
  usuario: "",
  fecha: "",
  fechaComentario: "",
  titulo: "",
  tipoComentario: "",
  descripcion: "",
};

const comentarioCustomState = {
  descripcion: "",
  impacto: "",
  obsSintomas: "",
  accionesPreliminares: "",
  capacidadesRemanentes: "",
};

const SolicitudCreateAdmin = () => {
  const [loading, setLoading] = useState(false);
  const [clasificaciones, setClasificaciones] = useState([]);
  const [activos, setActivos] = useState([]);
  const [asignaciones, setAsignaciones] = useState([]);
  const [values, setValues] = useState(initialState);
  const [acciones, setAcciones] = useState([]);
  const [sistemas, setSistemas] = useState([]);
  const [observacionesData, setObsevaciones] = useState([]);
  const [comentario, setComentario] = useState(comentarioState);
  const [comentarioCustom, setComentarioCustom] = useState(
    comentarioCustomState
  );

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [open, setOpen] = useState(false);

  const [fileName, setFileName] = useState("");

  let navigate = useNavigate();
  let { user } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    setLoading(true);

    const delay = 500; // 3 seconds in milliseconds

    const timer = setTimeout(() => {
      // Code to execute after the delay
      setLoading(false);

      loadClasificaciones();
      loadActivos();
      loadAsignaciones();
      loadSistemasActivo();

      console.log("3 seconds have passed");
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const {
    fecha_falla,
    idclasificacion,
    id_activo,
    id_item_activos,
    severidad,
    respuestos,
    periferico,
    fecha_programada,
    solicitante,
    imagenes,
    observaciones,
    estado_general,
    estado_buque,
    accionestomadas,
  } = values;

  const {
    titulo,
    fecha,
    usuario,
    fechaComentario,
    descripcion,
    tipoComentario,
  } = comentario;

  const loadClasificaciones = () =>
    getClasificaciones(user.token).then((res) => {
      setClasificaciones(res.data);
    });
  const loadActivos = () =>
    getActivosByUser(user.token, { id: user.id }).then((res) => {
      setActivos(res.data);
    });
  const loadAsignaciones = () =>
    getAsignacionesByUserId(user.token, { id: user.id }).then((res) => {
      setAsignaciones(res.data);
    });
  const loadSistemasActivo = () =>
    getItemsActivosById2(user.token, { id: id_activo }).then((res) => {
      setSistemas(res.data);
    });

  useEffect(() => {
    loadSistemasActivo();
  }, [id_activo]);

  const handleComentarioChange = (e) => {
    setComentario({ ...comentario, [e.target.name]: e.target.value });
  };

  const handleComentariocustomChange = (e) => {
    setComentarioCustom({
      ...comentarioCustom,
      [e.target.name]: e.target.value,
    });
  };

  const deleteItem = (index) => {
    const updatedArray = [...observacionesData]; // Create a copy of the array
    updatedArray.splice(index, 1); // Remove the item at the specified position
    setObsevaciones(updatedArray);
  };

  // observaciones array dictionary
  const handleAddDataObs = (e) => {
    e.preventDefault();

    if (titulo === "") {
      toast.error("Falta agregar título ");
    } else if (fecha === "") {
      toast.error("Falta agregar fecha");
    } else if (tipoComentario === "") {
      toast.error("Falta seleccionar tipo de comentario");
    } else if (descripcion === "") {
      toast.error("Falta agregar descripción");
    } else {
      const currentDate = new Date();
      comentario.usuario = user.nombre;
      comentario.fechaComentario = currentDate;
      setObsevaciones((prevData) => [...prevData, comentario]);
      setComentario(comentarioState);
    }
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(comentarioCustom);

    let comentario = {};

    values.accionestomadas = "planificado";
    values.nivel = 1;
    values.solicitante = user.id;
    values.observaciones = JSON.stringify([comentario]);
    values.repuestos = JSON.stringify(acciones);

    values.estado_general = JSON.stringify([
      {
        estado: "Programada",
        fecha: new Date(),
      },
    ]);

    createTrabajo(user.token, values)
      .then((res) => {
        toast.success("Mantenimiento planificado creado con exito");
        setValues([]);
        setAcciones([]);
        setObsevaciones([]);

        console.log(res.data[0].id);

        selectedFiles.forEach((file) => {
          let params = {};

          params.idTrabajo = res.data[0].id;
          params.nombre_documento = file.name;

          uploadFile(user.token, params, file)
            .then((res) => {
              toast.success(res);
            })
            .catch((err) => console.log(err));
          // Append the file with its name
          // formData.append("files", file, file.name);
        });

        navigate("/admin/mis-solicitudes");
      })
      .catch((err) => console.log(err));
  };

  const formatDate = (date) => {
    const inputDate = new Date(date);
    const hours = String(inputDate.getHours()).padStart(2, "0");
    const minutes = String(inputDate.getMinutes()).padStart(2, "0");
    const day = String(inputDate.getDate()).padStart(2, "0");
    const month = String(inputDate.getMonth() + 1).padStart(2, "0");
    const year = inputDate.getFullYear();

    return `${hours}:${minutes} ${day}-${month}-${year}`;
  };

  const handleFileChange = (event) => {
    setSelectedFiles([...event.target.files]);
  };

  const handleModal = (e) => {
    e.preventDefault();

    // if (accionestomadas === "pedimento") {
    //   handleSubmit();
    //   return toast.success("Pedimento creado exitosamente.");
    // }
    setOpen(!open);
  };

  return (
    <div>
      <AdminSidebar />

      <div className="home">
        <div className="navCentro">
          <ol class="breadcrumb">
            <li className="breadcrumb-item">
              <i class="fa-solid fa-screwdriver-wrench"></i> SOLICITAR TRABAJO
            </li>
            <li className="breadcrumb-item">Solicitar</li>
          </ol>
        </div>
        <div className="row">
          <div className="col-md-12">
            <form>
              <div className="row formSolicitud">
                <div className="col-md-12 mb-3">
                  <p className="title-section">Información Falla</p>
                </div>
                <div className="col-md-4">
                  <label className="mr-2 label-title">Fecha de la falla:</label>
                  <input
                    className="w-100 inputFormGest"
                    type="datetime-local"
                    id="datetime"
                    name="fecha_falla"
                    value={fecha_falla}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-4">
                  <label className="mr-2 label-title">Nave: </label>
                  <br />
                  <select
                    style={{ width: "100%" }}
                    name="id_activo"
                    value={id_activo}
                    onChange={handleChange}
                    className="inputFormGest"
                  >
                    <option selected>Seleccionar Nave</option>
                    {activos &&
                      activos.map((c) => (
                        <option key={c.id} value={c.id}>
                          {c.nombre_activo}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-4">
                  <label className="mr-2 label-title">Clasificacion: </label>
                  <br />
                  <select
                    style={{ width: "100%" }}
                    name="idclasificacion"
                    value={idclasificacion}
                    onChange={handleChange}
                    className="inputFormGest"
                  >
                    <option selected>Seleccionar estado</option>
                    {clasificaciones &&
                      clasificaciones.map((c) => (
                        <option key={c.id} value={c.id}>
                          {c.clasificacion}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-4  ">
                  <label className="mr-2 label-title">Equipo:</label>
                  <br />

                  <select
                    style={{ width: "100%" }}
                    name="id_item_activos"
                    value={id_item_activos}
                    onChange={handleChange}
                    className="inputFormGest"
                  >
                    <option selected>Seleccionar Equipo</option>
                    {sistemas.length > 0 &&
                      sistemas.map((c) => (
                        <option key={c.id} value={c.id}>
                          {c.nombre_item}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="col-md-4  ">
                  <label className="mr-2 label-title">Periferico:</label>
                  <br />
                  <input
                    name="periferico"
                    value={periferico}
                    style={{ height: "25px", width: "100%" }}
                    onChange={handleChange}
                    className="inputFormGest"
                  />
                </div>

                <div className="col-md-12">
                  <hr />
                </div>
                <div className="col-md-8">
                  <label className="mr-2 title-section">Comentarios</label>
                  <div className="comentarios">
                    {observacionesData &&
                      observacionesData.map((c, index) => (
                        <div className="comentario-body" key={index}>
                          <div className="header-comentario">
                            <ul className="userList">
                              <li className="userLi">{c.titulo}</li>
                              <div className="userLiContainer">
                                <li className="userLi">{c.usuario}</li>
                                <li
                                  className={
                                    c.tipoComentario === "Observación"
                                      ? "tipoComentarioLi"
                                      : "tipoComentarioLi2"
                                  }
                                >
                                  {c.tipoComentario}
                                </li>
                                <li>{c.fecha}</li>
                                <li>
                                  <button
                                    className="borarComent"
                                    onClick={() => deleteItem(index)}
                                  >
                                    <i class="fa-solid fa-trash"></i>
                                  </button>
                                </li>
                              </div>
                            </ul>
                          </div>
                          <div className="body-comentario">
                            <p className="body-desc">{c.descripcion}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="w-100">
                    <form className="cardFormComentarios">
                      <input
                        required
                        value={titulo}
                        name="titulo"
                        onChange={handleComentarioChange}
                        className="titleInput"
                        placeholder="Título"
                      />
                      <input
                        required
                        value={fecha}
                        name="fecha"
                        onChange={handleComentarioChange}
                        className="selectInput"
                        type="datetime-local"
                        placeholder="Título"
                      />
                      <select
                        required
                        value={tipoComentario}
                        name="tipoComentario"
                        onChange={handleComentarioChange}
                        className="selectInput"
                      >
                        <option value="">Tipo de comentario</option>
                        <option value="Accion Tomada">Acción tomada</option>
                        <option value="Observación">Observación</option>
                        {user && user.tipo_solicitante === "Ito" && (
                          <option value="Tarea">Tarea</option>
                        )}
                      </select>
                      <textArea
                        required
                        value={descripcion}
                        name="descripcion"
                        onChange={handleComentarioChange}
                        className="textAreaInput "
                        placeholder="Descripción"
                      ></textArea>
                    </form>
                    <button
                      className="buttonSolicitud"
                      onClick={handleAddDataObs}
                    >
                      Agregar comentario
                    </button>
                  </div>
                </div>
                <div className="col-md-4">
                  <label className="mr-2 title-section">Fecha Programada</label>
                  <input
                    className="inputFormGest w-100"
                    type="datetime-local"
                    onChange={handleChange}
                    name="fecha_programada"
                    value={fecha_programada}
                  />
                  <label className="mr-2 title-section mt-4">Adjuntos</label>
                  <input
                    type="file"
                    className="inputFormGest"
                    multiple
                    onChange={handleFileChange}
                  />
                  <ul>
                    {selectedFiles.map((file, index) => (
                      <li key={index}>{file.name}</li>
                    ))}
                  </ul>
                </div>

                <div className="col-12 mt-3">
                  <button className="buttonSolicitud" onClick={handleSubmit}>
                    Solicitar trabajo
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolicitudCreateAdmin;
