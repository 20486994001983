import React,{ useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import AdminSidebar from '../../../components/nav/AdminSidebar'

import Loading from '../../../components/otros/Loading'
import DataTable from 'react-data-table-component';


import { getTipoActivos } from '../../../functions/api/tipoActivos'


const CategoriaActivos = () => {
    const [loading,setLoading] = useState(false)
    const [activos,setActivos] = useState([])
    const [tipoActivo,setTipoActivo] = useState([])


    let {user} = useSelector((state) =>({...state}))

    useEffect(() => {
        setLoading(true)
        loadTipoActivos()
        setLoading(false)
    }, [])


    const loadTipoActivos = () => getTipoActivos(user.token).then( res => {
        setTipoActivo(res.data)
    })


    const columns = [
        {
            name: 'Tipo Activo',
            selector: row => row.tipo_activo,
        },
        {
            name: 'Activo',
            selector: row => row.activo,
        },
        {
            name: 'Editar',
            selector: row => row.editar,
        }
    ]

    const data = tipoActivo && tipoActivo.map( c => {
        return {
            tipo_activo:c.tipo_activo,
            activo: c.activo === true ? <button className='btn btn-primary'>Activo</button>:<button className='btn btn-secondary'>Inactivo</button>,
            editar:<Link to={`/admin/datos-maestros/update-tipo-activo/${c.id}`} className='btn btn-success'>Editar</Link >
        }
    }  )

   

  return (
    <div> 
        <AdminSidebar />
        {loading ? (
          
        <div className="home">
          <Loading />
        </div>
        ):(
            <div className="home">
                <div className='navCentro'>
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item">
                        <i class="fa-solid fa-ship"></i> ACTIVOS</li>
                        <li className="breadcrumb-item"> Categoria activos</li>
                    </ol>
                    <div className="row navTab">
                        
                        <div className="col-md-6 navTabItem">
                            <Link to='/admin/datos-maestros/activos'><i class="fa-solid fa-chevron-left mr-2"></i>Atras</Link>
                        </div>
                        <div className="col-md-6 navTabItem">
                            <Link to='/admin/datos-maestros/create-tipo-activo'>Create Categoria Activos<i class="fa-solid fa-circle-right ml-2"></i></Link>
                        </div>
                        
                    </div>
                    <div className="row">
                    <div className="col-md-12">
                        <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        />
                       
                        
                    </div>
                    </div>
                </div>
            </div>
        )}
    </div>
  )
}

export default CategoriaActivos 