import axios from 'axios'



export const getUbicaciones = async(token) =>{
    return await axios.get(`${process.env.REACT_APP_API}/get-ubicacion-fallas`,{
        headers:{
            "x-auth-token":token
        }
    })
}

export const getUbicacionById = async(token,id) =>{
    return await axios.post(`${process.env.REACT_APP_API}/get-ubicacion-falla-by-id`,id,{
        headers:{
            "x-auth-token":token
        }
    })
}

export const createUbicacion = async(token,body) =>{
    return await axios.post(`${process.env.REACT_APP_API}/create-ubicacion-falla`,body,{
        headers:{
            "x-auth-token":token
        }
    })
}

export const updateUbicacion = async(token,body) =>{
    return await axios.put(`${process.env.REACT_APP_API}/update-ubicacion-falla`,body,{
        headers:{
            "x-auth-token":token
        }
    })
}

