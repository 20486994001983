import React,{ useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom'
import AdminSidebar from '../../../components/nav/AdminSidebar'
import { toast } from 'react-toastify'

import Loading from '../../../components/otros/Loading'

import { createProveedor } from '../../../functions/api/proveedores';

const initialState = {
    rut:'',
    proveedor:'',
    activo:true
}


function formatRut(rut) {
    const rutLimpio = rut.replace(/[^0-9kK]/g, '');
      const cuerpo = rutLimpio.slice(0, -1);
      const dv = rutLimpio.slice(-1).toUpperCase();
      if (rutLimpio.length < 2) return rutLimpio;
      let cuerpoFormatoMiles = cuerpo
          .toString()
          .split('')
          .reverse()
          .join('')
          .replace(/(?=\d*\.?)(\d{3})/g, '$1.');
      cuerpoFormatoMiles = cuerpoFormatoMiles
          .split('')
          .reverse()
          .join('')
          .replace(/^[\.]/, '');
      return `${cuerpoFormatoMiles}-${dv}`;
  
  }
  
  function validateRut(rut) {
   
  
    const rutLimpio = rut.replace(/[^0-9kK]/g, '');
    if (rutLimpio.length < 2) return false;
    const cuerpo = rutLimpio.slice(0, -1);
    const dv = rutLimpio.slice(-1).toUpperCase();
    if (!cuerpo.replace(/[^0-9]/g, '')) return false;
  
  
  
    // Calculate verification digit
    let suma = 0;
    let multiplo = 2;
    for (let i = cuerpo.length - 1; i >= 0; i--) {
      suma += cuerpo.charAt(i) * multiplo;
      multiplo = (multiplo + 1) % 8 || 2;
    }
    const resultado = 11 - (suma % 11);
    const verificador = resultado === 11 ? '0' : resultado === 10 ? 'K' : resultado.toString();
    
  
  
    
  
  
    let errRut = true;
    const res = verificador === dv;
    if (rut && res === true) {
        errRut = false;
    }
    return errRut;
  
  }
  
  
const CreateProveedor = () => {

    const [loading,setLoading] = useState(false)
    const [proveedorData,setProveedor] = useState(initialState)

    let {user} = useSelector((state) =>({...state}))
    let navigate = useNavigate ()

    useEffect(() => {
        setLoading(true)

          setLoading(false)
          
    }, [])

    const {rut,proveedor,activo} = proveedorData


    const handleChange = (e) =>{

        setProveedor({...proveedorData, [e.target.name]:e.target.value})

    }

    const handleKeyPress = (event) => {
        const charCode = event.which || event.keyCode;
        // Only allow alphanumeric characters (letters and numbers)
        if (charCode !== 8 && !/^[a-zA-Z0-9]+$/.test(String.fromCharCode(charCode))) {
            event.preventDefault();
        }
    };

    const handleRutChange = (e) => {
        let rut = formatRut(e.target.value) 
        setProveedor({...proveedorData,['rut']:rut })
    }

    const submitData = (e) =>{

        e.preventDefault()
        
        createProveedor(user.token,proveedorData)
        .then( r => {
            toast.success('Proveedor creado exitosamente')
            setProveedor([])
            navigate('/admin/datos-maestros/proveedores')
        }
        )
        .catch( err =>
                toast.error(err.response.data)
        )

    }

  return (
    <div> 
        <AdminSidebar />
        {loading ? (
          
        <div className="home">
          <Loading />
        </div>
        ):(
            <div className="home">
                <div className='navCentro'>
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><i class="fa-solid fa-truck-field icon"></i> PROVEEDORES</li>
                        <li className="breadcrumb-item">Crear proveedor</li>
                    </ol>
                    <div className="row navTab">
                        <div className="col-md-12 navTabItem">
                            <Link to='/admin/datos-maestros/proveedores'><i class="fa-solid fa-chevron-left mr-2"></i>Atras</Link>
                        </div>
                    </div>
                   
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <form className='formFormat1'>
                            <label>Rut Proveedor</label>
                            <input type="text" name='rut' required onChange={e => handleRutChange(e)}    value={rut}/> 

                            <label>Nombre Proveedor</label>
                            <input  type='text' value={proveedor} name='proveedor' onChange={handleChange}></input>
                            
                            <button className='formButton1' onClick={submitData}>Crear proveedor</button>
                        </form>
                        
                    </div>
                    </div>
            </div>
        )}
    </div>
  )
}

export default CreateProveedor