import "./App.css";
import React, { Fragment, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";

//Funciones
import { getUserById } from "./functions/api/auth";

/* Routes */
import BuqueRoute from "./components/routes/BuqueRoute";
import AdminRoute from "./components/routes/AdminRoute";
import SupervisorRoute from "./components/routes/SupervisorRoute";
import AnalistaRoute from "./components/routes/AnalistaRoute";

import Login from "./pages/Login";

/*  Buque Pages  */
import HomeBuque from "./pages/buque/HomeBuque";
import SolicitudCreate from "./pages/buque/SolicitudCreate";
import SolicitudesAprobadas from "./pages/buque/SolicitudesList";
import SolicitudAprobadaDetail from "./pages/buque/SolicitudDetail";

/* Supervisor Pages */
import HomeSupervisor from "./pages/supervisor/HomeSupervisor";
import SolicitudDetailSup from "./pages/supervisor/SolicitudDetailSup";
import SolicitudesListSup from "./pages/supervisor/SolicitudesListSup";

/* Admin Pages */
import Usuarios from "./pages/admin/user/Usuarios";
import CreateUser from "./pages/admin/user/CreateUser";
import UpdateUser from "./pages/admin/user/UpdateUser";
import Clasificaciones from "./pages/admin/clasificaciones/Clasificaciones";
import CreateClasificacion from "./pages/admin/clasificaciones/CreateClasificacion";
import UpdateClasificacion from "./pages/admin/clasificaciones/UpdateClasificacion";
import Proveedores from "./pages/admin/proveedores/Proveedores";
import CreateProveedor from "./pages/admin/proveedores/CreateProveedor";
import UpdateProveedor from "./pages/admin/proveedores/UpdateProveedor";
import AsignarNave from "./pages/admin/asignarNave/AsignarNave";
import Activos from "./pages/admin/activos/Activos";
import CreateActivo from "./pages/admin/activos/CreateActivo";
import CreateTipoActivo from "./pages/admin/activos/CreateTipoActivo";
import Sistemas from "./pages/admin/sistemas/Sistemas";
import CreateCategoriaSistemas from "./pages/admin/sistemas/CreateCategoriaSistemas";
import CreateSistema from "./pages/admin/sistemas/CreateSistema";
import AsignarSistema from "./pages/admin/sistemas/AsignarSistema";
import UpdateActivo from "./pages/admin/activos/UpdateActivo";
import CategoriaActivos from "./pages/admin/activos/CategoriaActivos";
import UpdateTipoActivo from "./pages/admin/activos/UpdateTipoActivo";
import UpdateSistema from "./pages/admin/sistemas/UpdateSistema";
import CategoriaEquipo from "./pages/admin/sistemas/CategoriaEquipo";
import UpdateCategoriaEquipo from "./pages/admin/sistemas/UpdateCategoriaEquipo";

import Email from "./pages/Email";

/* Analista Pages */
import Solicitudes from "./pages/analista/Solicitudes";
import SolicitudesDetail from "./pages/analista/SolicitudesDetail";
import SolicitudesPedimentos from "./pages/buque/SolicitudesPedimentos";
import SolicitudesPlanificadas from "./pages/buque/SolicitudesPlanificadas";
import SolicitudesPedimentosSup from "./pages/supervisor/SolicitudesPedimentosSup";
import SolicitudesPlanificadasSup from "./pages/supervisor/SolicitudesPlanificadasSup";
import SolicitudesPedimentosAdmin from "./pages/admin/solicitudes/SolicitudesPedimentosAdmin";
import SolicitudesPlanificadasAdmin from "./pages/admin/solicitudes/SolicitudesPlanificadasAdmin";
import SolicitudesListAdmin from "./pages/admin/solicitudes/SolicitudesListAdmin";
import SolicitudesDetailAdmin from "./pages/admin/solicitudes/SolicitudesDetailAdmin";
import SolicitudCreateAdmin from "./pages/admin/solicitudes/SolicitudCreateAdmin";
import EquiposBitacoraHome from "./pages/admin/activos/equiposBitacora/EquiposBitacoraHome";
import HomeRegistrosBitacora from "./pages/buque/registrosBitacora/HomeRegistrosBitacora";
import RegistrosHome from "./pages/buque/registrosBitacora/registrosDiarios/RegistrosHome";
import RecargasHome from "./pages/buque/registrosBitacora/recargas/RecargasHome";
import EntregasHome from "./pages/buque/registrosBitacora/entregasCombustible/EntregasHome";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const checkUser = async () => {
      const token = localStorage.getItem("token");

      if (token !== null) {
        getUserById(localStorage.getItem("token"))
          .then((r) => {
            dispatch({
              type: "LOGGED_IN_USER",
              payload: {
                id: r.data[0].id,
                email: r.data[0].email,
                nombre: r.data[0].nombre,
                password: r.data[0].password,
                tipo_solicitante: r.data[0].tipo_solicitante,
                rol: r.data[0].rol,
                idnave: r.data[0].idnave,
                token: localStorage.getItem("token"),
              },
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };

    checkUser();
  }, [dispatch]);

  return (
    <Fragment>
      <ToastContainer />
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/email" element={<Email />} />
        {/* Rutas usuario Buque */}
        <Route
          exact
          path="/buque/home"
          element={<BuqueRoute Component={HomeBuque} />}
        />
        <Route
          exact
          path="/buque/registros-bitacora"
          element={<BuqueRoute Component={HomeRegistrosBitacora} />}
        />
        <Route
          exact
          path="/buque/registros-bitacora/registros-diarios/:idNave"
          element={<BuqueRoute Component={RegistrosHome} />}
        />
        <Route
          exact
          path="/buque/registros-bitacora/recargas/:idNave"
          element={<BuqueRoute Component={RecargasHome} />}
        />
        <Route
          exact
          path="/buque/registros-bitacora/entregas/:idNave"
          element={<BuqueRoute Component={EntregasHome} />}
        />
        <Route
          exact
          path="/buque/mis-solicitudes"
          element={<BuqueRoute Component={SolicitudesAprobadas} />}
        />
        <Route
          exact
          path="/buque/solicitud-pedimentos"
          element={<BuqueRoute Component={SolicitudesPedimentos} />}
        />
        <Route
          exact
          path="/buque/solicitud-planificado"
          element={<BuqueRoute Component={SolicitudesPlanificadas} />}
        />
        <Route
          exact
          path="/buque/solicitud"
          element={<BuqueRoute Component={SolicitudCreate} />}
        />
        <Route
          exact
          path="/buque/mis-solicitudes/:id"
          element={<BuqueRoute Component={SolicitudAprobadaDetail} />}
        />
        {/* Rutas usuario Analista */}
        <Route
          exact
          path="/analista/solicitudes"
          element={<AnalistaRoute Component={Solicitudes} />}
        />
        <Route
          exact
          path="/analista/solicitudes/:id"
          element={<AnalistaRoute Component={SolicitudesDetail} />}
        />
        {/* Rutas usuario Supervisor */}
        <Route
          exact
          path="/supervisor/home"
          element={<SupervisorRoute Component={HomeSupervisor} />}
        />
        <Route
          exact
          path="/supervisor/mis-solicitudes"
          element={<SupervisorRoute Component={SolicitudesListSup} />}
        />
        <Route
          exact
          path="/supervisor/mis-solicitudes/:id"
          element={<SupervisorRoute Component={SolicitudDetailSup} />}
        />
        <Route
          exact
          path="/supervisor/solicitud-pedimentos"
          element={<SupervisorRoute Component={SolicitudesPedimentosSup} />}
        />
        <Route
          exact
          path="/supervisor/solicitud-planificado"
          element={<SupervisorRoute Component={SolicitudesPlanificadasSup} />}
        />
        {/* Rutas usuario Admin */}{" "}
        <Route
          exact
          path="/admin/crear-solicitud"
          element={<AdminRoute Component={SolicitudCreateAdmin} />}
        />
        <Route
          exact
          path="/admin/mis-solicitudes"
          element={<AdminRoute Component={SolicitudesListAdmin} />}
        />
        <Route
          exact
          path="/admin/solicitud-pedimentos"
          element={<AdminRoute Component={SolicitudesPedimentosAdmin} />}
        />
        <Route
          exact
          path="/admin/solicitud-planificado"
          element={<AdminRoute Component={SolicitudesPlanificadasAdmin} />}
        />
        <Route
          exact
          path="/admin/mis-solicitudes/:id"
          element={<AdminRoute Component={SolicitudesDetailAdmin} />}
        />
        <Route
          exact
          path="/admin/usuarios"
          element={<AdminRoute Component={Usuarios} />}
        />
        <Route
          exact
          path="/admin/usuarios/crear-usuario"
          element={<AdminRoute Component={CreateUser} />}
        />
        <Route
          exact
          path="/admin/usuarios/actualizar-usuario/:id"
          element={<AdminRoute Component={UpdateUser} />}
        />
        <Route
          exact
          path="/admin/datos-maestros/clasificaciones"
          element={<AdminRoute Component={Clasificaciones} />}
        />
        <Route
          exact
          path="/admin/datos-maestros/clasificaciones/crear-clasificacion"
          element={<AdminRoute Component={CreateClasificacion} />}
        />
        <Route
          exact
          path="/admin/datos-maestros/clasificaciones/actualizar-clasificacion/:id"
          element={<AdminRoute Component={UpdateClasificacion} />}
        />{" "}
        <Route
          exact
          path="/admin/datos-maestros/equipos-bitacora/:id"
          element={<AdminRoute Component={EquiposBitacoraHome} />}
        />
        <Route
          exact
          path="/admin/datos-maestros/proveedores"
          element={<AdminRoute Component={Proveedores} />}
        />
        <Route
          exact
          path="/admin/datos-maestros/proveedores/crear-proveedor"
          element={<AdminRoute Component={CreateProveedor} />}
        />
        <Route
          exact
          path="/admin/datos-maestros/proveedores/actualizar-proveedor/:id"
          element={<AdminRoute Component={UpdateProveedor} />}
        />
        <Route
          exact
          path="/admin/usuarios/flujo-solicitud"
          element={<AdminRoute Component={AsignarNave} />}
        />
        <Route
          exact
          path="/admin/datos-maestros/activos"
          element={<AdminRoute Component={Activos} />}
        />
        <Route
          exact
          path="/admin/datos-maestros/activos/:id"
          element={<AdminRoute Component={UpdateActivo} />}
        />
        <Route
          exact
          path="/admin/datos-maestros/create-activo"
          element={<AdminRoute Component={CreateActivo} />}
        />
        <Route
          exact
          path="/admin/datos-maestros/categoria-activos"
          element={<AdminRoute Component={CategoriaActivos} />}
        />
        <Route
          exact
          path="/admin/datos-maestros/create-tipo-activo"
          element={<AdminRoute Component={CreateTipoActivo} />}
        />
        <Route
          exact
          path="/admin/datos-maestros/update-tipo-activo/:id"
          element={<AdminRoute Component={UpdateTipoActivo} />}
        />
        <Route
          exact
          path="/admin/sistemas"
          element={<AdminRoute Component={Sistemas} />}
        />
        <Route
          exact
          path="/admin/sistemas/create-sistema"
          element={<AdminRoute Component={CreateSistema} />}
        />
        <Route
          exact
          path="/admin/sistemas/update-sistema/:id"
          element={<AdminRoute Component={UpdateSistema} />}
        />
        <Route
          exact
          path="/admin/datos-maestros/asignar-sistema/:id"
          element={<AdminRoute Component={AsignarSistema} />}
        />
        <Route
          exact
          path="/admin/sistemas/tipo-sistema"
          element={<AdminRoute Component={CategoriaEquipo} />}
        />
        <Route
          exact
          path="/admin/sistemas/create-tipo-sistema"
          element={<AdminRoute Component={CreateCategoriaSistemas} />}
        />
        <Route
          exact
          path="/admin/sistemas/update-tipo-sistema/:id"
          element={<AdminRoute Component={UpdateCategoriaEquipo} />}
        />
      </Routes>
    </Fragment>
  );
}

export default App;
