import React, { useState, useEffect } from "react";
import loginImg from "../img/ocean.jpg";
import Logo from "../img/logo.png";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogin, getUserById } from "../functions/api/auth";

const Login = () => {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  let navigate = useNavigate();
  let dispatch = useDispatch();

  const { user } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    if (user && user.token) navigate("/");
  }, [dispatch]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    userLogin({ email: loginEmail, password: loginPassword })
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        getUserById(res.data.token).then((r) => {
          dispatch({
            type: "LOGGED_IN_USER",
            payload: {
              id: r.data[0].id,
              email: r.data[0].email,
              nombre: r.data[0].nombre,
              password: r.data[0].password,
              rol: r.data[0].rol,
              tipo_solicitante: r.data[0].tipo_solicitante,
              idnave: r.data[0].idnave,
              token: res.data.token,
            },
          });

          toast.success(`Inicio de sesión correcto`);

          if (r.data[0].rol === "Buque") {
            navigate(`/buque/mis-solicitudes`);
          } else if (r.data[0].rol === "Supervisor") {
            navigate(`/supervisor/mis-solicitudes`);
          } else if (r.data[0].rol === "Admin") {
            navigate(`/admin/usuarios`);
          } else if (r.data[0].rol === "Analista") {
            navigate(`/analista/solicitudes`);
          }
        });
      })
      .catch((err) => {
        toast.error("No se ha podido iniciar sesión");
        console.log(err);
      });
  };

  return (
    <div className="LoginHome">
      <div className="containerHome">
        <div className="login-left">
          <div className="login-header">
            <img src={Logo} alt="" />
            <h1>Bienvenido a sistema solicitudes de trabajos</h1>
            <p>Por favor inicie sesión para usar la plataforma</p>
          </div>
          <form className="login-form">
            <div className="form-item mb-4">
              <label>Ingrese su email</label>
              <input
                type="text"
                id="email"
                value={loginEmail}
                onChange={(e) => setLoginEmail(e.target.value)}
              />
            </div>

            <div className="form-item mb-4">
              <label>Ingrese su contraseña</label>
              <input
                type="password"
                id="email"
                value={loginPassword}
                onChange={(e) => setLoginPassword(e.target.value)}
              />
            </div>

            <button className="homeLogin" onClick={handleSubmit}>
              Ingresar al Sistema
            </button>
          </form>
        </div>
        <div className="login-right">
          <img src={loginImg} />
        </div>
      </div>
    </div>
  );
};

export default Login;
