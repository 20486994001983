import React from "react";

const EquipoBitacoraCard = ({ data, handleModalOpen }) => {
  return (
    <div key={data.id} className="col-md-3 cardContent text-capitalize">
      <h6 className="m-auto" style={{}}>
        {data.tipo_equipo} - ID: {data.id}
      </h6>
      {data.tipo_equipo === "estanque" && (
        <>
          <span className="m-auto">{data.tipo_estanque}</span>
          <span className="m-auto">{data.stock} Lts</span>
          <span className="m-auto">{data.m3} m3</span>
          <div
            className="progress-bar"
            role="progressbar"
            aria-valuenow={(data.stock / data.m3) * 100}
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
          <label className="fuelContent">
            <i className="fa-solid fa-gas-pump" />{" "}
            {((data.stock / data.m3) * 100).toFixed(2)}%
          </label>
          <div class="progress">
            <div
              class="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              style={{
                width: `${(data.stock / data.m3) * 100}%`,
              }}
              aria-valuenow={data.stock}
              aria-valuemin="0"
              aria-valuemax={data.m3}
            ></div>
          </div>
        </>
      )}
      <button
        className="buttonContentSecondary"
        onClick={() => handleModalOpen(data)}
      >
        Agregar Registro
      </button>
    </div>
  );
};

export default EquipoBitacoraCard;
