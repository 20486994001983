import React,{ useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';

import Loading from '../../../components/otros/Loading'
import DataTable from 'react-data-table-component';
import AdminSidebar from '../../../components/nav/AdminSidebar'

import { getClasificaciones } from '../../../functions/api/clasificaciones'

const Clasificaciones = () => {
    
    const [loading,setLoading] = useState(false)
    const [clasificaciones,setClasificaciones] = useState([])

    let {user} = useSelector((state) =>({...state}))
  
    useEffect(() => {
        
        setLoading(true)
        loadClasificaciones()
        setLoading(false)

    }, [])


    
    const loadClasificaciones = () => getClasificaciones(user.token).then( res => {
        setClasificaciones(res.data)
    }
    ).catch( err => 
        console.log(err)
    )


    const columns = [
        {
            name: 'Clasificación',
            selector: row => row.clasificacion,
        },
        {
            name: 'Activo',
            selector: row => row.activo,
        },
        {
            name: 'Editar',
            selector: row => row.editar,
        }
    ]


    const data = clasificaciones && clasificaciones.map( c => {
        return {
            clasificacion: c.clasificacion,
            activo: c.activo === true ? <button className='btn btn-primary'>Activo</button>:<button className='btn btn-secondary'>Inactivo</button>,
            editar: <Link to={`/admin/datos-maestros/clasificaciones/actualizar-clasificacion/${c.id}`} className='btn btn-success'>Editar</Link >
        }
    })


  return (
    <div> 
        <AdminSidebar />
        {loading ? (
          
        <div className="home">
          <Loading />
        </div>
        ):(
            <div className="home">
                 <div className='navCentro'>
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><i class="fa-solid fa-screwdriver-wrench "></i> TIPOS DE TRABAJO</li>
                    </ol>
                    <div className="row navTab">
                        
                        <div className="col-md-12 navTabItem">
                            <Link to='/admin/datos-maestros/clasificaciones/crear-clasificacion'>Crear clasificacion<i class="fa-solid fa-square-plus ml-2"></i></Link>
                        </div>
                    </div>
                   
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        />
                       
                    </div>
                    </div>
            </div>
        )}
    </div>
  )
}

export default Clasificaciones