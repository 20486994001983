import React,{ useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux';
import AdminSidebar from '../../../components/nav/AdminSidebar'
import { toast } from 'react-toastify'
import Loading from '../../../components/otros/Loading'


import { updateTipoActivo, getTipoActivosById } from '../../../functions/api/tipoActivos'


const initialState = {
    tipo_activo:'',
    activo:true
}




  
const UpdateTipoActivo = () => {
    const [loading,setLoading] = useState(false)
    const [tactivo,setTActivo] = useState([])

    let {id} = useParams()

    let {user} = useSelector((state) =>({...state}))
    let navigate = useNavigate ()



    useEffect(() => {
        setLoading(true)

        loadTActivos()
        setLoading(false)
    }, [])


    const loadTActivos = () => getTipoActivosById(user.token,{id:id}).then( r => setTActivo(r.data[0]))
    
    const handleChange = (e) =>{
        setTActivo({...tactivo, [e.target.name]:e.target.value})
    }

    const { tipo_activo } = tactivo

    const submitActivo = (e) =>{
        e.preventDefault()
        
        updateTipoActivo(user.token,tactivo )
        .then( r => {
            toast.success('Tipo activo actualizado exitosamente')
            setTActivo([])
            navigate('/admin/datos-maestros/categoria-activos')
        }
        )
        .catch( err =>
            
                toast.error(err.response.data)
            )
    }



  return (
    <div> 
        <AdminSidebar />
        {loading ? (
          
        <div className="home">
          <Loading />
        </div>
        ):(
            <div className="home">
                <div className='navCentro'>
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item">
                        <i class="fa-solid fa-ship"></i> TIPO ACTIVOS</li>
                        <li className="breadcrumb-item"> Actualizar tipo activos</li>
                    </ol>
                    <div className="row navTab">
                        <div className="col-md-12 navTabItem">
                            <Link to='/admin/datos-maestros/categoria-activos'><i class="fa-solid fa-chevron-left mr-2"></i>Atras</Link>
                        </div>
                    </div>
                </div>

                <div className='row'>
                <div className="col-md-12">
                        <form className='formFormat1'>
                            <label>Nombre del Tipo de Activo</label>
                            <input name='tipo_activo' value={tipo_activo} onChange={handleChange}  />
                            

                            <button className='formButton1' onClick={submitActivo} >Actualizar</button>
                        </form>
                        
                </div>
            </div>
        </div>
        )}
    </div>
  )
}

export default UpdateTipoActivo