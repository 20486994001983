import axios from 'axios'


export const getActivos = async(token) =>{
    return await axios.get(`${process.env.REACT_APP_API}/get-activos`,{
        headers:{
            "x-auth-token":token
        }
    })
}

export const getActivosCol = async(token) =>{
    return await axios.get(`${process.env.REACT_APP_API}/get-activos-col`,{
        headers:{
            "x-auth-token":token
        }
    })
}

export const getActivoById = async(token,id) =>{
    return await axios.post(`${process.env.REACT_APP_API}/get-activo-by-id`,id,{
        headers:{
            "x-auth-token":token
        }
    })
}

export const getActivosByUser = async(token,id) =>{
    return await axios.post(`${process.env.REACT_APP_API}/get-activos-by-user`,id,{
        headers:{
            "x-auth-token":token
        }
    })
}

export const createActivo = async(token,body) =>{
    return await axios.post(`${process.env.REACT_APP_API}/create-activo`,body,{
        headers:{
            "x-auth-token":token
        }
    })
}

export const updateActivo = async(token,body) =>{
    return await axios.put(`${process.env.REACT_APP_API}/update-activo`,body,{
        headers:{
            "x-auth-token":token
        }
    })
}

