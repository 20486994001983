import React,{ useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux';
import AdminSidebar from '../../../components/nav/AdminSidebar'
import { toast } from 'react-toastify'
import Loading from '../../../components/otros/Loading'


import { createCategoriaItems, getCategoriasItemsById } from '../../../functions/api/sistemasCategorias'


const initialState = {
    tipo_activo:'',
    activo:true
}




  
const UpdateCategoriaEquipo = () => {
    const [loading,setLoading] = useState(false)
    const [activo,setActivo] = useState(initialState)


    let { id } = useParams()
    let {user} = useSelector((state) =>({...state}))
    let navigate = useNavigate ()



    useEffect(() => {
        setLoading(true)

        const delay = 500; // 3 seconds in milliseconds
    
        const timer = setTimeout(() => {
          // Code to execute after the delay
          setLoading(false)
          loadCategoria()
          console.log('3 seconds have passed');
        }, delay);
    
        return () => {
          clearTimeout(timer);
        };
    }, [])

    

    const loadCategoria = () => getCategoriasItemsById(user.token, {id:id}).then( res => setActivo(res.data[0]) )


    const handleChange = (e) =>{
        setActivo({...activo, [e.target.name]:e.target.value})
    }

    const { tipo_activo } = activo

    const submitActivo = (e) =>{
        e.preventDefault()
        
        createCategoriaItems(user.token,activo )
        .then( r => {
            toast.success('Articulo creado exitosamente')
            setActivo([])
            navigate('/admin/sistemas')
        }
        )
        .catch( err =>
            
                toast.error(err.response.data)
            )
    }



  return (
    <div> 
        <AdminSidebar />
        {loading ? (
          
        <div className="home">
          <Loading />
        </div>
        ):(
            <div className="home">
                <div className='navCentro'>
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item">
                            <i class="fa-solid fa-database icon"></i> SISTEMAS</li>
                        <li className="breadcrumb-item">Seleccionar tipo de datos</li>
                    </ol>
                    <div className="row navTab">
                        <div className="col-md-12 navTabItem">
                            <Link to='/admin/sistemas/tipo-sistema'><i class="fa-solid fa-chevron-left mr-2"></i>Atras</Link>
                        </div>
                    </div>
                </div>

                <div className='row'>
                <div className="col-md-12">
                        <form className='formFormat1'>
                            <label>Nombre del Tipo de Articulo</label>
                            <input name='tipo_activo' value={tipo_activo} onChange={handleChange}  />
                            

                            <button className='formButton1' onClick={submitActivo} >Guardar</button>
                        </form>
                        
                    </div>
                </div>
            </div>
        )}
    </div>
  )
}

export default UpdateCategoriaEquipo