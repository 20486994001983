import React,{ useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux';
import AdminSidebar from '../../../components/nav/AdminSidebar'
import { toast } from 'react-toastify'
import Loading from '../../../components/otros/Loading'


import { crateItemsActivos, getItemsActivosById, updateItemsActivos } from '../../../functions/api/sistemas'
import { getCategoriaItems } from '../../../functions/api/sistemasCategorias'


const initialState = {
    nombre_item:'',
    descripcion:'',
    codigo:'',
    id_categoria:'',
    activo:true,
    marca:'',
    modelo:'',
    serie:'',
    item_year:'',
    cap_pot:'',
    rpm:'',
    lubricante:''
}


const UpdateSistema = () => {
    const [loading,setLoading] = useState(false)
    const [activo,setActivo] = useState(initialState)
    const [tipoActivos,setTipoActivos] = useState([])


    let { id } = useParams()
    let {user} = useSelector((state) =>({...state}))
    let navigate = useNavigate ()



    useEffect(() => {
        setLoading(true)

        const delay = 500; // 3 seconds in milliseconds
    
        const timer = setTimeout(() => {
          // Code to execute after the delay
          setLoading(false)
          loadTActivos()
          loadSistema()
          console.log('3 seconds have passed');
        }, delay);
    
        return () => {
          clearTimeout(timer);
        };
    }, [])

    const loadTActivos = () => getCategoriaItems(user.token).then( res => {
        setTipoActivos(res.data)
    })

    const loadSistema = () => getItemsActivosById(user.token,{id:id}).then( res => {
        setActivo(res.data[0])
    })
    
    const handleChange = (e) =>{
        setActivo({...activo, [e.target.name]:e.target.value})
    }


    const {nombre_item, descripcion, codigo, id_categoria, marca, modelo, serie, item_year, cap_pot, rpm, lubricante } = activo

    const submitActivo = (e) =>{
        e.preventDefault()
        
        updateItemsActivos( user.token,activo)
        .then( r => {
            toast.success('Articulo creado exitosamente')
            setActivo([])
            navigate('/admin/sistemas')
        }
        )
        .catch( err =>
            toast.error(err.response.data)
        )
    }



  return (
    <div> 
        <AdminSidebar />
        {loading ? (
          
        <div className="home">
          <Loading />
        </div>
        ):(
            <div className="home">
                <div className='navCentro'>
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item">
                        <i class="fa-solid fa-gears icon"></i> SISTEMAS Y EQUIPOS</li>
                        <li className="breadcrumb-item">Crear equipo</li>
                    </ol>
                    <div className="row navTab">
                        <div className="col-md-12 navTabItem">
                            <Link to='/admin/sistemas'><i class="fa-solid fa-chevron-left mr-2"></i>Atras</Link>
                        </div>
                    </div>
                </div>

                <div className='row'>
                <div className="col-md-12">
                        <form className='formFormat1'>
                            <div className="row">
                                <div className="col-md-4">   
                                    <label>Nombre del Articulo</label><br/>
                                    <input name='nombre_item' style={{width:'100%'}} value={nombre_item} onChange={handleChange}  />
                                </div>
                                
                                <div className="col-md-4">
                                    <label>Codigo</label><br/>
                                    <input style={{width:'100%'}} name='codigo' value={codigo} onChange={handleChange}  />
                                </div>
                                <div className="col-md-4">
                                    <label>Tipo de Articulo 
                                    </label>
                                    <br/>
                                    <select style={{width:'100%'}} id="selectInput" value={id_categoria} name='id_categoria' onChange={handleChange}>
                                        
                                    <option  value="">Seleccionar tipo de Activo</option>
                                    {tipoActivos && tipoActivos.map( c => (
                                        <option value={c.id}>{c.tipo_activo}</option>
                                    ))}
                                    </select>   
                                </div>
                                <div className="col-md-12 mb-3 mt-3">
                                    <label>Descripción</label><br/>
                                    <textarea className='inputTextArea' style={{width:'100%',height:'80px'}} name='descripcion' value={descripcion} onChange={handleChange}  />
                                </div>
                                <div className="col-md-4">
                                    <label>Marca</label><br/>
                                    <input style={{width:'100%'}} name='marca' value={marca} onChange={handleChange}  />
                                </div>

                                <div className="col-md-4">
                                    <label>Modelo</label><br/>
                                    <input style={{width:'100%'}} name='modelo' value={modelo} onChange={handleChange}  />
                                </div>
                                <div className="col-md-4">
                                    <label>Serie</label><br/>
                                    <input style={{width:'100%'}} name='serie' value={serie} onChange={handleChange}  />
                                </div>

                                <div className="col-md-3">
                                    <label>Año</label><br/>
                                    <input style={{width:'100%'}} name='item_year' type='number' value={item_year} onChange={handleChange}  />
                                </div>

                                <div className="col-md-3">
                                    <label>Capacidad / Potencia</label><br/>
                                    <input style={{width:'100%'}} name='cap_pot' value={cap_pot} onChange={handleChange}  />
                                </div>

                                <div className="col-md-3">
                                    <label>RPM</label><br/>
                                    <input style={{width:'100%'}} name='rpm' value={rpm} onChange={handleChange}  />
                                </div>

                                <div className="col-md-3">
                                    <label>Lubricante</label><br/>
                                    <input style={{width:'100%'}} name='lubricante' value={lubricante} onChange={handleChange}  />
                                </div>

                                

                            </div>
                            
                            
                            <button className='formButton1' onClick={submitActivo} >Guardar</button>
                        </form>
                        
                    </div>
                </div>
            </div>
        )}
    </div>
  )
}

export default UpdateSistema