import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { Version } from "../otros/Versiones";

import Logo from "../../img/logo.png";

const SupervisorSidebar = () => {
  let navigate = useNavigate();

  let { user } = useSelector((state) => ({ ...state }));

  const dispatch = useDispatch();

  const logout = () => {
    dispatch({
      type: "LOGOUT",
      payload: null,
    });
    navigate("/");
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    const resizeListener = () => {
      handleWindowResize();
    };

    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 800) {
      const body = document.querySelector("body"),
        sidebar = body.querySelector(".sidebar"),
        toggle = body.querySelector(".toggle"),
        modeSwitch = body.querySelector(".toggle-switch"),
        modeText = body.querySelector(".mode-text");

      modeSwitch.addEventListener("click", () => {
        body.classList.toggle("dark");
      });

      toggle.addEventListener("click", () => {
        sidebar.classList.toggle("cls");
      });
    }
  }, [windowWidth]);

  return windowWidth && windowWidth > 800 ? (
    <nav className="sidebar">
      <header>
        <div className="image-text">
          <span className="image">
            <img src={Logo} />
          </span>
          <div className="text header-text ml-2">
            <span className="name">{user.nombre}</span>
            <span className="subtitle">Napsom V {Version}</span>
          </div>
        </div>

        <i class="fa-solid fa-angle-right toggle"></i>
      </header>

      <div className="menu-bar ">
        <div className="menu">
          <ul className="menu-links">
            <li className="nav-link">
              <Link to={`/supervisor/mis-solicitudes`}>
                <i class="fa-solid fa-list icon"></i>
                <span className="text nav-text" style={{ fontSize: "12px" }}>
                  Solicitudes de trabajo
                </span>
              </Link>
            </li>
            <li className="nav-link">
              <Link to={`/supervisor/solicitud-planificado`}>
                <i class="fa-solid fa-list icon"></i>
                <span className="text nav-text" style={{ fontSize: "12px" }}>
                  Mantenimiento planificado
                </span>
              </Link>
            </li>
            <li className="nav-link">
              <Link to={`/supervisor/solicitud-pedimentos`}>
                <i class="fa-solid fa-list icon"></i>
                <span className="text nav-text" style={{ fontSize: "12px" }}>
                  Pedimentos
                </span>
              </Link>
            </li>
            {/* 
                        <li className="nav-link">
                            <Link to={`/supervisor/por-gestionar`}>
                            <i class="fa-regular fa-envelope icon"></i>
                              <span className="text nav-text">Recibir Solicitudes</span>
                            </Link>
                        </li>

                        
                        <li className="nav-link">
                            <Link to={`/supervisor/programar`}>
                            <i class="fa-solid fa-calendar icon"></i>
                              <span className="text nav-text">Programar </span>
                            </Link>
                        </li> */}

            {/* {user && user.tipo_solicitante === "Ito" && (
                             <li className="nav-link">
                             <Link to={`/supervisor/garantia`}>
                             <i class="fa-solid fa-shield icon"></i>
                                 <span className="text nav-text">Tramitar garantia</span>
                             </Link>
                            </li>
                        )} */}
          </ul>
        </div>
        <div className="bottom-content">
          <li className="">
            <Link onClick={logout}>
              <i class="fa-solid fa-right-from-bracket icon"></i>
              <span className="text nav-text">Desconectarse</span>
            </Link>
          </li>
          <li className="mode">
            <div className="moon-sun">
              <i class="fa-solid fa-moon icon moon"></i>
              <i class="fa-solid fa-sun icon sun"></i>
            </div>
            <span className="mode-text text">Dark Mode</span>
            <div className="toggle-switch">
              <span className="switch"></span>
            </div>
          </li>
        </div>
      </div>
    </nav>
  ) : (
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      {" "}
      <img src={Logo} style={{ width: "60px" }} />
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarTogglerDemo01"
        aria-controls="navbarTogglerDemo01"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
        <a class="navbar-brand" href="#"></a>
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
          <li className="nav-link">
            <Link to={`/supervisor/mis-solicitudes`}>
              <i class="fa-solid fa-list icon"></i>
              <span className="text nav-text" style={{ fontSize: "12px" }}>
                Solicitudes de trabajo
              </span>
            </Link>
          </li>
          <li className="nav-link">
            <Link to={`/supervisor/solicitud-planificado`}>
              <i class="fa-solid fa-list icon"></i>
              <span className="text nav-text" style={{ fontSize: "12px" }}>
                Mantenimiento planificado
              </span>
            </Link>
          </li>
          <li className="nav-link">
            <Link to={`/supervisor/solicitud-pedimentos`}>
              <i class="fa-solid fa-list icon"></i>
              <span className="text nav-text" style={{ fontSize: "12px" }}>
                Pedimentos
              </span>
            </Link>
          </li>

          {/* {user && user.tipo_solicitante === "Ito" && (
                             <li className="nav-link">
                             <Link to={`/supervisor/garantia`}>
                             <i class="fa-solid fa-shield icon"></i>
                                 <span className="text nav-text">Tramitar garantia</span>
                             </Link>
                            </li>
                        )} */}

          <li className="nav-link">
            <Link onClick={logout}>
              <i class="fa-solid fa-right-from-bracket icon"></i>
              <span className="text nav-text" style={{ fontSize: "12px" }}>
                Desconectarse
              </span>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default SupervisorSidebar;
