import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";

import AdminSidebar from "../../../components/nav/AdminSidebar";
import Loading from "../../../components/otros/Loading";
import DataTable from "react-data-table-component";

import { toast } from "react-toastify";
import { getUsers } from "../../../functions/api/auth";
import { getActivoById, getActivosCol } from "../../../functions/api/activos";
import { getTipoActivos } from "../../../functions/api/tipoActivos";
import {
  getItemsActivos,
  getSistemasByNave,
  crearAsignacionItems,
  createAsignacionItems2,
  getItemsActivosById2,
  getItemsAsignaciones,
} from "../../../functions/api/sistemas";
import { getCategoriaItems } from "../../../functions/api/sistemasCategorias";

const AsignarSistema = () => {
  const [filtro1, setFiltro1] = useState("");
  const [filtro2, setFiltro2] = useState("");
  const [loading, setLoading] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [toogleCleared, setToogleCleared] = useState(false);
  const [activo, setActivo] = useState([]);
  const [activos, setActivos] = useState([]);
  const [tipoActivo, setTipoActivo] = useState([]);
  const [catSistemas, setCatSistemas] = useState([]);
  const [sistemas, setSistemas] = useState([]);
  const [sistemasNaves, setSistemasNaves] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const [counter, setCounter] = useState(false);
  const sist = useRef([]);
  const sist2 = useRef([]);

  let { id } = useParams();

  let { user } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    setLoading(true);
    loadUsers();
    loadActivo();
    loadTActivos();
    loadSistemas();
    loadSistemasNave();
    loadSistemasCategoria();
    loadActivos();
    setLoading(false);
    setSelectedRows("");
  }, [counter]);

  const handleFiltro1 = (e) => {
    setFiltro1(e.target.value);
  };

  const handleFiltro2 = (e) => {
    setFiltro2(e.target.value);
  };

  const loadUsers = () =>
    getUsers(user.token)
      .then((res) => {
        setUsuarios(res.data);
      })
      .catch((err) => console.log(err));

  const loadActivo = () =>
    getActivoById(user.token, { id: id }).then((res) => {
      setActivo(res.data);
    });

  const loadActivos = () =>
    getActivosCol(user.token).then((res) => {
      setActivos(res.data);
    });

  const loadTActivos = () =>
    getTipoActivos(user.token).then((res) => {
      setTipoActivo(res.data);
    });

  const loadSistemas = () =>
    getItemsActivos(user.token, { id: id }).then((res) => {
      setSistemas(res.data);
    });

  const loadSistemasCategoria = () =>
    getCategoriaItems(user.token).then((res) => {
      setCatSistemas(res.data);
    });

  const loadSistemasNave = () =>
    getItemsActivosById2(user.token, { id: id }).then((res) => {
      setSistemasNaves(res.data);
    });

  const act = activo && activo[0];

  const columns = [
    // {
    //   name: "COD",
    //   selector: (row) => row.codigo,
    // },
    {
      name: "Nombre",
      selector: (row) => row.nombre,
    },
  ];

  const columns1 = [
    // {
    //   name: "COD",
    //   selector: (row) => row.codigo,
    // },
    {
      name: "Nombre",
      selector: (row) => row.nombre,
    },
    // {
    //   name: "Activo",
    //   selector: (row) => row.activo,
    // },
  ];

  const data =
    sistemasNaves &&
    sistemasNaves
      .filter((d) => d.nombre_item.toLowerCase().includes(filtro1))
      .map((c) => {
        return {
          id: c.id,
          nombre: c.nombre_item, // Assuming 'nombre' should be used here based on the filter
        };
      });

  const data2 =
    sistemasNaves &&
    sistemasNaves.map((c) => {
      return c.id;
    });

  const data1 =
    sistemas &&
    data &&
    catSistemas &&
    sistemas
      .filter((d) => !data2.includes(d.id))
      .filter((d) => d.nombre_item.toLowerCase().includes(filtro2))
      .map((c) => {
        return {
          id: c.id,
          nombre: c.nombre_item,
        };
      });

  const handleChange = ({ selectedRows }) => {
    sist.current = [];

    const keys = selectedRows.map((d) => {
      return d.id;
    });
    const siste = sistemas && sistemas.filter((item) => keys.includes(item.id));

    // Find the dictionary with the targetId and set its 'codigo' property to 'de'
    for (let i = 0; i < siste.length; i++) {
      siste[i].id_activo = id;
    }

    sist.current.push(siste);
    console.log(sist.current[0]);
    console.log("Selected Rows: ", selectedRows);
  };

  const handleChange2 = ({ selectedRows }) => {
    sist2.current = [];

    const keys = selectedRows.map((d) => {
      return d.id;
    });
    const siste = sistemas && sistemas.filter((item) => keys.includes(item.id));

    // Find the dictionary with the targetId and set its 'codigo' property to 'de'
    for (let i = 0; i < siste.length; i++) {
      siste[i].id_activo = id;
    }

    sist2.current.push(siste);
    console.log(sist2.current[0]);
    console.log("Selected Rows: ", selectedRows);
  };

  const handleSubmit1 = () => {
    console.log(sist.current[0]);

    const keys = sist.current[0].map((d) => {
      return d.id;
    });

    const actualKeys =
      sistemasNaves &&
      sistemasNaves.map((c) => {
        return c.id;
      });

    const mergeKeys = [...keys, ...actualKeys];

    createAsignacionItems2(user.token, { id: id, keys: mergeKeys })
      .then((res) => {
        console.log(res);
        toast.success("Equipo asignado correctamente");
        setCounter((prevIsActive) => !prevIsActive);
        setToggleClearRows(!toggledClearRows);
        sist.current = [];
        sist2.current = [];
      })
      .catch((err) => console.log(err));
  };

  const handleSubmit = () => {
    console.log(sist2.current[0]);

    const keys = sist2.current[0].map((d) => {
      return d.id;
    });

    console.log(keys);

    const actualKeys =
      sistemasNaves &&
      sistemasNaves.map((c) => {
        return c.id;
      });

    const filteredArray = actualKeys.filter(
      (element) => !keys.includes(element)
    );

    createAsignacionItems2(user.token, { id: id, keys: filteredArray })
      .then((res) => {
        console.log(res);
        toast.success("Equipo removido correctamente");
        setToggleClearRows(!toggledClearRows);
        setCounter((prevIsActive) => !prevIsActive);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <AdminSidebar />
      {loading ? (
        <div className="home">
          <Loading />
        </div>
      ) : (
        <div className="home">
          <div className="navCentro">
            <ol class="breadcrumb">
              <li className="breadcrumb-item">
                <i class="fa-solid fa-database icon"></i> SISTEMAS
              </li>
              <li className="breadcrumb-item">Asignar sistema</li>
            </ol>
            <div className="row navTab">
              <div className="col-md-12 navTabItem">
                <Link to="/admin/datos-maestros/activos">
                  <i class="fa-solid fa-chevron-left mr-2"></i>Atras
                </Link>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 header">
              <div className="row">
                <div className="col-md-3">
                  <div className="header-img">
                    <img src={act && act.img_activo} />
                  </div>

                  <div className="header-name">
                    <h4>{act && act.nombre_activo}</h4>
                    <p>
                      {tipoActivo &&
                        act &&
                        tipoActivo
                          .filter((d) => d.id === act.id_tipo_activo)
                          .map((c) => c.tipo_activo)}
                    </p>
                  </div>
                </div>
                {/* <div className="col-md-9">{JSON.stringify(data)}</div> */}
                <div className="col-md-4">
                  <input
                    className="filtroActivosSistemas"
                    value={filtro1}
                    onChange={handleFiltro1}
                    placeholder="Buscar sistema..."
                  />
                  <DataTable
                    title="Sistemas y equipos del activo"
                    columns={columns}
                    data={data}
                    pagination
                    selectableRows
                    onSelectedRowsChange={handleChange2}
                    clearSelectedRows={toggledClearRows}
                  />
                  <button
                    onClick={handleSubmit}
                    className="guardar-asignacion-naves"
                  >
                    Remover sistemas
                  </button>
                </div>
                <div className="col-md-5">
                  <input
                    className="filtroActivosSistemas"
                    value={filtro2}
                    onChange={handleFiltro2}
                    placeholder="Buscar sistema..."
                  />
                  <DataTable
                    title="Agregar sistemas y equipos"
                    columns={columns1}
                    data={data1}
                    pagination
                    selectableRows
                    clearSelectedRows={toggledClearRows}
                    onSelectedRowsChange={handleChange}
                  />
                  <button
                    onClick={handleSubmit1}
                    className="guardar-asignacion-naves"
                  >
                    Asignar sistema
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AsignarSistema;
