import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { getTrabajos } from "../../../functions/api/trabajos";
import { getClasificaciones } from "../../../functions/api/clasificaciones";
import { getAsignacionesByUserId } from "../../../functions/api/asignacionNaves";
import DataTable from "react-data-table-component";
import { getUsers } from "../../../functions/api/auth";
import { getActivosCol } from "../../../functions/api/activos";
import AdminSidebar from "../../../components/nav/AdminSidebar";

const initialStateFiltros = {
  estado: "",
  id_sol: "",
  nombre_activo: "",
};

const SolicitudesListAdmin = () => {
  const [solicitudes, setSolicitudes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clasificaciones, setClasificaciones] = useState([]);
  const [activos, setActivos] = useState([]);
  const [asignaciones, setAsignaciones] = useState([]);
  const [users, setUsers] = useState([]);
  const [filtros, setFiltros] = useState(initialStateFiltros);

  useEffect(() => {
    setLoading(true);

    const delay = 500; // 3 seconds in milliseconds

    const timer = setTimeout(() => {
      // Code to execute after the delay
      setLoading(false);

      loadClasificaciones();
      loadActivos();
      loadAsignaciones();
      loadTrabajos();
      loadUsers();

      console.log("3 seconds have passed");
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const loadClasificaciones = () =>
    getClasificaciones(user.token).then((res) => {
      setClasificaciones(res.data);
    });
  const loadActivos = () =>
    getActivosCol(user.token).then((res) => {
      setActivos(res.data);
    });
  const loadTrabajos = () =>
    getTrabajos(user.token).then((res) => {
      setSolicitudes(res.data);
    });
  const loadUsers = () =>
    getUsers(user.token).then((res) => {
      setUsers(res.data);
    });
  const loadAsignaciones = () =>
    getAsignacionesByUserId(user.token, { id: user.id }).then((res) => {
      setAsignaciones(res.data);
    });

  let navigate = useNavigate();
  let { user } = useSelector((state) => ({ ...state }));

  const { estado, id_sol, nombre_activo } = filtros;

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toISOString().split("T")[0];
    return formattedDate;
  }

  const estadoSolicitud = (item) =>
    JSON.parse(item.estado_general)[JSON.parse(item.estado_general).length - 1]
      .estado;

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
      width: "80px",
    },
    {
      name: "Fecha Solicitud",
      selector: (row) => row.fecha,
      sortable: true,
      width: "120px",
    },
    {
      name: "Severidad",
      selector: (row) => row.severidad,
      sortable: true,
      width: "100px",
    },
    {
      name: "Nivel",
      selector: (row) => row.nivel,
      sortable: true,
      width: "100px",
    },
    {
      name: "Equipo",
      selector: (row) => row.equipo,
      sortable: true,
    },

    {
      name: "Periferico",
      sortable: true,
      selector: (row) => row.periferico,
    },
    {
      name: "Nave",
      sortable: true,
      selector: (row) => row.nave,
    },
    {
      name: "Estado",
      sortable: true,
      selector: (row) => row.estado,
    },
    {
      name: "Detalle",
      selector: (row) => row.detalle,
    },
  ];

  const solicitudesData =
    solicitudes &&
    solicitudes.sort((a, b) => {
      const dateA = new Date(a.fecha_falla);
      const dateB = new Date(b.fecha_falla);
      return dateB - dateA;
    });

  const data =
    solicitudesData &&
    solicitudesData
      .filter((item) =>
        asignaciones.some((filter) => filter.activo_id === item.id_activo)
      )
      .filter((item) =>
        item.nombre_activo
          .toLowerCase()
          .includes(filtros.nombre_activo.toLowerCase())
      )
      .filter((item) =>
        estadoSolicitud(item)
          .toLowerCase()
          .includes(filtros.estado.toLowerCase())
      )
      .filter(
        (item) =>
          !item.accionestomadas.toLowerCase().includes("pedimento") &&
          !item.accionestomadas.toLowerCase().includes("planificado")
      )
      .filter((item) => estadoSolicitud(item) !== "Abierta")
      .filter((item) => String(item.id).includes(id_sol))
      .map((item) => {
        return {
          id: item.id,
          fecha: formatDate(item.fecha_falla),
          severidad:
            item.severidad === "" ? (
              "Por asignar"
            ) : item.severidad === "Alta" ? (
              <button className="btn btn-sm btn-danger btn-list">
                {item.severidad}
              </button>
            ) : item.severidad === "Media" ? (
              <button className="btn btn-sm btn-warning btn-list">
                {item.severidad}
              </button>
            ) : (
              <button className="btn btn-sm btn-success btn-list">
                {item.severidad}
              </button>
            ),
          nivel:
            item.nivel === null ? (
              "Por asignar"
            ) : (
              <button className="btn btn-sm btn-info">{item.nivel}</button>
            ),
          equipo: item.nombre_item === null ? "Por asignar" : item.nombre_item,

          periferico: item.periferico,
          nave:
            activos &&
            activos
              .filter((c) => c.id === item.id_activo)
              .map((c) => c.nombre_activo),
          estado:
            estadoSolicitud(item) === "Abierta" ? (
              <button className="btn-abierta">Abierta</button>
            ) : estadoSolicitud(item) === "Aprobada" ? (
              <button className="btn-aprobada">Aprobada</button>
            ) : estadoSolicitud(item) === "Gestionada" ? (
              <button className="btn-gestion">En gestión</button>
            ) : estadoSolicitud(item) === "Programada" ? (
              <button className="btn-programada">Programada</button>
            ) : estadoSolicitud(item) === "Garantia" ? (
              <button className="btn-garantia">Garantia</button>
            ) : estadoSolicitud(item) === "Conforme" ? (
              <button className="btn-conforme">Conforme</button>
            ) : estadoSolicitud(item) === "Cerrado" ? (
              <button className="btn-finalizado">Cerrado</button>
            ) : estadoSolicitud(item) === "En Trabajos" ? (
              <button className="btn-trabajos">En Trabajos</button>
            ) : estadoSolicitud(item) === "Rechazada" ? (
              <button className="btn-rechazada">Rechazada</button>
            ) : (
              <button className="">error</button>
            ),
          detalle: (
            <Link
              className="btn btn-primary"
              to={`/admin/mis-solicitudes/${item.id}`}
            >
              <i class="fa-solid fa-magnifying-glass"></i> Ver
            </Link>
          ),
        };
      });

  const handleFiltroChange = (e) => {
    setFiltros({ ...filtros, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <AdminSidebar />
      <div className="home">
        <div className="navCentro">
          <ol class="breadcrumb">
            <li className="breadcrumb-item">
              <i class="fa-solid fa-list"></i> MIS SOLICITUDES
            </li>
            <li className="breadcrumb-item">Lista</li>
          </ol>
          <div className="row navTab">
            <div className="col-md-4 navTabItem"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="filtros">
              <span>Filtros:</span>
              <input
                placeholder="Id"
                value={id_sol}
                name="id_sol"
                onChange={handleFiltroChange}
              />
              <input
                placeholder="Estado"
                value={estado}
                name="estado"
                onChange={handleFiltroChange}
              />
              <input
                placeholder="Nombre Nave"
                value={nombre_activo}
                name="nombre_activo"
                onChange={handleFiltroChange}
              />
            </div>
            <DataTable
              columns={columns}
              data={data}
              // defaultSortFieldId={2}
              pagination
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolicitudesListAdmin;
