import React, { useState } from "react";
import Sidebar from "../../../../components/nav/Sidebar";
import { useSelector } from "react-redux";
import useEquiposNaves from "../../../../hooks/useEquiposNave";
import LoadingComponent from "../../../../components/otros/LoadingComponent";
import { useNavigate, useParams } from "react-router-dom";
import EquipoBitacoraCard from "./EquipoBitacoraCard";
import ModalEquiposBitacora from "./ModalEquiposBitacora";
import { createRecarga } from "../../../../functions/api/recargas";
import { set } from "lodash";

const initialState = {
  nro_dcto: "",
  fecha: "",
  documento: "",
  lugar: "",
  cantidad: "",
  estanque: "",
  tipo_de_documento: "",
};

const RecargasHome = () => {
  let navigate = useNavigate();

  const [reload, setReload] = useState(false);
  const [modalRegistrosDiarios, setModalRegistrosDiarios] = useState(false);
  const [equipo, setEquipo] = useState({});
  const [preview, setPreview] = useState(null);
  const [values, setValues] = useState(initialState);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const user = useSelector((state) => state.user);
  const { idNave } = useParams();

  const handleReload = () => {
    setReload(!reload);
  };

  const handleModalOpen = (data) => {
    if (!modalRegistrosDiarios) {
      setEquipo(data);
    }

    setModalRegistrosDiarios(!modalRegistrosDiarios);
  };

  const { data, loading } = useEquiposNaves(user.token, idNave, reload);

  if (loading)
    return (
      <div>
        <Sidebar />
        <div className="home">
          <LoadingComponent />
        </div>
      </div>
    );

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    setLoadingSubmit(true);

    values.estanque = equipo.id;

    createRecarga(user.token, values)
      .then((res) => {
        console.log(res);
        setValues(initialState);
        handleReload();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingSubmit(false);
      });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setValues((prevState) => ({
          ...prevState,
          documento: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      {modalRegistrosDiarios && (
        <ModalEquiposBitacora
          onOpenChange={handleModalOpen}
          values={values}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleFileChange={handleFileChange}
        />
      )}
      <Sidebar />
      <div className="home">
        <div className="navCentro mb-4">
          <ol class="breadcrumb">
            <li className="breadcrumb-item">
              <i className="fa-solid fa-marker "></i> Registros Bitácora
            </li>
            <li className="breadcrumb-item">Equipos</li>
          </ol>
        </div>
        <button
          className="backButton"
          onClick={() => navigate("/buque/registros-bitacora")}
        >
          <i class="fa-solid fa-chevron-left"></i> Volver
        </button>
        <div className="row content">
          <div className="col-md-12 mt-2">
            <h4 className="subtitleContent">Estanques</h4>
            <hr />
          </div>
          <div className="col-md-12">
            <div className="row">
              {data.length > 0 &&
                data
                  .filter((d) => d.tipo_equipo === "estanque")
                  .map((c) => (
                    <EquipoBitacoraCard
                      handleModalOpen={handleModalOpen}
                      data={c}
                    />
                  ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecargasHome;
