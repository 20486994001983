import axios from 'axios'



export const getProveedores = async(token) =>{
    return await axios.get(`${process.env.REACT_APP_API}/get-proveedores`,{
        headers:{
            "x-auth-token":token
        }
    })
}

export const getProveedorById = async(token,id) =>{
    return await axios.post(`${process.env.REACT_APP_API}/get-proveedor-by-id`,id,{
        headers:{
            "x-auth-token":token
        }
    })
}

export const createProveedor = async(token,body) =>{
    return await axios.post(`${process.env.REACT_APP_API}/create-proveedor`,body,{
        headers:{
            "x-auth-token":token
        }
    })
}

export const updateProveedor = async(token,body) =>{
    return await axios.put(`${process.env.REACT_APP_API}/update-proveedor`,body,{
        headers:{
            "x-auth-token":token
        }
    })
}

