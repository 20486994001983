import React from "react";

const SolicitudModalComentario = ({
  handleModal,
  handleSubmit,
  handleComentariocustomChange,
  values,
  comentario,
}) => {
  const {
    descripcion,
    impacto,
    obsSintomas,
    accionesPreliminares,
    capacidadesRemanentes,
    solicitante,
  } = comentario;

  return (
    <>
      <div className="container-modal" onClick={handleModal}></div>
      <div className="body-modal">
        <div className="header-modal">Información de la falla</div>
        <div className=" mt-2 mb-2">
          <form className="cardFormComentarios">
            {values.accionestomadas === "trabajo" ? (
              <>
                <label>1.- Descripción de la falla </label>
                <textArea
                  required
                  value={descripcion}
                  name="descripcion"
                  onChange={handleComentariocustomChange}
                  className="textAreaInput2"
                  placeholder="...."
                ></textArea>
                <label>2.- Impacto de la falla </label>
                <textArea
                  required
                  value={impacto}
                  name="impacto"
                  onChange={handleComentariocustomChange}
                  className="textAreaInput2"
                  placeholder="...."
                ></textArea>
                <label>3.- Observaciones </label>
                <textArea
                  required
                  value={obsSintomas}
                  name="obsSintomas"
                  onChange={handleComentariocustomChange}
                  className="textAreaInput2"
                  placeholder="...."
                ></textArea>
                <label>4.- Acciones preliminares tomadas </label>
                <textArea
                  required
                  value={accionesPreliminares}
                  name="accionesPreliminares"
                  onChange={handleComentariocustomChange}
                  className="textAreaInput2"
                  placeholder="...."
                ></textArea>
                <label> 5.- Capacidades remanentes </label>
                <textArea
                  required
                  value={capacidadesRemanentes}
                  name="capacidadesRemanentes"
                  onChange={handleComentariocustomChange}
                  className="textAreaInput2"
                  placeholder="...."
                ></textArea>
                <label>6. Nombre Solicitante </label>
                <textArea
                  required
                  value={solicitante}
                  name="solicitante"
                  onChange={handleComentariocustomChange}
                  className="textAreaInput2"
                  placeholder="...."
                ></textArea>
              </>
            ) : (
              <>
                <label>Nombre Solicitante </label>
                <textArea
                  required
                  value={solicitante}
                  name="solicitante"
                  onChange={handleComentariocustomChange}
                  className="textAreaInput2"
                  placeholder="...."
                ></textArea>
              </>
            )}
          </form>
        </div>
        <div className="footer-modal">
          <button className="btn btn-danger" onClick={handleModal}>
            Cerrar
          </button>
          <button className="btn btn-primary" onClick={handleSubmit}>
            Solicitar trabajo
          </button>
        </div>
      </div>
    </>
  );
};

export default SolicitudModalComentario;
