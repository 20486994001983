import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { Version } from "../otros/Versiones";

import Logo from "../../img/logo.png";

const Sidebar = () => {
  let navigate = useNavigate();

  let { user } = useSelector((state) => ({ ...state }));
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleWindowResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    const resizeListener = () => {
      handleWindowResize();
    };

    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 800) {
      const body = document.querySelector("body"),
        sidebar = body.querySelector(".sidebar"),
        toggle = body.querySelector(".toggle"),
        modeSwitch = body.querySelector(".toggle-switch"),
        modeText = body.querySelector(".mode-text");

      modeSwitch.addEventListener("click", () => {
        body.classList.toggle("dark");
      });

      toggle.addEventListener("click", () => {
        sidebar.classList.toggle("cls");
      });
    }
  }, [windowWidth]);

  const dispatch = useDispatch();

  const logout = () => {
    dispatch({
      type: "LOGOUT",
      payload: null,
    });
    navigate("/");
  };

  return windowWidth && windowWidth > 800 ? (
    <nav className="sidebar">
      <header>
        <div className="image-text">
          <span className="image">
            <img src={Logo} />
          </span>
          <div className="text header-text ml-2">
            <span className="name">{user.nombre}</span>
            <span className="subtitle">Napsom V {Version}</span>
          </div>
        </div>

        <i className="fa-solid fa-angle-right toggle"></i>
      </header>

      <div className="menu-bar ">
        <div className="menu">
          <ul className="menu-links">
            {/* <li className="nav-link">
              <Link to={`/buque/registros-bitacora`}>
                <i className="fa-solid fa-marker icon"></i>
                <span className="text nav-text" style={{ fontSize: "12px" }}>
                  Registros
                </span>
              </Link>
            </li> */}
            <li className="nav-link">
              <Link to={`/buque/mis-solicitudes`}>
                <i className="fa-solid fa-list icon"></i>
                <span className="text nav-text" style={{ fontSize: "12px" }}>
                  Solicitudes de trabajo
                </span>
              </Link>
            </li>
            <li className="nav-link">
              <Link to={`/buque/solicitud-planificado`}>
                <i className="fa-solid fa-list icon"></i>
                <span className="text nav-text" style={{ fontSize: "12px" }}>
                  Mantenimiento planificado
                </span>
              </Link>
            </li>

            <li className="nav-link">
              <Link to={`/buque/solicitud-pedimentos`}>
                <i className="fa-solid fa-list icon"></i>{" "}
                <span className="text nav-text" style={{ fontSize: "12px" }}>
                  Pedimentos
                </span>
              </Link>
            </li>
            {user && user.tipo_solicitante === "Solicitante" && (
              <li className="nav-link">
                <Link to={`/buque/solicitud`}>
                  <i className="fa-solid fa-screwdriver-wrench icon"></i>
                  <span className="text nav-text" style={{ fontSize: "12px" }}>
                    Crear solicitud
                  </span>
                </Link>
              </li>
            )}

            {/* {user && user.tipo_solicitante === "Solicitante" && (
                         <li className="nav-link">
                         <Link to={`/buque/aprobaciones`}>
                         <i className="fa-regular fa-circle-check icon" ></i>
                             <span className="text nav-text">Espera Aprobación</span>
                         </Link>
                        </li>
                    )}
                    
                    <li className="nav-link">
                        <Link to={`/buque/programadas`}>
                        <i className="fa-solid fa-calendar icon"></i>
                            <span className="text nav-text">Programadas</span>
                        </Link>
                    </li> */}

            {/* {user && user.tipo_solicitante === "Aprobador" && (
                        <li className="nav-link">
                        <Link to={`/buque/aprobar`}>
                        <i className="fa-regular fa-circle-check icon" ></i>
                            <span className="text nav-text">Por Aprobar</span>
                        </Link>
                        </li>
                    )} */}

            {/* {user && user.tipo_solicitante === "Aprobador" && (
                        <li className="nav-link">
                        <Link to={`/buque/finalizar`}>
                        <i className="fa-solid fa-circle-check icon"></i>
                            <span className="text nav-text">Por finalizar</span>
                        </Link>
                        </li>
                    )} */}
          </ul>
        </div>
        <div className="bottom-content">
          <li className="">
            <Link onClick={logout}>
              <i className="fa-solid fa-right-from-bracket icon"></i>
              <span className="text nav-text">Desconectarse</span>
            </Link>
          </li>
          <li className="mode">
            <div className="moon-sun">
              <i className="fa-solid fa-moon icon moon"></i>
              <i className="fa-solid fa-sun icon sun"></i>
            </div>
            <span className="mode-text text">Dark Mode</span>
            <div className="toggle-switch">
              <span className="switch"></span>
            </div>
          </li>
        </div>
      </div>
    </nav>
  ) : (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      {" "}
      <img src={Logo} style={{ width: "60px" }} />
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarTogglerDemo01"
        aria-controls="navbarTogglerDemo01"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
        <a className="navbar-brand" href="#"></a>
        <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
          {/* <li className="nav-link">
            <Link to={`/buque/registros-bitacora`}>
              <i className="fa-solid fa-marker icon"></i>
              <span className="text nav-text" style={{ fontSize: "12px" }}>
                Registros
              </span>
            </Link>
          </li> */}
          <li className="nav-link">
            <Link to={`/buque/mis-solicitudes`}>
              <i className="fa-solid fa-list icon"></i>
              <span className="text nav-text" style={{ fontSize: "12px" }}>
                Solicitudes de trabajo
              </span>
            </Link>
          </li>
          <li className="nav-link">
            <Link to={`/buque/solicitud-planificado`}>
              <i className="fa-solid fa-list icon"></i>
              <span className="text nav-text" style={{ fontSize: "12px" }}>
                Mantenimiento planificado
              </span>
            </Link>
          </li>
          <li className="nav-link">
            <Link to={`/buque/solicitud-pedimentos`}>
              <i className="fa-solid fa-list icon"></i>{" "}
              <span className="text nav-text" style={{ fontSize: "12px" }}>
                Pedimentos
              </span>
            </Link>
          </li>
          {user && user.tipo_solicitante === "Solicitante" && (
            <li className="nav-link">
              <Link to={`/buque/solicitud`}>
                <i className="fa-solid fa-screwdriver-wrench icon"></i>
                <span className="text nav-text" style={{ fontSize: "12px" }}>
                  Crear solicitud
                </span>
              </Link>
            </li>
          )}

          {/* {user && user.tipo_solicitante === "Solicitante" && (
                         <li className="nav-link">
                         <Link to={`/buque/aprobaciones`}>
                         <i className="fa-regular fa-circle-check icon" ></i>
                             <span className="text nav-text">Espera Aprobación</span>
                         </Link>
                        </li>
                    )}
                    
                    <li className="nav-link">
                        <Link to={`/buque/programadas`}>
                        <i className="fa-solid fa-calendar icon"></i>
                            <span className="text nav-text">Programadas</span>
                        </Link>
                    </li> */}

          {/* {user && user.tipo_solicitante === "Aprobador" && (
                        <li className="nav-link">
                        <Link to={`/buque/aprobar`}>
                        <i className="fa-regular fa-circle-check icon" ></i>
                            <span className="text nav-text">Por Aprobar</span>
                        </Link>
                        </li>
                    )} */}

          <li className="nav-link">
            <Link onClick={logout}>
              <i className="fa-solid fa-right-from-bracket icon"></i>
              <span className="text nav-text" style={{ fontSize: "12px" }}>
                Desconectarse
              </span>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
