import axios from "axios";

const API_URL = process.env.REACT_APP_API;

export const getEquiposBitacora = async (token) => {
  return await axios.get(`${API_URL}/equipos_bitacora`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const getEquiposBitacoraByIdNave = async (token, idNave) => {
  return await axios.get(`${API_URL}/equipos_bitacora/nave/${idNave}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const createEquipoBitacora = async (token, body) => {
  return await axios.post(`${API_URL}/equipos_bitacora`, body, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const updateEquipoBitacora = async (token, id, body) => {
  return await axios.put(`${API_URL}/equipos_bitacora/${id}`, body, {
    headers: {
      "x-auth-token": token,
    },
  });
};
