import React from "react";

const ModalProgramarSolicitud = ({
  handleProgramarModal,
  handleProgramar,
  handleChange,
  values,
}) => {
  const {
    fecha_programada,
    nrocot,
    fechacotizacion,
    nrosolped,
    fechasolped,
    nrooc,
    fechaoc,
  } = values;
  return (
    <>
      <div className="container-modal " onClick={handleProgramarModal}></div>
      <div className="body-modal">
        <div className="header-modal mb-2">Programar solicitud</div>
        <div className="row ">
          <div className="col-md-12  p-3">
            <label className="label-title">Fecha Programación</label>
            <input
              type="datetime-local"
              className="w-100 inputFormGest"
              onChange={handleChange}
              name="fecha_programada"
              value={fecha_programada}
            />
          </div>
          <div className="col-md-6">
            <label className="label-title">Número Cotización</label>
            <input
              type="number"
              className="w-100 inputFormGest"
              onChange={handleChange}
              name="nrocot"
              value={nrocot}
            />
          </div>
          <div className="col-md-6">
            <label className="label-title">Fecha Cotización</label>
            <input
              type="datetime-local"
              className="w-100 inputFormGest"
              onChange={handleChange}
              name="fechacotizacion"
              value={fechacotizacion}
            />
          </div>
          <div className="col-md-12 mt-3"></div>
          <div className="col-md-6">
            <label className="label-title">Número Solped</label>
            <input
              type="number"
              className="w-100 inputFormGest"
              onChange={handleChange}
              name="nrosolped"
              value={nrosolped}
            />
          </div>
          <div className="col-md-6">
            <label className="label-title">Fecha Solped</label>
            <input
              type="datetime-local"
              className="w-100 inputFormGest"
              onChange={handleChange}
              name="fechasolped"
              value={fechasolped}
            />
          </div>
        </div>
        <div className="footer-modal mt-3">
          <button className="btn btn-danger" onClick={handleProgramarModal}>
            Cerrar
          </button>
          <button className="btn btn-primary" onClick={handleProgramar}>
            Programar
          </button>
        </div>
      </div>
    </>
  );
};

export default ModalProgramarSolicitud;
