import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import AdminSidebar from "../../../components/nav/AdminSidebar";

import Loading from "../../../components/otros/Loading";
import DataTable from "react-data-table-component";

import { getActivosCol } from "../../../functions/api/activos";

const Activos = () => {
  const [loading, setLoading] = useState(false);
  const [activos, setActivos] = useState([]);

  let { user } = useSelector((state) => ({ ...state }));

  useEffect(() => {
    setLoading(true);

    loadActivos();
    setLoading(false);
  }, []);

  const loadActivos = () =>
    getActivosCol(user.token).then((res) => {
      setActivos(res.data);
    });

  const columns = [
    {
      name: "Nombre",
      selector: (row) => row.nombre_activo,
    },
    {
      name: "Tipo ",
      selector: (row) => row.tactivo,
    },
    {
      name: "Activo",
      selector: (row) => row.activo,
    },
    {
      name: "Editar",
      selector: (row) => row.editar,
    },
    {
      name: "Equipos",
      selector: (row) => row.asignar,
      width: "130px",
    },
    {
      name: "Equipos Bitacora",
      selector: (row) => row.bitacora,
      width: "130px",
    },
  ];

  const data =
    activos &&
    activos.map((c) => {
      return {
        nombre_activo: c.nombre_activo,
        tactivo: c.tipo_activo,
        activo:
          c.activo === true ? (
            <button className="btn btn-primary">Activo</button>
          ) : (
            <button className="btn btn-secondary">Inactivo</button>
          ),
        editar: (
          <Link
            to={`/admin/datos-maestros/activos/${c.id}`}
            className="btn btn-success"
          >
            Editar
          </Link>
        ),
        asignar: (
          <Link
            to={`/admin/datos-maestros/asignar-sistema/${c.id}`}
            className="btn btn-primary btn-sm"
          >
            <i class="fa-solid fa-gears"></i> Asignar
          </Link>
        ),
        bitacora: (
          <Link
            to={`/admin/datos-maestros/equipos-bitacora/${c.id}`}
            className="btn btn-secondary btn-sm"
          >
            <i class="fa-solid fa-gears"></i> Gestionar
          </Link>
        ),
      };
    });

  return (
    <div>
      <AdminSidebar />
      {loading ? (
        <div className="home">
          <Loading />
        </div>
      ) : (
        <div className="home">
          <div className="navCentro">
            <ol class="breadcrumb">
              <li className="breadcrumb-item">
                <i class="fa-solid fa-ship"></i> ACTIVOS
              </li>
            </ol>
            <div className="row navTab">
              <div className="col-md-6 navTabItem">
                <Link to="/admin/datos-maestros/create-activo">
                  Crear Activo<i class="fa-solid fa-circle-right ml-2"></i>
                </Link>
              </div>
              <div className="col-md-6 navTabItem">
                <Link to="/admin/datos-maestros/categoria-activos">
                  Categoria Activos<i class="fa-solid fa-circle-right ml-2"></i>
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <DataTable columns={columns} data={data} pagination />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Activos;
