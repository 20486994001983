import axios from "axios";

export const sendMail = async (token, body) => {
  return await axios.post(`${process.env.REACT_APP_API}/mail`, body, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const sendMailTarea = async (token, body) => {
  return await axios.post(`${process.env.REACT_APP_API}/mail-tarea`, body, {
    headers: {
      "x-auth-token": token,
    },
  });
};
