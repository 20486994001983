export const Version = "1.3.6";

export const VersionesData = [
  {
    v: "1.0.1",
    updates: [
      {
        title: "Sort By Date",
        descripcion:
          "Se ordenan lista de solicitudes en tablas por fecha de falla de manera descendente.",
        UpdateType: "Funcionalidad",
      },
    ],
  },
  {
    v: "1.0.2",
    updates: [
      {
        title: "Asignación de Nivel a solicitudes",
        descripcion:
          "Se arregla error al querer asignar nivel a solicitudes desde usuario ITO.",
        UpdateType: "Error Fix",
      },
    ],
  },
];
