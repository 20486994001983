import axios from "axios";

export const getItemsActivos = async (token) => {
  return await axios.get(`${process.env.REACT_APP_API}/get-items-activos`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const getItemsActivosById = async (token, id) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/get-items-activos-by-id`,
    id,
    {
      headers: {
        "x-auth-token": token,
      },
    }
  );
};

export const getItemsActivosById2 = async (token, id) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/get-items-activos-by-id-2`,
    id,
    {
      headers: {
        "x-auth-token": token,
      },
    }
  );
};

export const getSistemasByNave = async (token, id) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/get-items-activos-by-activo`,
    id,
    {
      headers: {
        "x-auth-token": token,
      },
    }
  );
};

export const crateItemsActivos = async (token, body) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/create-items-activos`,
    body,
    {
      headers: {
        "x-auth-token": token,
      },
    }
  );
};

export const crearAsignacionItems = async (token, body) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/create-items-activos-asig`,
    body,
    {
      headers: {
        "x-auth-token": token,
      },
    }
  );
};

export const getItemsAsignaciones = async (token) => {
  return await axios.get(
    `${process.env.REACT_APP_API}//get-items-asignaciones`,
    {
      headers: {
        "x-auth-token": token,
      },
    }
  );
};

export const createAsignacionItems2 = async (token, body) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/create-items-activos-asig-2`,
    body,
    {
      headers: {
        "x-auth-token": token,
      },
    }
  );
};

export const updateItemsActivos = async (token, body) => {
  return await axios.put(
    `${process.env.REACT_APP_API}/update-items-activos`,
    body,
    {
      headers: {
        "x-auth-token": token,
      },
    }
  );
};
