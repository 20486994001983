import React,{ useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';
import AdminSidebar from '../../../components/nav/AdminSidebar'
import DataTable from 'react-data-table-component';

import Loading from '../../../components/otros/Loading'

import { getProveedores } from '../../../functions/api/proveedores'

const Proveedores = () => {
    
    const [loading,setLoading] = useState(false)
    const [proveedores,setProveedores] = useState([])

    let {user} = useSelector((state) =>({...state}))
  
    useEffect(() => {
        setLoading(true)

        const delay = 500; // 3 seconds in milliseconds
    
        const timer = setTimeout(() => {
          // Code to execute after the delay
          setLoading(false)
          loadProveedores()
          console.log('3 seconds have passed');
        }, delay);
    
        return () => {
          clearTimeout(timer);
        };
    }, [])


    
    const loadProveedores = () => getProveedores(user.token).then( res => {
        setProveedores(res.data)
    }
    ).catch( err => 
        console.log(err)
    )


    const columns = [
        {
            name: 'Rut',
            selector: row => row.rut,
        },
        {
            name: 'Nombre Proveedor',
            selector: row => row.nombre,
        },
        {
            name: 'Activo',
            selector: row => row.activo,
        },
        {
            name: 'Editar',
            selector: row => row.editar,
        }
    ]

    const data = proveedores && proveedores.map( c => {
        return {
            rut: c.rut,
            nombre: c.proveedor,
            activo: c.activo === true ? <button className='btn btn-primary'>Activo</button>:<button className='btn btn-secondary'>Inactivo</button>,
            editar:<Link to={`/admin/datos-maestros/proveedores/actualizar-proveedor/${c.id}`} className='btn btn-success'>Editar</Link >
        }
    })

    
  return (
    <div> 
        <AdminSidebar />
        {loading ? (
          
        <div className="home">
          <Loading />
        </div>
        ):(
            <div className="home">
                 <div className='navCentro'>
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><i class="fa-solid fa-truck-field icon"></i> PROVEEDORES</li>
                    </ol>
                    <div className="row navTab">
                        
                        <div className="col-md-12 navTabItem">
                            <Link to='/admin/datos-maestros/proveedores/crear-proveedor'>Crear Proveedor<i class="fa-solid fa-square-plus ml-2"></i></Link>
                        </div>
                    </div>
                   
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <DataTable
                        columns={columns}
                        data={data}
                        pagination
                        />
                        
                        
                    </div>
                    </div>
            </div>
        )}
    </div>
  )
}

export default Proveedores