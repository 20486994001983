import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link, useParams } from "react-router-dom";
import AdminSidebar from "../../../components/nav/AdminSidebar";
import { toast } from "react-toastify";
import Loading from "../../../components/otros/Loading";
import { updateUser, getUserByIdReal } from "../../../functions/api/auth";

const UpdateUser = () => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const { id } = useParams();
  const { user } = useSelector((state) => ({ ...state }));
  const navigate = useNavigate();

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    setLoading(true);
    try {
      const response = await getUserByIdReal({ id }, user.token);
      const userData = response.data[0];
      // Parse tipo_solicitante if the role is Analista
      if (
        userData.rol === "Analista" &&
        typeof userData.tipo_solicitante !== "string"
      ) {
        userData.tipo_solicitante = JSON.parse(userData.tipo_solicitante);
      }
      setUserData(userData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Reset tipo_solicitante when role changes to Analista
    if (name === "rol" && value === "Analista") {
      setUserData({ ...userData, [name]: value, tipo_solicitante: [] });
    } else {
      setUserData({ ...userData, [name]: value });
    }
  };

  const handleCheckboxChange = (event) => {
    const checkedValue = event.target.value;

    let updatedTipoSolicitante = [];

    if (typeof userData.tipo_solicitante === "string") {
      let tipoSolicitante = JSON.parse(userData.tipo_solicitante);

      updatedTipoSolicitante = event.target.checked
        ? [...tipoSolicitante, checkedValue]
        : tipoSolicitante.filter((item) => item !== checkedValue);
    } else {
      updatedTipoSolicitante = event.target.checked
        ? [...userData.tipo_solicitante, checkedValue]
        : userData.tipo_solicitante.filter((item) => item !== checkedValue);
    }

    setUserData({ ...userData, tipo_solicitante: updatedTipoSolicitante });
  };

  const isCheckboxChecked = (c) => {
    return userData.tipo_solicitante.includes(c);
  };

  const submitUser = async (e) => {
    e.preventDefault();

    const updatedUser = {
      ...userData,
      tipo_solicitante:
        userData.rol === "Analista"
          ? JSON.stringify(userData.tipo_solicitante)
          : userData.tipo_solicitante,
    };

    console.log(updatedUser);
    try {
      await updateUser(updatedUser, user.token);
      toast.success("Usuario actualizado exitosamente");
      navigate("/admin/usuarios");
    } catch (error) {
      toast.error(error.response?.data || "Error al actualizar usuario");
    }
  };

  const renderSolicitanteOptions = () => (
    <>
      <label>Tipo de solicitante</label>
      <select
        id="selectInput"
        value={userData.tipo_solicitante}
        name="tipo_solicitante"
        onChange={handleChange}
      >
        <option value="">Escoga el rol del usuario</option>
        <option value="Solicitante">Solicitante</option>
        <option value="Aprobador">Aprobador</option>
      </select>
    </>
  );

  const renderSupervisorOptions = () => (
    <>
      <label>Tipo de supervisor</label>
      <select
        id="selectInput"
        value={userData.tipo_solicitante}
        name="tipo_solicitante"
        onChange={handleChange}
      >
        <option value="">Escoga el rol del usuario</option>
        <option value="Superintendente">Superintendente</option>
        <option value="Ito">Ito</option>
        <option value="Flota">Flota</option>
        <option value="SSO">SSO</option>
        <option value="Armatorial">Armatorial</option>
      </select>
    </>
  );

  const renderAnalistaCheckboxes = () => {
    const checkBoxAnalista = ["Combustible", "Pedimentos", "Trabajos"];
    return (
      <div className="mb-4">
        <label>Roles de analista</label>
        {}
        <div className="row">
          {checkBoxAnalista.map((c) => (
            <div className="col-md-3" key={c}>
              <label className="switchN">
                <input
                  type="checkbox"
                  value={c}
                  onChange={handleCheckboxChange}
                  checked={isCheckboxChecked(c)}
                />
                <span className="slider"></span>
                <span className="text">{c}</span>
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      <AdminSidebar />
      {loading ? (
        <div className="home">
          <Loading />
        </div>
      ) : (
        <div className="home">
          <div className="navCentro">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <i className="fa-solid fa-users "></i> USUARIOS
              </li>
              <li className="breadcrumb-item">Editar usuario</li>
            </ol>
            <div className="row navTab">
              <div className="col-md-12 navTabItem">
                <Link to="/admin/usuarios">
                  <i className="fa-solid fa-chevron-left mr-2"></i>Atras
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <form className="formFormat1" onSubmit={submitUser}>
                <label>Nombre de usuario</label>
                <input
                  type="text"
                  value={userData.nombre || ""}
                  name="nombre"
                  onChange={handleChange}
                />
                <label>Email</label>
                <input
                  type="email"
                  value={userData.email || ""}
                  name="email"
                  onChange={handleChange}
                />
                <label>Password</label>
                <input
                  type="password"
                  value={userData.password || ""}
                  name="password"
                  onChange={handleChange}
                />
                <label>Rol del usuario</label>
                <select
                  id="selectInput"
                  value={userData.rol || ""}
                  name="rol"
                  onChange={handleChange}
                >
                  <option value="">Escoga el rol del usuario</option>
                  <option value="Admin">Administrador</option>
                  <option value="Supervisor">Supervisor</option>
                  <option value="Buque">Buque</option>
                  <option value="Analista">Analista</option>
                </select>
                <label>Activo / Inactivo</label>
                <select
                  id="selectInput"
                  value={userData.activo}
                  name="activo"
                  onChange={handleChange}
                >
                  <option value="">Escoga el estado del usuario</option>
                  <option value={true}>Activo</option>
                  <option value={false}>Inactivo</option>
                </select>
                {userData.rol === "Buque" && renderSolicitanteOptions()}
                {userData.rol === "Supervisor" && renderSupervisorOptions()}
                {userData.rol === "Analista" && renderAnalistaCheckboxes()}
                <button className="formButton1" type="submit">
                  Actualizar usuario
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateUser;
