import React from "react";

const ModalEquiposBitacora = ({
  onOpenChange,
  handleSubmit,
  handleChange,
  values,
  handleFileChange,
}) => {
  const {
    nro_dcto,
    fecha,
    documento,
    lugar,
    cantidad,
    estanque,
    tipo_de_documento,
  } = values;

  return (
    <>
      <div className="container-modal" onClick={onOpenChange}></div>
      <div className="body-modal">
        <h6>Agregar datos</h6>
        <div className="row">
          <div className="col-md-4">
            <label className="label-title mt-2 mb-2">Nro Documento</label>
            <input
              name="nro_dcto"
              value={nro_dcto}
              onChange={handleChange}
              className="inputRegistros"
              placeholder="Ingrese nro documento"
            />
          </div>
          <div className="col-md-4">
            <label className="label-title mt-2 mb-2">Fecha</label>
            <input
              name="fecha"
              value={fecha}
              onChange={handleChange}
              className="inputRegistros"
              placeholder="Ingrese fecha"
              type="datetime-local"
            />
          </div>
          <div className="col-md-4">
            <label className="label-title mt-2 mb-2">Lugar</label>
            <input
              name="lugar"
              value={lugar}
              onChange={handleChange}
              className="inputRegistros"
              placeholder="Ingrese lugar"
            />
          </div>

          <div className="col-md-6 mt-2">
            <label className="label-title mt-2 mb-2">Cantidad</label>
            <input
              name="cantidad"
              value={cantidad}
              type="number"
              onChange={handleChange}
              className="inputRegistros"
              placeholder="Ingrese cantidad"
            />
          </div>
          <div className="col-md-6 mt-2">
            <label className="label-title mt-2 mb-2">Tipo de documento</label>
            <select
              name="tipo_de_documento"
              value={tipo_de_documento}
              onChange={handleChange}
              className="inputRegistros"
            >
              <option value="">Seleccione</option>
              <option value="Guía de despacho">Guía de despacho</option>
              <option value="Vale">Vale</option>
              <option value="Factura">Factura</option>
            </select>
          </div>

          <div className="col-md-12 ">
            <label className="label-title mt-2 mb-2">Documento</label>
            <input
              name="documento"
              type="file"
              onChange={handleFileChange}
              className="inputRegistros"
              placeholder="documento"
              accept="image/*"
            />
            {documento && (
              <img
                src={documento}
                alt="documento Preview"
                style={{ width: "100px", height: "100px" }}
              />
            )}
          </div>

          <button
            className="buttonContentSecondary w-100 mt-2"
            onClick={handleSubmit}
          >
            Agregar Registro
          </button>
        </div>
      </div>
    </>
  );
};

export default ModalEquiposBitacora;
