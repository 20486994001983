import axios from "axios";

const API_URL = process.env.REACT_APP_API;

export const getRecargas = async (token) => {
  return await axios.get(`${API_URL}/recargas`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const getRecargasByEstanque = async (token, estanque) => {
  return await axios.get(`${API_URL}/recargas/estanque/${estanque}`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const createRecarga = async (token, body) => {
  return await axios.post(`${API_URL}/recargas`, body, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const updateRecarga = async (token, id, body) => {
  return await axios.put(`${API_URL}/recargas/${id}`, body, {
    headers: {
      "x-auth-token": token,
    },
  });
};
