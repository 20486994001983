import React from "react";
import Sidebar from "../../../components/nav/Sidebar";
import { useSelector } from "react-redux";
import useNavesAsignadas from "../../../hooks/useNavesAsignadas";
import { useNavigate } from "react-router-dom";

const HomeRegistrosBitacora = () => {
  let navigate = useNavigate();

  const [reload, setReload] = React.useState(false);

  let { user } = useSelector((state) => ({ ...state }));

  const handleReload = () => {
    setReload(!reload);
  };

  const { data, loading } = useNavesAsignadas(user.token, user.id, reload);

  if (loading)
    return (
      <div>
        <Sidebar />
        <div className="home">
          <p>Loading....</p>
        </div>
      </div>
    );

  return (
    <div>
      <Sidebar />
      <div className="home">
        <div className="navCentro mb-4">
          <ol class="breadcrumb">
            <li className="breadcrumb-item">
              <i className="fa-solid fa-marker "></i> Registros Bitácora
            </li>
          </ol>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="card-registros">
              <div className="card-registros-title">
                <h5>Registros diarios</h5>
              </div>

              <div className="card-registros-content">
                {data.length > 0 ? (
                  <div className="listadoAsignaciones">
                    {data.map((c) => (
                      <div className="item-activo-registros">
                        <span>{c.nombre_activo}</span>
                        <button
                          onClick={() =>
                            navigate(
                              `/buque/registros-bitacora/registros-diarios/${c.activo_id}`
                            )
                          }
                        >
                          <i class="fa-solid fa-chevron-right"></i>
                        </button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>No tiene registros diarios</p>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card-registros">
              <div className="card-registros-title">
                <h5>Recargas</h5>
              </div>
              <div className="card-registros-content">
                {data.length > 0 ? (
                  <div className="listadoAsignaciones">
                    {data.map((c) => (
                      <div className="item-activo-registros">
                        <span>{c.nombre_activo}</span>
                        <button
                          onClick={() =>
                            navigate(
                              `/buque/registros-bitacora/recargas/${c.activo_id}`
                            )
                          }
                        >
                          <i class="fa-solid fa-chevron-right"></i>
                        </button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>No tiene recargas</p>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card-registros">
              <div className="card-registros-title">
                <h5>Entregas de combustible</h5>
              </div>
              <div className="card-registros-content">
                {data.length > 0 ? (
                  <div className="listadoAsignaciones">
                    {data.map((c) => (
                      <div className="item-activo-registros">
                        <span>{c.nombre_activo}</span>
                        <button
                          onClick={() =>
                            navigate(
                              `/buque/registros-bitacora/entregas/${c.activo_id}`
                            )
                          }
                        >
                          <i class="fa-solid fa-chevron-right"></i>
                        </button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p>No tiene entregas</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeRegistrosBitacora;
