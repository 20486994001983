import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { getUsers } from "../../functions/api/auth";
import { getTrabajoById, updateTrabajo } from "../../functions/api/trabajos";
import { getProveedores } from "../../functions/api/proveedores";

import SolicitudItoForm from "../../components/forms/solicitudesDetail/SolicitudITOForm";
import SolicitudSuperIntendenteForm from "../../components/forms/solicitudesDetail/SolicitudSuperIntendenteForm";
import SupervisorSidebar from "../../components/nav/SupervisorSidebar";
import {
  downloadFile,
  getArchivosSolicitud,
  uploadFile,
} from "../../functions/api/archivos";
import { sendMailTarea } from "../../functions/api/mail";
import ModalProgramarSolicitud from "./modal/ModalProgramarSolicitud";
import SolicitudFlotaForm from "../../components/forms/solicitudesDetail/SolicitudFlotaForm";

const comentarioState = {
  usuario: "",
  fecha: "",
  fechaComentario: "",
  titulo: "",
  tipoComentario: "",
  descripcion: "",
};

const repuestoState = {
  cantRep: "",
  descRep: "",
};

const SolicitudDetailSup = () => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState("");
  const [observacionesData, setObsevaciones] = useState([]);
  const [rep, setRep] = useState([]);
  const [repuestosData, setRepuestosData] = useState(repuestoState);
  const [comentario, setComentario] = useState(comentarioState);
  const [nivelModal, setNivelModal] = useState(false);
  const [programarModal, setProgramarModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [adjuntosData, setAdjuntosData] = useState([]);

  let navigate = useNavigate();
  let { user } = useSelector((state) => ({ ...state }));

  let { id } = useParams();

  useEffect(() => {
    setLoading(true);

    const delay = 500; // 3 seconds in milliseconds

    const timer = setTimeout(() => {
      // Code to execute after the delay
      setLoading(false);

      loadTrabajo();
      loadUsers();
      loadProveedores();
      loadAdjuntos();
      console.log("3 seconds have passed");
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const loadAdjuntos = () =>
    getArchivosSolicitud(user.token, { id_trabajo: id }).then((res) =>
      setAdjuntosData(res.data)
    );

  const loadTrabajo = () =>
    getTrabajoById(user.token, { id: id }).then((res) =>
      setValues(res.data[0])
    );
  const loadUsers = () =>
    getUsers(user.token).then((res) => {
      setUsers(res.data);
    });
  const loadProveedores = () =>
    getProveedores(user.token).then((res) => setProveedores(res.data));

  const {
    fecha_falla,
    costoreparacion,
    nivel,
    responsable,
    repuestos,
    idclasificacion,
    nombre_activo,
    nombre_item,
    severidad,
    fecha_programada,
    origen_falla,
    periferico,
    clasificacion,
    nrocot,
    fechacotizacion,
    nrosolped,
    fechasolped,
    nrooc,
    fechaoc,
    respuestos,
    nombresolicitante,
    nombreaprobador,
    imagenes,
    observaciones,
    estado_general,
    estado_buque,
    estado_supervisor,
  } = values;

  const {
    titulo,
    fecha,
    usuario,
    fechaComentario,
    descripcion,
    tipoComentario,
  } = comentario;

  const { cantRep, descRep } = repuestosData;

  const obs = values && JSON.parse(observaciones);

  const repData = values && repuestos && JSON.parse(repuestos);

  //Gestionar Niveles

  const handleSubmitNivel = (e) => {
    e.preventDefault();

    values.observaciones = JSON.stringify(obs.concat(observacionesData));

    if (values.costoreparacion !== null) {
      values.costoreparacion = values.costoreparacion.replace(/[^0-9.]/g, "");
    }

    let estadoG;
    let aprobValue;

    if (values.nivel === "1") {
      values.responsable = values.nombresolicitante;

      estadoG = JSON.parse(estado_general);

      aprobValue = {
        estado: "Programada",
        fecha: new Date(),
      };
    } else if (values.nivel === "2") {
      estadoG = JSON.parse(estado_general);

      aprobValue = {
        estado: "Gestionada",
        fecha: new Date(),
      };
    } else if (values.nivel === "3") {
      estadoG = JSON.parse(estado_general);

      aprobValue = {
        estado: "Gestionada",
        fecha: new Date(),
      };
    }

    const newEstado = [...estadoG, aprobValue];

    values.estado_general = JSON.stringify(newEstado);

    updateTrabajo(user.token, values)
      .then((r) => {
        toast.success("Nivel asignado con éxito");
        setObsevaciones([]);
        setRep([]);
        setNivelModal(!nivelModal);
        navigate(`/supervisor/mis-solicitudes/${id}`);
      })
      .catch((err) => console.log(err));
  };

  const handleSubmitComentario = (e) => {
    e.preventDefault();

    values.observaciones = JSON.stringify(obs.concat(observacionesData));

    values.repuestos = JSON.stringify(repData.concat(rep));
    if (values.costoreparacion !== null) {
      values.costoreparacion = values.costoreparacion.replace(/[^0-9.]/g, "");
    }

    updateTrabajo(user.token, values)
      .then((r) => {
        observacionesData.forEach((item) => {
          if (item.tipoComentario === "Tarea") {
            let body = {};
            body.titulo = item.titulo;
            body.fecha = item.fechaComentario;
            body.desc = item.descripcion;
            body.nombresolicitante = item.usuario;
            body.id = id;
            body.nave_id = values.id_activo;

            sendMailTarea(user.token, body)
              .then((res) => {
                toast.success("Email de tarea enviado!");
              })
              .catch((err) => {
                console.log(err);
                toast.error("Error al enviar correo de tarea.");
              });
          }
        });
        toast.success("Solicitud actualizada con exito");
        setObsevaciones([]);
        setRep([]);
        navigate(`/supervisor/mis-solicitudes/${id}`);
      })
      .catch((err) => console.log(err));
  };

  const handleModalSupervisor = (e) => {
    e.preventDefault();
    setProgramarModal(!programarModal);
  };

  const handleProgramarModal = (e) => {
    setProgramarModal(!programarModal);
  };

  const handleProgramar = (e) => {
    e.preventDefault();

    if (values.fecha_programada === null) {
      return toast.error("No hay fecha programada");
    }

    if (values.nivel === 3 && values.nrosolped === null) {
      return toast.error("No hay número de solped ingresado");
    }
    values.observaciones = JSON.stringify(obs.concat(observacionesData));
    values.repuestos = JSON.stringify(repData.concat(rep));
    if (values.costoreparacion !== null) {
      values.costoreparacion = values.costoreparacion.replace(/[^0-9.]/g, "");
    }

    const estadoG = JSON.parse(estado_general);

    let aprobValue = {};

    if (
      values.accionestomadas === "pedimentoFlota" ||
      values.accionestomadas === "pedimentoArmatorial" ||
      values.accionestomadas === "pedimentoSSO" ||
      values.accionestomadas === "pedimentoMant"
    ) {
      aprobValue.estado = "Gestionada";
      aprobValue.fecha = new Date();
    } else {
      aprobValue.estado = "Programada";
      aprobValue.fecha = new Date();
    }

    const newEstado = [...estadoG, aprobValue];

    values.estado_general = JSON.stringify(newEstado);

    updateTrabajo(user.token, values)
      .then((r) => {
        observacionesData.forEach((item) => {
          if (item.tipoComentario === "Tarea") {
            let body = {};
            body.titulo = item.titulo;
            body.fecha = item.fechaComentario;
            body.desc = item.descripcion;
            body.nombresolicitante = item.usuario;
            body.id = id;
            body.nave_id = values.id_activo;

            sendMailTarea(user.token, body)
              .then((res) => {
                toast.success("Email de tarea enviado!");
              })
              .catch((err) => {
                console.log(err);
                toast.error("Error al enviar correo de tarea.");
              });
          }
        });
        toast.success("Solicitud actualizada con exito");
        setObsevaciones([]);
        setRep([]);
        navigate(`/supervisor/mis-solicitudes/${id}`);
        handleProgramarModal();
      })
      .catch((err) => console.log(err));
  };

  const handleRecibir = (e) => {
    e.preventDefault();

    values.observaciones = JSON.stringify(obs.concat(observacionesData));
    values.repuestos = JSON.stringify(repData.concat(rep));
    if (values.costoreparacion !== null) {
      values.costoreparacion = values.costoreparacion.replace(/[^0-9.]/g, "");
    }
    const estadoG = JSON.parse(estado_general);

    const aprobValue = {
      estado: "Conforme",
      fecha: new Date(),
    };

    const newEstado = [...estadoG, aprobValue];

    values.estado_general = JSON.stringify(newEstado);

    updateTrabajo(user.token, values)
      .then((r) => {
        observacionesData.forEach((item) => {
          if (item.tipoComentario === "Tarea") {
            let body = {};
            body.titulo = item.titulo;
            body.fecha = item.fechaComentario;
            body.desc = item.descripcion;
            body.nombresolicitante = item.usuario;
            body.id = id;
            body.nave_id = values.id_activo;

            sendMailTarea(user.token, body)
              .then((res) => {
                toast.success("Email de tarea enviado!");
              })
              .catch((err) => {
                console.log(err);
                toast.error("Error al enviar correo de tarea.");
              });
          }
        });
        toast.success("Solicitud actualizada con exito");
        setObsevaciones([]);
        setRep([]);
        navigate(`/supervisor/mis-solicitudes/${id}`);
      })
      .catch((err) => console.log(err));
  };

  const handleValuesChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleComentarioChange = (e) => {
    setComentario({ ...comentario, [e.target.name]: e.target.value });
  };

  const deleteItem = (index) => {
    const updatedArray = [...observacionesData]; // Create a copy of the array
    updatedArray.splice(index, 1); // Remove the item at the specified position
    setObsevaciones(updatedArray);
  };

  const deleteRepuesto = (index) => {
    const updatedArray = [...rep]; // Create a copy of the array
    updatedArray.splice(index, 1); // Remove the item at the specified position
    setRep(updatedArray);
  };

  const handleAddDataObs = (e) => {
    e.preventDefault();

    if (titulo === "") {
      toast.error("Falta agregar título ");
    } else if (fecha === "") {
      toast.error("Falta agregar fecha");
    } else if (tipoComentario === "") {
      toast.error("Falta seleccionar tipo de comentario");
    } else if (descripcion === "") {
      toast.error("Falta agregar descripción");
    } else {
      const currentDate = new Date();
      comentario.usuario = user.nombre;
      comentario.fechaComentario = currentDate;
      setObsevaciones((prevData) => [...prevData, comentario]);
      setComentario(comentarioState);
    }
  };

  const handleRepuestoChange = (e) => {
    e.preventDefault();
    setRepuestosData({ ...repuestosData, [e.target.name]: e.target.value });
  };

  const handleAddRepuestosData = (e) => {
    e.preventDefault();
    if (cantRep === "") {
      toast.error("Falta agregar cantidad");
    } else if (descRep === "") {
      toast.error("Falta agregar descripción");
    } else {
      setRep((prevData) => [...prevData, repuestosData]);
      setRepuestosData(repuestoState);
    }
  };

  const handleNivelModal = (e) => {
    e.preventDefault();

    setNivelModal(!nivelModal);
  };

  const handleCerrar = (e) => {
    e.preventDefault();

    values.observaciones = JSON.stringify(obs.concat(observacionesData));
    values.repuestos = JSON.stringify(repData.concat(rep));
    if (values.costoreparacion !== null) {
      values.costoreparacion = values.costoreparacion.replace(/[^0-9.]/g, "");
    }
    const estadoG = JSON.parse(estado_general);

    const aprobValue = {
      estado: "Cerrado",
      fecha: new Date(),
    };

    const newEstado = [...estadoG, aprobValue];

    values.estado_general = JSON.stringify(newEstado);

    updateTrabajo(user.token, values)
      .then((r) => {
        observacionesData.forEach((item) => {
          if (item.tipoComentario === "Tarea") {
            let body = {};
            body.titulo = item.titulo;
            body.fecha = item.fechaComentario;
            body.desc = item.descripcion;
            body.nombresolicitante = item.usuario;
            body.id = id;
            body.nave_id = values.id_activo;

            sendMailTarea(user.token, body)
              .then((res) => {
                toast.success("Email de tarea enviado!");
              })
              .catch((err) => {
                console.log(err);
                toast.error("Error al enviar correo de tarea.");
              });
          }
        });
        toast.success("Solicitud actualizada con exito");
        setObsevaciones([]);
        setRep([]);
        navigate(`/supervisor/mis-solicitudes/${id}`);
      })
      .catch((err) => console.log(err));
  };

  const handleDownloadFile = (e, ruta) => {
    e.preventDefault();
    // toast.success(ruta);
    downloadFile(user.token, ruta)
      .then((res) => {
        toast.success("Documento descargado exitosamente");
      })
      .catch((err) => console.log(err));
  };

  const handleFileChange = (event) => {
    setSelectedFiles([...event.target.files]);
  };

  const handleFileUpload = (e) => {
    e.preventDefault();

    selectedFiles.forEach((file) => {
      let params = {};

      params.idTrabajo = values.id;
      params.nombre_documento = file.name;

      toast.success(file.name);

      uploadFile(user.token, params, file)
        .then((res) => {
          toast.success("Archivo subido exitosamente.");
          loadTrabajo();
          loadAdjuntos();
          selectedFiles([]);
        })
        .catch((err) => console.log(err));

      // Append the file with its name
      // formData.append("files", file, file.name);
    });
  };

  return (
    <div>
      {}
      {nivelModal && (
        <>
          <div className="nivelModal" onClick={handleNivelModal}></div>

          <div className="nivelModal-card">
            <div className="title-section">
              Asignar Nivel A solicitud de trabajo
            </div>
            <label className="mt-2">Severidad de la falla</label>
            <select
              name="severidad"
              value={severidad}
              className="w-100"
              onChange={handleValuesChange}
            >
              <option>Seleccionar severiad de la falla</option>
              <option value="Alta">Alta</option>
              <option value="Media">Media</option>
              <option value="Baja">Baja</option>
            </select>

            <label className="mt-2">Origen de la falla</label>
            <select
              name="origen_falla"
              value={origen_falla}
              className="w-100"
              onChange={handleValuesChange}
            >
              <option>Seleccionar origen de la falla</option>
              <option value="Mala operación">Mala operación</option>
              <option value="Vestustez de material">
                Vestustez de material
              </option>
              <option value="Mantenimiento no efectuado.">
                Mantenimiento no efectuado.
              </option>
              <option value="No considerado en plan mantención">
                No considerado en plan mantención
              </option>
            </select>

            <label className="mt-2">Nivel de mantención</label>
            <select
              name="nivel"
              value={nivel}
              className="w-100"
              onChange={handleValuesChange}
            >
              <option>Seleccionar nivel de solicitud de trabajo</option>
              <option value="1">1 - Buque</option>
              <option value="2">2 - Mantenimiento</option>
              <option value="3">3 - Servicios</option>
            </select>
            {nivel && nivel === "1" && (
              <>
                <div className="title-section mt-4 mb-3">
                  Programar trabajos
                </div>
                <label>Fecha trabajos</label>
                <br />
                <input
                  type="datetime-local"
                  name="fecha_programada"
                  className="w-100"
                  value={fecha_programada}
                  onChange={handleValuesChange}
                />
                <label className="mt-4">Responsable</label>
                <br />
                <input
                  className="w-100 "
                  name="responsable"
                  value={nombresolicitante}
                  disabled
                />
                <button
                  className="buttonSolicitud mt-4"
                  onClick={handleSubmitNivel}
                >
                  Asignar Nivel
                </button>
              </>
            )}

            {nivel && nivel === "2" && (
              <>
                <div className="title-section mt-4 mb-3">
                  Asignar Superintendente
                </div>
                <label className="mt-2">Responsable</label>
                <br />
                <select
                  name="responsable"
                  value={responsable}
                  className="w-100"
                  onChange={handleValuesChange}
                >
                  <option>Seleccionar responsable</option>
                  {users &&
                    users
                      .filter((d) => d.tipo_solicitante === "Superintendente")
                      .map((c) => <option value={c.nombre}>{c.nombre}</option>)}
                </select>
                <button
                  className="buttonSolicitud mt-4"
                  onClick={handleSubmitNivel}
                >
                  Asignar Nivel
                </button>
              </>
            )}

            {nivel && nivel === "3" && (
              <>
                <div className="title-section mt-4 mb-3">
                  Asignar Superintendente
                </div>
                <label className="mt-2">Responsable</label>
                <br />
                <select
                  name="responsable"
                  value={responsable}
                  className="w-100"
                  onChange={handleValuesChange}
                >
                  <option>Seleccionar responsable</option>
                  {users &&
                    users
                      .filter((d) => d.tipo_solicitante === "Superintendente")
                      .map((c) => <option value={c.nombre}>{c.nombre}</option>)}
                </select>
                <button
                  className="buttonSolicitud mt-4"
                  onClick={handleSubmitNivel}
                >
                  Asignar Nivel
                </button>
              </>
            )}
          </div>
        </>
      )}

      {programarModal && (
        <ModalProgramarSolicitud
          handleProgramarModal={handleProgramarModal}
          handleProgramar={handleProgramar}
          handleChange={handleValuesChange}
          values={values}
        />
      )}
      <SupervisorSidebar />
      <div className="home">
        <div className="navCentro">
          <ol class="breadcrumb">
            <li className="breadcrumb-item">
              <i class="fa-solid fa-screwdriver-wrench"></i> Solicitud de
              Trabajo
            </li>
            <li className="breadcrumb-item">Solicitud N° {id}</li>
          </ol>
        </div>
        <div className="row">
          <div className="col-md-12">
            {user.tipo_solicitante && user.tipo_solicitante === "Ito" && (
              <SolicitudItoForm
                values={values}
                handleProgramarModal={handleProgramarModal}
                handleChange={handleValuesChange}
                comentario={comentario}
                observacionesData={observacionesData}
                handleAddDataObs={handleAddDataObs}
                deleteItem={deleteItem}
                handleComentarioChange={handleComentarioChange}
                handleSubmitComentario={handleSubmitComentario}
                handleNivelModal={handleNivelModal}
                user={user}
                handleCerrar={handleCerrar}
                handleAddRepuestosData={handleAddRepuestosData}
                handleRepuestoChange={handleRepuestoChange}
                rep={rep}
                handleProgramar={handleProgramar}
                repuestosData={repuestosData}
                deleteRepuesto={deleteRepuesto}
                proveedores={proveedores}
                handleRecibir={handleRecibir}
                adjuntosData={adjuntosData}
                handleFileChange={handleFileChange}
                selectedFiles={selectedFiles}
                handleFileUpload={handleFileUpload}
                handleDownloadFile={handleDownloadFile}
              />
            )}

            {user.tipo_solicitante &&
              user.tipo_solicitante === "Superintendente" && (
                <SolicitudSuperIntendenteForm
                  values={values}
                  handleChange={handleValuesChange}
                  comentario={comentario}
                  observacionesData={observacionesData}
                  handleAddDataObs={handleAddDataObs}
                  proveedores={proveedores}
                  deleteItem={deleteItem}
                  handleComentarioChange={handleComentarioChange}
                  handleSubmitComentario={handleSubmitComentario}
                  user={user}
                  handleProgramar={handleProgramar}
                  handleCerrar={handleCerrar}
                  handleAddRepuestosData={handleAddRepuestosData}
                  handleRepuestoChange={handleRepuestoChange}
                  rep={rep}
                  handleModalSupervisor={handleModalSupervisor}
                  repuestosData={repuestosData}
                  deleteRepuesto={deleteRepuesto}
                  adjuntosData={adjuntosData}
                  handleFileChange={handleFileChange}
                  selectedFiles={selectedFiles}
                  handleDownloadFile={handleDownloadFile}
                  handleFileUpload={handleFileUpload}
                />
              )}

            {user.tipo_solicitante &&
              (user.tipo_solicitante === "Flota" ||
                user.tipo_solicitante === "SSO" ||
                user.tipo_solicitante === "Armatorial") && (
                <SolicitudFlotaForm
                  values={values}
                  handleChange={handleValuesChange}
                  comentario={comentario}
                  observacionesData={observacionesData}
                  handleAddDataObs={handleAddDataObs}
                  proveedores={proveedores}
                  deleteItem={deleteItem}
                  handleComentarioChange={handleComentarioChange}
                  handleSubmitComentario={handleSubmitComentario}
                  user={user}
                  handleProgramar={handleProgramar}
                  handleCerrar={handleCerrar}
                  handleAddRepuestosData={handleAddRepuestosData}
                  handleRepuestoChange={handleRepuestoChange}
                  rep={rep}
                  handleModalSupervisor={handleModalSupervisor}
                  repuestosData={repuestosData}
                  deleteRepuesto={deleteRepuesto}
                  adjuntosData={adjuntosData}
                  handleFileChange={handleFileChange}
                  selectedFiles={selectedFiles}
                  handleDownloadFile={handleDownloadFile}
                  handleFileUpload={handleFileUpload}
                />
              )}

            {/* {user.tipo_solicitante && user.tipo_solicitante==='Superintendente' && (
                <SolicitudSuperIntendenteForm  values={values}   />
              )} */}
            {/* <form>
                <div className="row formSolicitud">
                  <div className="col-md-12 ">
                    <p className='title-section'>Información Falla </p>
                  </div>
                  <div className="col-md-3">
                     <label className='mr-2' >Fecha de la falla:</label>
                     <label className='label-white'>{formatDate(fecha_falla)}</label>
                  </div>
                  <div className="col-md-3">
                     <label className='mr-2' >Clasificación de Trabajo:</label>
                     <br/>
                      <label className='label-white'>{clasificacion}</label>
                     
                  </div>
                  <div className="col-md-3">
                     <label className='mr-2' >Nave: </label><br/>
                     <label className='label-white'>{nombre_activo}</label>
                  </div>
                  <div className="col-md-3 ">
                     <label className='mr-2' >Equipo:</label>
                     <br/>
                                <label className='label-white'>{nombre_item}</label>
                  </div>
                  {estadoSolicitud && (estadoSolicitud === 'Aprobada' || estadoSolicitud === 'Gestionada' || estadoSolicitud === 'Programada' || estadoSolicitud === 'En Trabajos') && (
                    <>
                    
                  <div className="col-md-12 mt-3">
                    <p className='title-section'>
                      Gestión de la falla </p>
                  </div>
                    <div className="col-12 ">
                     <label className='mr-2' >Severidad de Falla:</label>
                     <br />
                     
                      <select 
                      style={{width:'100%'}}
                      name='severidad'
                      value={severidad}
                      onChange={handleChange}
                      className='inputFormGest'
                      >
                      <option selected>Seleccionar severidad</option>
                                  <option  value='Alta'>Alta</option>
                                  <option  value='Media'>Media</option>
                                  <option  value='Baja'>Baja</option>
                      </select>     
                     
                  </div>
                     <div className="col-1 mt-2">
                     <label className='mr-2' >Id Cot:</label>
                     <br />
                      <input type='number'   className='w-100 inputFormGest' name='nrocot' value={nrocot} onChange={handleChange} />   
                     
                  </div>
                  <div className="col-2 mt-2">
                     <label className='mr-2' >Fecha Cot:</label>
                     <br />

                        <input type='date' name='fechacotizacion' value={formatDate2(fechacotizacion)} onChange={handleChange}  className='w-100 inputFormGest'/>   
                     
                  </div>
                  <div className="col-2 mt-2">
                     <label className='mr-2' >Id Solped:</label>
                     <br />

<input type='number'   className='w-100 inputFormGest' name='nrosolped' value={nrosolped} onChange={handleChange} />  
                      
                  </div>
                  <div className="col-3 mt-2">
                     <label  className='mr-2' >Fecha Solped:</label>
                     <br />

                      <input type='date' name='fechasolped' value={formatDate2(fechasolped)} onChange={handleChange}  className='w-100 inputFormGest'/>   

                  </div>
                  <div className="col-1 mt-2">
                     <label className='mr-2' >Id OC:</label>
                     <br />
<input type='number'   className='w-100 inputFormGest' name='nrooc' value={nrooc} onChange={handleChange} />   
                     
                  </div>
                  <div className="col-3 mt-2">
                     <label  className='mr-2' >Fecha OC:</label>
                     <br />

                      <input type='date' name='fechaoc' value={formatDate2(fechaoc)} onChange={handleChange}  className='w-100 inputFormGest'/>   

                     
                  </div>
                  
                    </>
                  )}



                  <div className="col-md-12">
                    <hr/>
                  </div>
                   
                  <div className="col-md-8 mt-2">
                     <label className='mr-2 title-section' >Comentarios</label><br/>
                     
                          <div className="comentarios">
                      {obs && obs.map( (c,index) => (
                           <div className='comentario-body' key={index}>
                            <div className="header-comentario">
                            <ul className="userList">
                              <li className="userLi">{c.titulo}</li>
                              <div className="userLiContainer">
                                <li className="userLi">{c.usuario}</li>
                                <li className={c.tipoComentario === 'Observación' ? 'tipoComentarioLi' : 'tipoComentarioLi2'}>{c.tipoComentario}</li>
                                <li>{formatDate(c.fecha)}</li>
                              </div>
                            </ul>
                            </div>
                            <div className="body-comentario">
                                <p className='body-desc'>{c.descripcion}</p>
                          </div>
                          </div>
                      ))

                      }
                      </div>
                      <div className="comentarios">
                      {observacionesData && observacionesData.map( (c,index) => (
                           <div className='comentario-body' key={index}>
                            <div className="header-comentario">
                            <ul className="userList">
                              <li className="userLi">{c.titulo}</li>
                              <div className="userLiContainer">
                                <li className="userLi">{c.usuario}</li>
                                <li className={c.tipoComentario === 'Observación' ? 'tipoComentarioLi' : 'tipoComentarioLi2'}>{c.tipoComentario}</li>
                                <li>{formatDate(c.fecha)}</li>
                                <li ><button className='borarComent' onClick={() => deleteItem(index)}><i class="fa-solid fa-trash"></i></button></li>
                              </div>
                            </ul>
                            </div>
                            <div className="body-comentario">
                                <p className='body-desc'>{c.descripcion}</p>
                          </div>
                          </div>
                      ))

                      }
                      </div>
                      { estadoSolicitud !== 'Rechazada' && ( 

                      <>
                        <form className='cardFormComentarios'>
                          
                          <input required  value={titulo} name='titulo' onChange={handleComentarioChange} className='titleInput' placeholder='Título'  />
                          <input required value={fecha} name='fecha' onChange={handleComentarioChange} className='selectInput' type="datetime-local" placeholder='Título'  />
                          <select required  value={tipoComentario} name='tipoComentario' onChange={handleComentarioChange} className='selectInput'>
                            <option value=''>Tipo de comentario</option>
                            <option value='Accion Tomada'>Acción tomada</option>
                            <option value='Observación'>Observación</option>
                          </select>
                          <textArea required value={descripcion} name='descripcion' onChange={handleComentarioChange} className='textAreaInput' placeholder='Descripción'></textArea>
                        </form>
                        <button className='buttonSolicitud' onClick={handleAddDataObs}>Agregar comentario</button>
                      </>
                     
                      )}
                      
                  </div>
                  
                  <div className="col-md-4 mt-2">
                     <label className='mr-2 title-section' >Imágenes</label><br/>
                    <div className="row">
                      <div className="col-12 mt-2">
                        {img  && img[0] === '' ? (
                          <div className="imgDefault"><i class="fa-regular fa-image"></i></div>
                        ):(
                          <div className="imgDefault" style={{background:`url(${img[0]})`,backgroundPosition:'center',backgroundSize:'cover',backgroundRepeat:'no-repeat'}}></div>
                        )}        
                      </div>
                      <div className="col-12 mt-2">
                        {img && img && img[1] === '' ? (
                          <div className="imgDefault"><i class="fa-regular fa-image"></i></div>
                        ):(
                          <div className="imgDefault" style={{background:`url(${img[1]})`,backgroundPosition:'center',backgroundSize:'cover',backgroundRepeat:'no-repeat'}}></div>
                        )}   
                      </div>
                      <div className="col-12 mt-2">
                        {img && img[2] === '' ? (
                          <div className="imgDefault"><i class="fa-regular fa-image"></i></div>
                        ):(
                          <div className="imgDefault" style={{background:`url(${img[2]})`,backgroundPosition:'center',backgroundSize:'cover',backgroundRepeat:'no-repeat'}}></div>
                            
                        )}   
                    </div>
                    {estadoSolicitud &&  (estadoSolicitud === 'Gestionada' ) && (<>

                  <div className="col-md-12 mt-3">
                    <p className='title-section'>Programación de trabajos</p>
                  </div>
                  <label type='date' className='mr-2' >Fecha de trabajos:</label>
                     <br />
                     
                      <input type='datetime-local' name='fecha_programada' value={fecha_programada} onChange={handleChange} className='w-100 inputFormGest'/>   
                     
</>)}


{estadoSolicitud &&  (estadoSolicitud === 'Programada' || estadoSolicitud === 'En Trabajos') && (<>

<div className="col-md-12 mt-3">
  <p className='title-section'>Programación de trabajos</p>
</div>
<label type='date' className='mr-2' >Trabajos programados para</label>
   <br />
   
 <label className='label-white'> <i class="fa-solid fa-clock"></i> {formatDate(fecha_programada)}</label>
   
</>)}

                  </div>
                  </div>
                 
                 
                  {estadoSolicitud && estadoSolicitud === 'En Trabajos'  && (
                      <div className="col-md-12 mt-3">
                        <p className='title-section'>Recepción de trabajos</p>
                      </div>
                  )}
                  
                  {estadoSolicitud && estadoSolicitud === 'Aprobada' && (
                    <>
                   
                    <div className="col-6 mt-3">
                     <button className='buttonComment' onClick={handleSubmit}>Guardar comentarios</button>
                  </div>
                  
                  <div className="col-6 mt-3">
                     <button className='buttonSolicitud' onClick={handleGestionar}>Gestionar Solicitud</button>
                  </div>
                  
                    </>
                  )}


                  {estadoSolicitud && estadoSolicitud === 'Gestionada'  && (
                      <>
                    
                      <div className="col-6 mt-3">
                      <button className='buttonComment' onClick={handleSubmit}>Actualizar solicitud</button>
                    </div>
                    
                    <div className="col-6 mt-3">
                      <button className='buttonSolicitud' onClick={handleProgramar}>Programar solicitud</button>
                    </div>
                    
                      </>
                    )}
                 

                 {estadoSolicitud && estadoSolicitud === 'Programada'  && (
                      <>
                    
                      <div className="col-12 mt-3">
                      <button className='buttonComment' onClick={handleSubmit}>Actualizar solicitud</button>
                    </div>
                    
                    
                      </>
                    )}
                 

                 {estadoSolicitud && estadoSolicitud === 'En Trabajos'  && (
                      <>
                    
                      <div className="col-12 mt-3">
                      <button className='buttonSolicitud' onClick={handleSubmit}>Actualizar solicitud</button>
                    </div>
                    
                    <div className="col-6 mt-3">
                      <button className='buttonComment' ><i class="fa-solid fa-shield"></i> Solicitud garantia</button>
                    </div>
                    <div className="col-6 mt-3">
                      <button className='buttonSolicitud' onClick={handleRecibir}><i class="fa-solid fa-check"></i> Recibido Conforme</button>
                    </div>
                    
                      </>
                    )}
                </div>
              </form> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolicitudDetailSup;
