import React,{ useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, Link, useParams } from 'react-router-dom'
import AdminSidebar from '../../../components/nav/AdminSidebar'
import { toast } from 'react-toastify'

import Loading from '../../../components/otros/Loading'

import { updateClasificacion, getClasificacionById } from '../../../functions/api/clasificaciones';



const UpdateClasificacion = () => {

    const [loading,setLoading] = useState(false)
    const [clasificacionData,setClasificacion] = useState([])

    let { id } = useParams();
    let {user} = useSelector((state) =>({...state}))
    let navigate = useNavigate ()

    useEffect(() => {
        setLoading(true)

          loadClasificacion()
          setLoading(false)
    }, [])


    const loadClasificacion = () => getClasificacionById(user.token,{id:id}).then( c=> setClasificacion(c.data[0])).catch(err => console.log(err))



    const {clasificacion,activo} = clasificacionData


    const handleChange = (e) =>{

        setClasificacion({...clasificacionData, [e.target.name]:e.target.value})

    }


    const submitData = (e) =>{

        e.preventDefault()
        
        updateClasificacion(user.token,clasificacionData)
        .then( r => {
            toast.success('Clasificación actualizada exitosamente')
            setClasificacion([])
            navigate('/admin/datos-maestros/clasificaciones')
        }
        )
        .catch( err =>
                toast.error(err.response.data)
        )

    }

  return (
    <div> 
        <AdminSidebar />
        {loading ? (
          
        <div className="home">
          <Loading />
        </div>
        ):(
            <div className="home">
                <div className='navCentro'>
                    <ol class="breadcrumb">
                        <li className="breadcrumb-item"><i class="fa-solid fa-screwdriver-wrench "></i> TIPOS DE TRABAJO</li>
                        <li className="breadcrumb-item">Editar clasificación</li>
                    </ol>
                    <div className="row navTab">
                        <div className="col-md-12 navTabItem">
                            <Link to='/admin/datos-maestros/clasificaciones'><i class="fa-solid fa-chevron-left mr-2"></i>Atras</Link>
                        </div>
                    </div>
                   
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <form className='formFormat1'>
                            <label>Clasificación</label>
                            <input  type='text' value={clasificacion} name='clasificacion' onChange={handleChange}></input>
                            <label>Activo / Inactivo</label>
                            <select id="selectInput" value={activo} name='activo' onChange={handleChange}>
                                <option value="">Escoga el estado de la clasificación</option>
                                <option value={true}>Activo</option>
                                <option value={false}>Inactivo</option>
                            </select>
                            <button className='formButton1' onClick={submitData}>Actualizar clasificacion</button>
                        </form>
                        
                    </div>
                    </div>
            </div>
        )}
    </div>
  )
}

export default UpdateClasificacion