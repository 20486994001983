import axios from "axios";

const API_URL = process.env.REACT_APP_API;

export const getEntregasCombustible = async (token) => {
  return await axios.get(`${API_URL}/entrega_combustible`, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const getEntregasCombustibleByEstanque = async (token, estanque) => {
  return await axios.get(
    `${API_URL}/entrega_combustible/estanque/${estanque}`,
    {
      headers: {
        "x-auth-token": token,
      },
    }
  );
};

export const createEntregaCombustible = async (token, body) => {
  return await axios.post(`${API_URL}/entrega_combustible`, body, {
    headers: {
      "x-auth-token": token,
    },
  });
};

export const updateEntregaCombustible = async (token, id, body) => {
  return await axios.put(`${API_URL}/entrega_combustible/${id}`, body, {
    headers: {
      "x-auth-token": token,
    },
  });
};
