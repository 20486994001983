import React from "react";

const ModalEquiposBitacora = ({
  onOpenChange,
  handleSubmit,
  handleChange,
  values,
  handleFileChange,
  preview,
}) => {
  const { nivel, comprobante, estanque } = values;

  return (
    <>
      <div className="container-modal" onClick={onOpenChange}></div>
      <div className="body-modal">
        <h6>Agregar datos</h6>
        <div className="row">
          <label className="label-title mt-2 mb-2">
            {/* {values.tipo_equipo === "estanque" ? "Nivel" : "Horometro"} */}
            Nivel
          </label>
          <input
            name="nivel"
            value={nivel}
            onChange={handleChange}
            className="inputRegistros"
            type="number"
            placeholder="Ingrese valor"
          />
          <label className="label-title mt-2 mb-2">Comprobante</label>
          <input
            name="comprobante"
            type="file"
            onChange={handleFileChange}
            className="inputRegistros"
            placeholder="Comprobante"
            accept="image/*"
          />
          {comprobante && (
            <img
              src={comprobante}
              alt="Comprobante Preview"
              style={{ width: "100px", height: "100px" }}
            />
          )}
          <button
            className="buttonContentSecondary w-100 mt-2"
            onClick={handleSubmit}
          >
            Agregar Registro
          </button>
        </div>
      </div>
    </>
  );
};

export default ModalEquiposBitacora;
